import React, {useEffect} from "react";
import {observer} from "mobx-react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {Accordion, AccordionDetails, AccordionSummary, LinearProgress, Stack, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import {DateCalendar} from "@mui/x-date-pickers";
import {Group} from "@mui/icons-material";
import FormControlGroup from "@mui/material/FormControl";


const columns = [
    {field: 'datetime', headerName: 'Date', width: 130},
    {field: 'name', headerName: 'Name', width: 130},
    {field: 'status', headerName: 'Status', width: 130},
    {field: 'progress', headerName: 'Progress', width: 130},
    {field: 'action', headerName: 'Action', width: 130},
];

const rows = [
    {id: 1, datetime: Date.now() , name: 'Jon', status: 'Active', progress: 10, action: 'Delete'},
    {id: 2, datetime: Date.now() , name: 'Cersei', status: 'Active', progress: 10, action: 'Delete'},
    {id: 3, datetime: Date.now() , name: 'Jaime', status: 'Active', progress: 10, action: 'Delete'},
    ]


interface RenderQueueProps {

}

const RenderQueueBase: React.FC<RenderQueueProps> =( props, context) =>{
    return (
        <Paper>
            <Typography variant={"h6"}>Render Queue</Typography>
            <Grid container>
                {rows.map((row) => {
                    return <Grid item xs={12} sx={{
                        padding:"1px",
                        borderBottom:"solid 1px #CCCCCC",
                    }}>
                        <Accordion  disableGutters square={true}>
                            <AccordionSummary sx={{
                                border:"solid 1px #CCCCCC",
                                padding:"4px",
                                "& .MuiAccordionSummary-content":{
                                    margin:"0px",
                                    padding:"0px",
                                    rounded:"0px",
                                }
                            }}>
                                 <Grid container>
                                    <Grid item xs={4}>
                                     <Typography fontSize={"0.8rem"}>{new Intl.DateTimeFormat("ru-RU",
                                         {
                                             dateStyle: 'short',
                                             timeStyle: 'long',
                                             hour12: false,
                                         }
                                     ).format(row.datetime)}</Typography>
                                    </Grid>
                                        <Grid item xs={4}>
                                     <Typography fontSize={"0.8rem"}>{row.name}</Typography>
                                    </Grid>
                                        <Grid item xs={4}>
                                     <LinearProgress sx={{minWidth:"100px"}} color="info" value={row.progress} variant={"determinate"}  />
                                        </Grid>

                                 </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography fontSize={"0.7rem"}>Queued</Typography>
                                <Typography fontSize={"0.7rem"}>Queued</Typography>
                                <Typography fontSize={"0.7rem"}>Queued</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                })}
            </Grid>

        </Paper>
    )
}



const RenderQueue = observer(RenderQueueBase)
export default RenderQueue
