import {action, makeAutoObservable, runInAction} from "mobx";
import api from "../api/api";
import {DatasetCreate} from "../api/datasets";
import {deepClone} from "@mui/x-data-grid/utils/utils";



class ErrorStore{

        errors: string[] = []
        constructor(){
                makeAutoObservable(this)

        }

        get hasErrors(){
                return errorStore.errors.length > 0
        }
        get Errors(){
                return errorStore.errors
        }

        @action
        async pushError( status:any, error: string){
                errorStore.errors.push(error)
        }
        @action
        clearErrors() {

             errorStore.errors = []

        }
}


export const errorStore = new ErrorStore();
