import {action, autorun, makeAutoObservable, observable} from "mobx";
import {ProjectCreate, ProjectDelete, ProjectList} from "../api/projects";



const name = 'ProjectStore'

interface  FigmaLink {
    key: string
    label: string
}
export class ProjectContract {
    id: string = "";
    name: string = ""
    owner_id: string = "";
    created_at: any = null;
    updated_at: any = null;
    assets: Array<any> = [];
    data_sources: Array<any> = [];
    templates: Array<any> = [];
    render_tasks: Array<any> = [];

}

class ProjectStore {

    ui_state: number = 0
    id: string = ""
    name : string = ""
    activeProject: number = 0
    projects: ProjectContract[] = Array<ProjectContract>()

     constructor(){
        makeAutoObservable(this)
    }
    @action
   async initProjectList() {

        this.projects = []
        let projectlist = await ProjectList();

        if (projectlist !== null && projectlist !== undefined && Array.isArray(projectlist)) {
            let tmpArr: ProjectContract[] = []
            projectlist.forEach((project: ProjectContract) => {
                let tmp = new ProjectContract()
                tmp.id = project.id
                tmp.name = project.name
                tmp.owner_id = project.owner_id
                tmp.created_at = project.created_at
                tmp.updated_at = project.updated_at
                tmp.assets = project.assets
                tmp.data_sources = project.data_sources
                tmp.templates = project.templates
                tmp.render_tasks = project.render_tasks
                tmpArr.push(tmp)
            });
            this.projects = tmpArr
            console.log("ProjectStore: ", projectlist)
        }
    }

    @action
    createProject(name: string, description: string){
        ProjectCreate(name, description).then((res) => {
            console.log(res)
            this.initProjectList().then()
        })
    }

    @action
    deleteProject(id: string){
        ProjectDelete(id).then((res) => {
            console.log(res)
            this.initProjectList().then()
        });

    }


   List(){

        return this.projects
    }

    get(project_id: number){
        return this.projects[project_id]
    }
    getActive(){
        return this.get(this.activeProject)
    }
}
const projectStore = new ProjectStore()
export  default projectStore;
