import {
    TFrame,
    TRectangle,
    ITemplate,
    TVector,
    TImage,
    TDynamicValue,
    TDynamicValueTypes
} from "../TemplateContracts";
import {action, makeAutoObservable, makeObservable, observable} from "mobx";
import {TBaseItem} from "./TBaseItem";
import {TText} from "./TTextItem";
import {assign} from "mobx/dist/utils/utils";



/**
 * A template class representing a reusable design template.
 */
export class TTemplate extends TBaseItem implements ITemplate  {


   /**
    * Determines whether the given value is a root.
    *
    * @param {boolean | undefined} isRoot - The value to check if it's a root.
    * @returns {boolean} - True if the value is a root, false otherwise.
    */
   @observable is_root: boolean | undefined;
    /**
     * Description: Represents the URL of a Figma design file.
     *
     * @type {string}
     * @default ""
     */
    @observable  figma_url: string = "";
    /**
     * Holds the identifier of a mask.
     *
     * @type {string | undefined}
     */
    @observable  mask_id: string | undefined;
    /**
     * Holds the unique identifier for the ORM (Object-Relational Mapping) entity.
     *
     * @type {string}
     */
    @observable  orm_id: string = "";
    /**
     * Represents the description of a variable.
     *
     * @typedef {string | undefined} Description
     */
    @observable  description?: string | undefined;
    /**
     * Checks if the variable has a mask applied.
     *
     * @param {boolean|undefined} has_mask - Indicates if the variable has a mask applied. An undefined value indicates that the variable's mask status is unknown.
     * @returns {boolean} - Returns true if the variable has a mask applied, otherwise false.
     */
    @observable  has_mask: boolean | undefined;
    /**
     * Represents the content of clips.
     *
     * @typedef {boolean|undefined} clips_content
     */
    @observable  clips_content: boolean | undefined;
    /**
     * Represents a collection of images.
     *
     * @typedef {Object} TImage
     * @property {string} name - The name of the image.
     * @property {string} url - The URL of the image.
     *
     * @typedef {Array<TImage>} images - An array of TImage objects.
     */
    @observable   images: TImage[] | undefined;
    /**
     * Represents the available fonts as a nested object structure.
     * Each font is identified by a key and contains a set of properties.
     *
     * @typedef {Object} Fonts
     * @property {Object.<string, string>} font - The properties of each font.
     */
    @observable  fonts: { [key: string]: { [key: string]: string; }; } | undefined;
    /**
     * Represents the aspect ratio of a video or image.
     *
     * @typedef {(number | undefined)} AspectRatio
     */
    @observable  aspect_ratio: number | undefined;
    /**
     * Represents an array of dynamic values.
     *
     * @typedef {TDynamicValue[]} dynamic_values
     * @property {TDynamicValue[]} [dynamic_values=[]] - The array of dynamic values.
     */
    @observable dynamic_values: TDynamicValue[] | undefined = []

    /**
     * An array of children elements.
     *
     * @type {(TText | TRectangle | TVector | TFrame)[]}
     */
    @observable  children: (TText | TRectangle | TVector | TFrame)[] = [];

    constructor() {
        super()
        makeObservable(this)
  }

    @action
    addDynamicField(node: TBaseItem, type: TDynamicValueTypes) {

        if(!this.dynamic_values)
            this.dynamic_values = []

        let dynamicValue: TDynamicValue =  {
            "id": "",
            "name": "",
            "parent_id": "",
            "path": "",
            "type": type
        }
        dynamicValue.type = type
        dynamicValue.name = node.name as string
        dynamicValue.parent_id = node.id as string
        dynamicValue.id = "data-dynamic-" + node.id + "-" + type
        this.dynamic_values = [...this.dynamic_values, dynamicValue]
    }
    @action
    removeDynamicField(node: TBaseItem, type: TDynamicValueTypes) {
        if(!this.dynamic_values)
            return
        this.dynamic_values = this.dynamic_values.filter((item) => item.id !==  "data-dynamic-" + node.id + "-" + type)

    }
}
