import {IText, IValue, TColorFill, TConstraint, TGradient, TImage, ITextStyle, Transform} from "../TemplateContracts";
import {TBaseItem} from "./TBaseItem";
import {action, makeAutoObservable, makeObservable, observable} from "mobx";
import {canvasStore} from "../../stores/templates/CanvasStore";

/**
 * Represents the styling for text.
 * @implements {ITextStyle}
 */
export class TTextStyle implements ITextStyle {

    @observable  fontFamily: string;
    @observable fontPostScriptName: string;
    @observable fontWeight: number;
    @observable fontSize: number;
    @observable textAlignHorizontal: string;
    @observable textAlignVertical: string;
    @observable textAutoResize: string | null;
    @observable letterSpacing: number;
    @observable lineHeightPx: number;
    @observable lineHeightPercent: number;
    @observable lineHeightPercentFontSize: number;
    @observable lineHeightUnit: string;
    @observable isScalable: boolean;

    constructor() {
        this.fontFamily = "";
        this.fontPostScriptName = "";
        this.fontWeight = 0;
        this.fontSize = 0;
        this.textAlignHorizontal = "";
        this.textAlignVertical = "";
        this.textAutoResize = "";
        this.letterSpacing = 0;
        this.lineHeightPx = 0;
        this.lineHeightPercent = 0;
        this.lineHeightPercentFontSize = 0;
        this.lineHeightUnit = "";
        this.isScalable = false;
        makeObservable(this);
    }

}

/**
 * Class representing a Text element.
 * @extends TBaseItem
 * @implements IText
 */
export class TText extends TBaseItem implements IText{

    /**
     * Represents a text variable.
     *
     * @typedef {string} Text
     */
    @observable
    text: string = "";
    /**
     * Whether the variable `scalable` can be scaled or not.
     *
     * @type {boolean | undefined}
     * @default true
     */
    @observable
    scalable?: boolean | undefined = true;
    /**
     * Represents the style of a text element.
     *
     * @typedef {Object} TTextStyle
     * @property {string} font - The font family of the text.
     * @property {number} fontSize - The size of the text in pixels.
     * @property {string} color - The color of the text.
     * @property {string} backgroundColor - The background color of the text.
     * @property {boolean} bold - Specifies whether the text should be bold or not.
     * @property {boolean} italic - Specifies whether the text should be italic or not.
     * @property {boolean} underline - Specifies whether the text should be underlined or not.
     */
    @observable
    text_style: TTextStyle

   /**
    * Constructs a new instance of the class.
    *
    * @constructor
    */
   constructor() {
       super();
       this.text_style = new TTextStyle()
        makeObservable( this)
   }

    /**
     * Sets the text value of the element.
     *
     * @param {string} value - The new text value.
     *
     * @return {void}
     */
    @action
    setText(value: string) {

        this.text = value
        let el = canvasStore.currentElement
        if (el){
            if(this.text_style.isScalable ){
                el.querySelector("foreignObject")!.innerHTML = value
            }else {
                el.innerText = value
            }
            }
    }

    /**
     * Sets the font size for the current element.
     * @param {number} value - The font size value to set.
     * @returns {void}
     */
    @action
    SetFontSize(value: number) {

        this.text_style.fontSize = value
        let el = canvasStore.currentElement
        if (el){
            el.style.fontSize = `${value}px`
        }
    }
    /**
     * Sets the line height in pixels for the current element.
     *
     * @param {number} value - The line height value in pixels.
     *
     * @return {void}
     */
    @action
    setLineHeightPx(value: number) {

            this.text_style.lineHeightPercentFontSize = value
            let el = canvasStore.currentElement
            if (el){
                el.style.lineHeight = `${value}%`
            }
    }
    /**
     * Set the letter spacing of the text.
     *
     * @param {number} number - The desired letter spacing value.
     *
     * @action
     */
    @action
    setLetterSpacing(number: number) {

        this.text_style.letterSpacing = number
        let el = canvasStore.currentElement
        if (el){
            el.style.letterSpacing = `${number}px`
        }
    }
    /**
     * Sets the scalability of the text element.
     *
     * @param {boolean} checked - The flag indicating whether the text should be scalable or not.
     *
     * @return {void}
     */
    @action
    setTextScalable(checked: boolean) {

                this.text_style.isScalable = checked

                let el = canvasStore.currentElement
                if (el){
                    if(this.text_style.isScalable ){

                        if(!el.querySelector("svg")){
                            console.log("setTextScalable",el )
                            el.innerText = ""
                            let doc = el.ownerDocument
                            let svg = doc.createElementNS("http://www.w3.org/2000/svg", "svg");
                            svg.setAttribute('width', '100%');
                            svg.setAttribute('height', '100%');
                            svg.setAttribute('viewBox', `0 0 ${this.width.value} ${this.height.value}}`);
                            svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
                            svg.setAttribute('version', '1.1');
                            svg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
                            svg.setAttribute('xmlns:svgjs', 'http://svgjs.com/svgjs');
                            svg.setAttribute('style', 'position: absolute; top: 0; left: 0; width: 100%; height: 100%; overflow: visible;');

                            let foreignObject = doc.createElementNS("http://www.w3.org/2000/svg", "foreignObject");
                            foreignObject.setAttribute('width', '100%');
                            foreignObject.setAttribute('height', '100%');
                            foreignObject.setAttribute('overflow', 'visible');
                            foreignObject.innerHTML =   this.text
                            svg.appendChild(foreignObject);
                            el.appendChild(svg)
                        }

                    } else{
                        if(el.querySelector("svg")) {
                            el.removeChild(el.querySelector("svg")!)
                        }
                        el.innerText = this.text
                    }

                }
            }

    setFontFamily(value: string) {

        this.text_style.fontFamily = value
        let el = canvasStore.currentElement
        if (el){
            el.style.fontFamily = value
        }
    }
}
