
import api, {API_PREFIX} from "./api";
import {authStore} from "../stores/AuthStore";
import {ITemplate, IVariant} from "../contracts/TemplateContracts";
import ky from "ky";
import {canvasStore} from "../stores/templates/CanvasStore";






export namespace TemplateAPI {
    export  async function List(): Promise<any[]> {
        try {
            return api.get("api/template/").json();

        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    export async function Create(name: string, description: string, width: number, height: number) {

        try {
            return api.post("api/template/", {
                json: {
                    name: name,
                    description: description,
                    width: width,
                    height: height,
                    data: {},
                }
            }).json();

        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    export  async function Read(id: string): Promise<ITemplate>{
        try {
            return api.get("api/template/" + id).json();
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    export async function Update(template: ITemplate) {

            return api.put("api/template/" + template.orm_id, {
                json:template
              /*  json:  JSON.parse(JSON.stringify(template, (key, value) => {

                    if (key === 'parent' ) {
                        return undefined;
                    }
                    return value;
                })),*/
            });
    }

    export async function RenderTemplate( id:string,  template: ITemplate):Promise<string> {

            return api.post("api/template/" + id + "/render_template", {
                    json: template,
                    hooks: {
                        beforeRequest: [
                            async (request) => {
                                const token = localStorage.getItem('token')
                                request.headers.set('Authorization', 'Bearer '+token);
                                canvasStore.isRendering = true;
                                return request;
                            }
                        ],

                        afterResponse: [
                            async (_request, _options, response) => {

                                if (response.status === 200) {
                                    canvasStore.isRendering = false
                                    return response;
                                }
                                const text = await response.text();
                                throw new Error(text);
                            }
                        ]
                    }
            }).text();

    }

    export async function Render(id: string | undefined) {

            return api.get("api/template/" + id + "/render_html?format=base").json();

    }

    export async function Delete(id: string) {
        try {
            return api.delete("api/template/" + id).json();

        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    export async function ImportFromFigma(id: string, figma_url: string, variantId:string|null = null) {
        try {
            return api.post("api/template/" + id + "/figma-import",
                {
                    json: {
                        id: id,
                        figma_url: figma_url,
                        variantId: variantId
                    }
                }
            ).json();

        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    export async function ImportFromFigmaStatus(task_id: string, updateCallback: any) {
        try {

            const taskId = task_id
            // @ts-ignore
            const eventSource = new EventSource(`/api/template/figma-import/${taskId}`, { headers: {  "Authorization": 'Bearer ' + authStore.token  }});

            eventSource.onmessage = function(event) {
                const data = JSON.parse(event.data);
                if(updateCallback)
                    updateCallback(data);

                if (data.status === 'done' || data.status === 'error') {
                    eventSource.close();
                }
            };

            eventSource.onerror = function(event) {
                console.error('EventSource failed:', event);
                eventSource.close();
                if(updateCallback)
                    updateCallback({status: "error", message: "EventSource failed"})
            };


        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    export async function VariantsList(id: string) {
        try {
            return api.get("api/template/" + id + "/variants").json();

        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    export async function VariantsCreate( variant: IVariant) {
        try {
            return api.post("api/template/" + variant.template_id + "/variants", {
                json: variant
            }).json();

        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    export async function VariantsRead(id: string, variant_id: string): Promise<IVariant> {
        try {
            return api.get("api/template/" + id + "/variants/" + variant_id).json();

        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    export async function VariantsUpdate(variant: IVariant) {

            return api.put("api/template/" + variant.template_id + "/variants/" + variant.id, {
                json: variant
            }).json();

    }

    export async function VariantsDelete(id: string, variant_id: string) {
        try {
            return api.delete("api/template/" + id + "/variants/" + variant_id).json();

        } catch (e) {
            console.log(e);
            throw e;
        }
    }

}
