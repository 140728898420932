

import api from "./api";
import {IFont} from "../contracts/interfaces/IFonts";




export namespace FontsAPI {

    export function DeleteFont(font: IFont) {

          return api.delete(`api/storage/fonts/${font.id}`).json();

    }

    export async function GetFontFile(font: IFont) {

        try {
            return api.get(`api/storage/fonts/${font.id}/file`).blob();
        } catch (e) {
            console.log(e);
            throw e;
        }

    }

    export async function GetFontsList(): Promise<any[]> {
        try {
            return api.get("api/storage/fonts").json();
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    export async function UploadFont(font: any, file: File): Promise<any> {
        try {

            const form = new FormData();
            form.append("file", file);
            form.append("font", JSON.stringify(font));
            return api.post("api/storage/fonts", { body: form }).json();

        } catch (e) {
            console.log(e);
            throw e;
        }
    }
}
