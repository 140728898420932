import {action, flow, makeAutoObservable, runInAction} from "mobx";
import  {TemplateAPI} from "../../api/templates";
import {canvasStore} from "./CanvasStore";
import {
    THorizontalConstraint,
    TColor,
    TColorFill,
    TFrame,
    TRectangle,
    ITemplate,
    IText,
    TVector, TVerticalConstraint, IBaseItem, IVariant
} from "../../contracts/TemplateContracts";
import {ApplyConstraints, transformToElement} from "../../utils/calculations";
import {TTemplate} from "../../contracts/implementations/TemplateImp";
import {TBaseItem} from "../../contracts/implementations/TBaseItem";
import {TText} from "../../contracts/implementations/TTextItem";
import VariantStoreStore from "./VariantStore";

class Serializer {

    static serialize(source: IBaseItem): any {
        let target: any = source
        switch (source.convert_type) {

            case "container":
                target =  new TBaseItem()
                break;
            case "main_frame":
                target =   new TTemplate()
                break;
            case "text":
                 target = new TText()
                break;
            case "rectangle":
                target =   new TBaseItem()
                break;
            case "mask_vector":
                target =   new TBaseItem()
                break;
            case "solid_vector":
                target =   new TBaseItem()
                break;
            default:
                target = new TBaseItem()
                break;

        }
        Object.assign(target, source)


        if (target.children){
            target.children = Serializer.serializeChildren(target.children)
        }
        return target
    }


    static serializeChildren(children: any[] ): any[] {
        let target: any[] = []
        for (let child of children) {
            target.push(Serializer.serialize(child))
        }
        return target
    }
    static makeInstance(source: ITemplate): TTemplate {
        let target = new TTemplate()
        Object.assign(target, source)
        Object.assign(target.children, Serializer.serializeChildren(source.children))
        return target
    }
}



class TemplateListItem {
    id: string = ""
    name: string = ""
    width: number = 0
    height: number = 0
    thumbnail: string = ""
    description: string = ""
    created_at: string = ""
    updated_at: string = ""
    constructor(){
        makeAutoObservable(this)
    }
}

class TemplateStore{

    templates: TemplateListItem[] = []

    private _currentTemplate: TTemplate  = new TTemplate()
    variants: VariantStoreStore  = new VariantStoreStore()
    isVariantViewMode: boolean = false
    nodesIndex: Map<string, TBaseItem> =  new Map<string, TBaseItem>(    )
    currentImportTaskId: string = ""
    currentImportTaskStatus: string = ""
    currentImportTaskMessage: string = ""
    currentImportTaskIntervalId: any = null
    currentImportInProgress: boolean = false
    selectedTemplateId: string = ""
    selectedLayerId: string = ""
    currentNode: TVector|TText| TRectangle |null = null
    currentContainer: ITemplate| TFrame |null = null
    renderPreviewOpen: boolean = false
    //currentLayer: any = null

    constructor(){
        makeAutoObservable(this)
        //this.fetchDatasets().then()
    }
    getNodeById(id: string, node: any = this.template): any | null {
        // Base case: if the node is null, return null
        if (node === null) {
            return null;
        }

        // Check if the current node matches the id
        if (node.id === id) {
            return node;
        }

        // Recursively search in children nodes
        if (node.children) {
            for (let i = 0; i < node.children.length; i++) {
                let child = node.children[i];
                let res: any = this.getNodeById(id, child);
                if (res) {
                    return res;
                }
            }
        }

        // Return null if no matching node is found
        return null;
    }
    get selectedNode(): TText| TVector| TRectangle | ITemplate| TFrame | TBaseItem | null {
        if (this.currentNode){
            return  this.currentNode
        }
        return null
    }

    get template(): TTemplate {
        return this._currentTemplate
    }


    setSelectedNode(nodeIds: string) {

        let node = this.getNodeById(nodeIds)
        console.log("setSelectedNode", node)
        if (node){
            if (node.convert_type === "mask_vector" || node.convert_type === "solid_vector" || node.convert_type === "text" || node.convert_type === "rectangle"){
                runInAction(() => {
                    this.currentNode = node
                    console.log("setSelectedNode", node)
                })
            }
            if (node.convert_type === "container"|| node.convert_type === "main_frame"){
                runInAction(() => {
                    this.currentNode = node
                    this.currentContainer = node
                    console.log("setSelectedContainer", node)
                })
            }
        } else {
            console.log("setSelectedNode", node)
        }
        let s = canvasStore.currentElement
    }

    get textNode(): TText |null {
        if (this.currentNode){
            return  this.currentNode as TText
        }
        return null
    }


    get selectedContainer(): ITemplate| TFrame |null {
        if (this.currentContainer){
            return  this.currentContainer
        }
        return null
    }


    @action
    List(){
        const rows:any[]   = [ ];
        this.templates.forEach((template) => {
            rows.push({id: template.id, name: template.name, description: template.description, dataSource: 1})
        } )
        return rows//this.DataSets
    }

    @action
    async fetch(){
        const data_resp: any[] = await TemplateAPI.List()

        this.templates = []
        data_resp.forEach((data) => {
            const templateTmp: TemplateListItem =  {...new TemplateListItem(), ...data}

            runInAction(() => {
                //this.templates.set(data.id, dataset)
                this.templates.push(templateTmp)

            })
        })

    }

    @action
    makeNodesIndex(node: any, parent: any|null = null) {
        if(parent&&parent.id) {
            node.parentId = parent.id;
        }
        let baseItem  = node as TBaseItem
        if(baseItem.id) {
            this.nodesIndex.set(baseItem.id, baseItem)
        }
        if (node.children) {
            for (let child of node.children) {
                this.makeNodesIndex(child, node);
            }
        }
    }


    readTemplate = flow(function*(this: TemplateStore, id: string, variant_id: string = "default") {

        const selectedID = templateStore.selectedNode?.id

        if(variant_id === "default") {
            let tmpl = Serializer.serialize(yield TemplateAPI.Read(id))
            this._currentTemplate = tmpl;

            this.makeNodesIndex(this.template)

            //console.log( this.template )

            //console.log("readTemplate", this.template)
            canvasStore.set_width(this.template.width.value as number)
            canvasStore.set_height(this.template.height.value as number)
            this.variants.ReadList(this.template.orm_id)
            canvasStore.RenderCanvas()
        } else{

            let variant: IVariant = yield TemplateAPI.VariantsRead(id, variant_id)
            if(variant && variant.template ) {
                templateStore.variants.selectedVariant = variant
                templateStore.variants.selectedVariant.template =  Serializer.serialize(variant.template)
                if(templateStore.variants.selectedVariant) {


                    this._currentTemplate = templateStore.variants.selectedVariant.template as TTemplate;
                    this.makeNodesIndex(this.template)
                    this.isVariantViewMode = true
                    canvasStore.set_width(this.template.width.value as number)
                    canvasStore.set_height(this.template.height.value as number)
                    canvasStore.RenderCanvas()

                }
                //this.variants.ReadList(this.template.orm_id)
            }
        }
        if(selectedID){
            console.log("selectedID", selectedID)
            this.setSelectedNode(selectedID)
        }
    })

    @action
    async importFromFigmaVariant(id: string, variant_id: string | undefined, figma_url: string) {

        let data: any =  await TemplateAPI.ImportFromFigma(id, figma_url, variant_id)
        console.log("import started")
        console.log("imported variant:", data)

    }


    @action
    async importFromFigma(id: string, figma_url: string){
        let data: any =  await TemplateAPI.ImportFromFigma(id, figma_url)
        console.log("import started")
            runInAction(() => {
                this.currentImportTaskId = data.task_id
                this.currentImportTaskStatus = data.status
                this.currentImportTaskMessage = data.message
                this.currentImportInProgress = true
            });

             await TemplateAPI.ImportFromFigmaStatus(this.currentImportTaskId, (message:any)=>{
                 if (message.status === "done" || message.status === "error"){
                     console.log("done")
                     runInAction(() => {
                         this.currentImportTaskStatus = message.status
                         this.currentImportTaskMessage = message.message
                         this.currentImportInProgress = false
                     })
                     this.readTemplate(id)

                 } else{
                     console.log("message", message)
                     runInAction(() => {
                         this.currentImportTaskStatus = message.status
                         this.currentImportTaskMessage = message.message
                     })
                 }
             })
    }

    @action
    async create(DatasetName: string, description: string, width: number, height: number) {
        let data: any =  await TemplateAPI.Create(DatasetName, description, width, height)
        this.templates.push({...new TemplateListItem(), ...data})
    }
    @action
    async delete(id: string) {
        console.log(id)
        await TemplateAPI.Delete(id)
        this.templates = this.templates.filter((template) => template.id !== id)

    }

    async Update() {
        if (this.template){

            let result = await TemplateAPI.Update(this.template)
        }
    }



    @action
    applyTransform() {
        if (this.selectedNode) {
            let el = canvasStore.currentElement
            if (el) {
                ApplyConstraints(this?.selectedNode)
                if (this.selectedNode?.transform)
                    transformToElement(this.selectedNode, el)

                canvasStore.selectElement()
            }
        }
    }

    @action
    setVConstraint(value: TVerticalConstraint) {

        if (this.selectedNode && this.selectedNode.constraints){
            let constraints  = this.selectedNode.constraints
            constraints.vertical = value
            this.applyTransform()
            console.log("setVConstraint", this.selectedNode.constraints)
        }

    }
    @action
    setHConstraint(value: THorizontalConstraint) {

        if (this.selectedNode && this.selectedNode.constraints){
            let constraints  = this.selectedNode.constraints
            constraints.horizontal = value
            this.applyTransform()
            console.log("setVConstraint", this.selectedNode.constraints)
        }

    }
    @action
    setY(number: number) {
        if (this.selectedNode && this.selectedNode.transform){
            let transform  = this.selectedNode.transform
            transform.y.value = number
            this.applyTransform()
            console.log("setY", this.selectedNode.transform.y)
        }
    }

    @action
    setX(value: number) {
        if (this.selectedNode && this.selectedNode.transform){
            let transform  = this.selectedNode.transform
            transform.x.value = value
            this.applyTransform()
            console.log("setX", this.selectedNode.transform)
        }
    }

    @action
    setWidth(value: number) {
        if (this.selectedNode){
            this.selectedNode.width.value = value
            this.applyTransform()
            console.log("setWidth", this.selectedNode.transform)
        }
    }

    @action
    setHeight(value: number) {
        if (this.selectedNode){
            this.selectedNode.height.value = value
            this.applyTransform()
            console.log("setHeight", this.selectedNode.transform)
        }
    }

    @action
    setColorFill(color:TColor, index:number){

        if (this.selectedNode){
            if (this.selectedNode.fills){

                let fill = this.selectedNode.fills[index] as TColorFill
                if (fill){
                    console.log("setColorFill", fill)
                    fill.color = color
                    let el = canvasStore.currentElement
                    if (el){
                        if(this.selectedNode.convert_type === "text"){
                            el.style.color = `rgba(${color.r* 255},${color.g* 255},${color.b* 255},${color.a})`
                        }else {
                            el.style.backgroundColor = `rgba(${color.r * 255},${color.g * 255},${color.b * 255},${color.a})`
                        }
                    }
                }
            }
        }
    }

    @action
    setRenderPreviewOpen(b: boolean) {
        this.renderPreviewOpen = b
    }
    @action
    setClipsContent(checked: boolean) {
        if (this.selectedNode){
            if (this.selectedNode.convert_type === "container" || this.selectedNode.convert_type === "main_frame"){
                let node = this.selectedNode as TFrame
                node.clips_content = checked
                let el = canvasStore.currentElement
                if (el){
                    el.style.overflow = checked ? "hidden" : "visible"
                }
            }
        }

    }
    @action
    setVisible(checked: boolean) {
        if (this.selectedNode){
                let node = this.selectedNode as IBaseItem
                node.visible = checked
                let el = canvasStore.currentElement
                if (el){
                   // el.style.visibility = checked ? "visible !important" : "hidden !important"
                    if(checked) {
                        el.style.setProperty('visibility', 'visible', 'important');
                    } else {
                        el.style.setProperty('visibility', 'hidden', 'important');
                    }
                }
        }

    }



}


export const templateStore = new TemplateStore();
