import {Checkbox, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as Mobx from "mobx-react";
import app_store from "../stores/ui_state";
import {runInAction} from "mobx";
import store from "../stores/ui_state";
import Layout from "../components/layout";
import Box from "@mui/material/Box";
import {authStore} from "../stores/AuthStore";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";

function Login() {
    let navigate = useNavigate();
    let location = useLocation();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const paperStyle = {padding: 20, minHeight: "35vh", width: 380, margin: "20px auto"};
    const avatarStyle = {backgroundColor: "#1bbd7e", margin: "10px"};
    const [errorText, setErrorText] = useState("");
    const btnstyle = {margin: "8px 0"};
    let from = location.state?.from?.pathname || "/";
    const handleLogin = async (e:any ) => {
        e.preventDefault();
        let [ok, error] = await authStore.loginUser(username, password);
        if(ok) {
            navigate(from, {replace: true});
        } else {
            // @ts-ignore
            if(error?.response === undefined){
                setErrorText("Server is not available");
                console.log(error)
                return;
            }
            // @ts-ignore
            const code = error?.response?.status;
            switch (code) {
                case 401:
                    setErrorText("Invalid username or password");
                    break;
                case 403:
                    setErrorText("User is not active");
                    break;
                case 404:
                    setErrorText("[404] route is wrong ");
                    break;
                case 500:
                     setErrorText("[500] Server error");
                    break;
                default:
                    setErrorText("["+code+"] Something went wrong");
            }

        }


    };
    useEffect(() => {
        if(authStore.isLoggedIn){
            console.log("user logged in");
            if(location.pathname === "/login") {
               navigate("/");
            }
        }
    },[]);

    return (
        <Box>
            <Box
                style={{ minHeight: '100vh' }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor:'#081627'
                }} >
        <Grid>
            <Paper elevation={5} style={paperStyle}>
                <form onSubmit={handleLogin}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justifyContent="start"
                    >
                        <Box sx={{display:"flex", direction:"columns", alignItems:"center"}}>
                        <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
                        <Typography sx={{ textWrap:"nowrap", fontSize:24}}>Sign In</Typography>
                        <Typography sx={{color:"red", padding:"10px"}}>
                        {errorText}
                        </Typography>
                        </Box>
                    </Grid>

                    <TextField style={{margin: "5px auto"}}
                               id={"username"}
                               size="small"
                               label="Username"
                               name={"username"}
                               variant="outlined"
                               autoComplete={"username"}
                               fullWidth
                               required
                               onChange={(e) => {
                                   setUsername(e.target.value);
                               }}
                    />

                    <TextField style={{margin: "5px auto"}}
                               id={"password"}
                               size="small"
                               name={"password"}
                               label="Password"

                               type="password"
                               variant="outlined"
                               fullWidth
                               autoComplete={"current-password"}
                               required
                               onChange={(e) => {
                                   setPassword(e.target.value);
                               }}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox checked={app_store.remember_me} name="checkedB" color="primary" onChange={() => {
                                runInAction(() => {
                                    app_store.remember_me = !app_store.remember_me;
                                });
                            }}/>
                        }
                        label="Remember me"
                    />
                    <Button type="submit" color="primary" variant="contained" style={btnstyle} fullWidth >  Sign in</Button>
                </form>
            </Paper>
        </Grid>
            </Box>
        </Box>
    );
}

export default Mobx.observer(Login)
