import {ITruthTable, THorizontalConstraint, Transform, TUnits, TVerticalConstraint} from "../contracts/TemplateContracts";
//import truth_table from "./truth_table.json";
import table_json from "./truth_table.json";
export namespace TTruthTable  {

   export function  GetTransform(hConstraint: THorizontalConstraint, vConstraint: TVerticalConstraint): Transform {

       const table = table_json as ITruthTable[];

       let item =  table.find((item) => {
            return item.HorizontalConstraint === hConstraint && item.VerticalConstraint === vConstraint
        })

        const reverse = new Map(Object.values(TUnits).map(item => [item.toString(), item]))

        return {
            x: {value: 0, unit: TUnits.px, isUsed: true},
            y: {value: 0, unit:  TUnits.px, isUsed: true},
            rotation: {value: 0, unit:  TUnits.px, isUsed: false},
            width: {value: 0, unit: reverse.get(item?.WidthUnit as string) as TUnits, isUsed: item?.WidthUsed  as boolean },
            height: {value: 0, unit: reverse.get(item?.HeightUnit as string) as TUnits, isUsed: item?.HeightUsed as boolean},
            left: {value: 0, unit: reverse.get(item?.LeftUnit as string) as TUnits, isUsed: item?.LeftUsed as boolean},
            right: {value: 0, unit: reverse.get(item?.RightUnit as string) as TUnits, isUsed: item?.RightUsed as boolean},
            top: {value: 0, unit: reverse.get(item?.TopUnit  as string) as TUnits, isUsed: item?.TopUsed as boolean},
            bottom: {value: 0, unit: reverse.get(item?.BottomUnit as string) as TUnits , isUsed: item?.BottomUsed as boolean},
            translate: {
                x: {value: 0, unit: reverse.get(item?.TranslateXUnit as string) as TUnits , isUsed: item?.TranslateXUsed as boolean},
                y: {value: 0, unit: reverse.get(item?.TranslateYUnit as  string) as TUnits , isUsed: item?.TranslateYUsed as boolean},
            }
        }
    }
}
