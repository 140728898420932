import {action, makeAutoObservable, observable, runInAction} from "mobx";
import api from "../api/api";
import {
    AddRecord,
    DatasetCreate,
    DatasetDelete,
    DatasetList,
    DatasetRead,
    DatasetUpdate,
    DeleteRecord, UpdateDatasetRecord
} from "../api/datasets";

export class DatasetColumn {
    id: string = ""
    name: string = ""
    display_name: string = ""
    type: string = ""

    constructor(id = "", name = "", display_name = "", type = "") {
        this.id = id
        this.name = name
        this.display_name = display_name
        this.type = type
        makeAutoObservable(this)
    }
}



export class Dataset {
    id: string = ""
    name: string = ""
    description: string = ""
    @observable
    columns: DatasetColumn[] = []
    data: any[] = []
    records: any[] = []
    total_rows: number = 0
    created_at: string = ""
    updated_at: string = ""
    constructor(){
        this.columns = new Array<DatasetColumn>()
        makeAutoObservable(this)
    }

}

class DatasetStore{

    Step: number = 0
    datasets: Map<string, Dataset> = new Map<string, Dataset>()

    constructor(){
        makeAutoObservable(this)
        //this.fetchDatasets().then()
    }


    @action
    List(){
        const rows:any[]   = [ ];
        this.datasets.forEach((dataset) => {
            rows.push({id: dataset.id, name: dataset.name, description: dataset.description, dataSource: dataset.total_rows})
        } )
        return rows//this.DataSets
    }

    @action
    async read(id: string){

        let dataset:Dataset = await DatasetRead(id) as Dataset
        if(dataset !== undefined){
            runInAction(() => {
                makeAutoObservable(dataset)
                this.datasets.set(dataset.id, {...dataset})
            })

            return dataset
        }
        return null
    }

    @action
    addColumn(dataset_id: string, name: string, type: string){
        if(this.datasets.has(dataset_id)){
            let dataset: Dataset = this.datasets.get(dataset_id) as Dataset
            if(dataset.columns === null){
                dataset.columns = new Array<DatasetColumn>()
            }
            let tmpColumn = new DatasetColumn( dataset.columns.length.toString(), name, name, type)
            dataset.columns.push(tmpColumn)
            makeAutoObservable(dataset.columns[dataset.columns.length-1])
        }
    }

    @action
    async fetchDatasets(){
         const data_resp:any[] = await DatasetList();
             data_resp.forEach((data) => {
                 let dataset: Dataset =  {...new Dataset(), ...data}

                 //console.log(data)
                 runInAction(() => {
                     this.datasets.set(data.id, dataset)
                     makeAutoObservable(dataset)
                 })
             })
    }

    @action
    async create(DatasetName: string, test: string) {
       let data: any =  await DatasetCreate(DatasetName, test)
       this.datasets.set(data.id, {...new Dataset(), ...data})
    }

    @action
    async save(id: string) {
        console.log(id)
        const dataset = this.datasets.get(id)
        if(dataset === undefined){
            return
        }
        await DatasetUpdate(dataset)
        //this.datasets.delete(id)
    }

    @action
    async delete(id: string) {
        console.log(id)
        await DatasetDelete(id)
        this.datasets.delete(id)
        await this.fetchDatasets()

    }

     deleteColumn(id: string | undefined, id2: string) {
        if(id === undefined){
            return
        }
        if(this.datasets.has(id)){
            let dataset: Dataset = this.datasets.get(id) as Dataset
            if(dataset.columns === null){
                return
            }
            dataset.columns = dataset.columns.filter((column) => column.id !== id2)
            dataset.columns.forEach((column, index) => {
                column.id = index.toString()
            })

        }

    }

    @action
    async addDatasetRecord(id:string) {
        if(this.datasets.has(id)){

            const dataset  = await AddRecord(id) as Dataset
            this.datasets.set(dataset.id, {...dataset})
            await this.fetchDatasets()
        }
    }

    @action
    async deleteDatasetRecord(id: string, id2: string) {
        if(this.datasets.has(id)){
            let dataset: Dataset = this.datasets.get(id) as Dataset
            if(dataset.data === null){
                return
            }
            await DeleteRecord(id, id2)
            await this.read(id)
        }
    }

    async updateDatasetRecord(dataset_id: string, rowId: string, value: any) {

         await UpdateDatasetRecord(dataset_id, rowId, value)
         await this.read(dataset_id)
    }

    async attachData(datasetId: string) {
        if(this.datasets.has(datasetId)){
            let dataset: Dataset = this.datasets.get(datasetId) as Dataset
            if(dataset.data === null){
                return
            }



        }

    }
}


export const datasetStore = new DatasetStore();
