import {observer} from "mobx-react";
import Grid from "@mui/material/Grid";
import {rgbToHex, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {templateStore} from "../../stores/templates/TemplateStore";
import SolidFill from "./fills/SolidFills";
import ImageFill from "./fills/ImageFills";


const FillsSwitcher = (fill: any, index:number, id:string) => {

     switch (fill.type) {
         case "SOLID":
                    return <SolidFill fill={fill} id={id} index={index} />

         case "IMAGE":
             return <ImageFill fill={fill} id={id} index={index} />

         case "GRADIENT_LINEAR":
                return <Typography sx={{
                    fontSize: "0.8rem",
                }}>Gradient fill</Typography>

            default:
                return <Typography sx={{
                fontSize: "0.8rem",
            }}>Not implemented fill</Typography>
     }
}


interface DynamicParametersProps {

}

const FillsParametersBase = (props: DynamicParametersProps) => {

    return (
        <>
            <Typography sx={{
                fontSize: "0.8rem",
            }}>Fills</Typography>
            <Grid container
                  sx={{
                      border: "solid 1px #CCCCCC",
                      borderRadius: "4px",
                      padding: "15px",
                      minHeight: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexGrow: 1,
                      backgroundColor: "#FFFFFF",
                  }}
            >
                {templateStore.selectedNode?.fills?.map((fill, index) => {
                    return (
                        <Grid item xs={12} key={index}>
                            {FillsSwitcher(fill, index, templateStore?.selectedNode?.id as string)}
                        </Grid>
                    )
                })}
            </Grid>
        </>
    );
}

const FillsParameters = observer(FillsParametersBase)
export default FillsParameters
