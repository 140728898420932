import ky from "ky";
import {authStore} from "../stores/AuthStore";
import {errorStore} from "../stores/ErrorStore";

export const API_PREFIX = "/" ;

const api = ky.extend({
    prefixUrl: API_PREFIX,
    hooks: {
        beforeRequest: [
            request => {
               // console.log('beforeRequest')
                const token = localStorage.getItem('token')
                request.headers.set('Authorization', 'Bearer '+token);
                authStore.IsLoading = true;
               // console.log( 'IsLoading:', authStore.IsLoading);
            }
        ],
        afterResponse: [
            (_request, _options, response) => {
                //console.log('afterResponse')
                authStore.IsLoading = false;
                if(response.status === 200){
                    return response;
                }
                if (response.status === 401) {
                    authStore.logoutUser();
                     errorStore.pushError(response.status, response.statusText).catch((e)=>{console.log(e)})
                } else if (response.status === 403) {
                      errorStore.pushError(response.status, response.statusText).catch((e)=>{console.log(e)})
                     authStore.logoutUser();
                } else  {
                    console.log(response.status, response.statusText)
                    errorStore.pushError(response.status, response.statusText).catch((e)=>{console.log(e)})
                }

                //console.log( 'IsLoading:', authStore.IsLoading);
            }
            ],
        beforeError: [
            error => {
                const {response} = error;
                authStore.IsLoading = false;
                return error;
            }
        ]
    }
});

export default api;
