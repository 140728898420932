import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import menuStore from "../../stores/navigatorStore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Container,
    Modal, Stack,
    styled,
    Toolbar
} from "@mui/material";
//import Grid from "@mui/material/Grid";
import Grid from '@mui/material/Unstable_Grid2';
import {observer} from "mobx-react";
import * as React from "react";
import DnsRoundedIcon from "@mui/icons-material/DnsRounded";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import MappingIcon from "@mui/icons-material/Commit";
import {TemplateCanvas} from "./TemplateCanvas";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {Add} from "@mui/icons-material";
import {templateStore} from "../../stores/templates/TemplateStore";
import LayersList from "./LayersList";
import PropertyList from "./PropertyList";
import {runInAction} from "mobx";
import {canvasStore} from "../../stores/templates/CanvasStore";
import {PiFigmaLogo} from "react-icons/pi";
import {SiOctanerender, SiRender} from "react-icons/si";
import CompTreeView from "./CompTree";
import Divider from "@mui/material/Divider";
import VariantsBuilder from "./variants/VariantsBuilder";
import RenderPreview from "./RenderPreview";
import VariantsControls from "./variants/VariantsControls";
import DataSetControlls from "./DataSetControlls";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DynamicParameters from "./DynamicParameters";
import {FigmaImportDlg} from "./FigmaImportDlg";


const projectCategories =
    {
        categoryName: '< Back',
        path: "/dataset/",
        children: [
            { idx:1 , name: 'DataSet', icon: <DnsRoundedIcon/> , active: true, path: "datasets" },
            { idx:2 , name: 'Templates', icon: <SelectAllIcon/> , active: false, path: "templates" },
            { idx:3 , name: 'Mapping', icon:  <MappingIcon/>, active: false,path: "mapping"},
        ],
    };



function Template() {

    const [loadingOverlayOpen, setLoadingOverlayOpen] = useState(false)
    let { id, variant_id } = useParams();


    const handleImportDlg = () => {
        setLoadingOverlayOpen(true);
    }

    const handleVariantsDlgOpen = () => {
        templateStore.variants.variantsDlgOpen = true;
    }


    useEffect(() => {
        menuStore.setMenu(projectCategories);
        const load = async ()=> {
            if(id === undefined) return;
            if(variant_id){
                //console.log("readTemplateVary ", id, variant_id)
                await templateStore.readTemplate(id, variant_id)
            } else {
                //console.log("readTemplateNone ", id, variant_id)
                await templateStore.readTemplate(id)
            }

            runInAction(()=>{
                if(id === undefined) return;
                templateStore.selectedTemplateId= id;
            })
        };
        load().catch(console.error);

    }, []);



    return (<>
          <RenderPreview/>
          <VariantsBuilder/>
          <FigmaImportDlg isOpen={loadingOverlayOpen} onClose={()=>setLoadingOverlayOpen(false)} id={id?id:""} />

          <Grid container >
            <Grid  xs={12} sx={{
                borderBottom:"solid 0px #CCCCCC",
                backgroundColor:"#f3f3f3",
                margin:"0px",
                display:"flex",
                flexDirection:"row",
                height:"33px",

            }} >
                <Stack direction={"row"} spacing={1} height={"25px"}>
                    {!templateStore.isVariantViewMode &&<>
                        <Button size={"small"} variant={"outlined"}  onClick={handleImportDlg} startIcon={<PiFigmaLogo/>}>
                          Import
                        </Button>
                        <Button size={"small"} variant="outlined" startIcon={<Add />} onClick={handleVariantsDlgOpen}>
                            Variant
                        </Button></>
                    }
                    {templateStore.isVariantViewMode &&<>
                         <Button size={"small"} variant={"outlined"}  onClick={handleImportDlg} startIcon={<PiFigmaLogo/>}>
                            Import Variant
                        </Button>
                      </>
                    }
                <Button size={"small"} variant={"outlined"}  startIcon={<SiOctanerender/>} onClick={()=>{
                    templateStore.setRenderPreviewOpen(true)
                }}>
                    Render preview
                </Button>

                </Stack>
                <Box sx={{
                    marginLeft:"200px",
                    height:"30px",
                    borderBottom:"solid 1px #CCCCCC",
                    backgroundColor:"#f3f3f3",
                    display:"flex",
                    flexDirection:"row",
                    justifyContent:"center",
                    alignItems:"center",
                }}>
                    {templateStore.template && <VariantsControls/>}
                    <Divider/>
                </Box>
            </Grid>
             <Grid  xs={12} sx={{

             }}>


              </Grid>


            <Grid  xs={1.5} sx={{
                borderRight:"solid 1px #CCCCCC",
                backgroundColor:"#f3f3f3",
            }} >
                <Box sx={{
                    height:"40px",
                    borderBottom:"solid 1px #CCCCCC",
                    backgroundColor:"#f3f3f3",
                    display:"flex",
                    flexDirection:"row",
                    justifyContent:"center",
                    alignItems:"center",
                }}>
                    <Typography variant={"caption"}>
                        {templateStore.isVariantViewMode?"Variant: ":"Template: "}{templateStore.template?.name}
                    </Typography>
                </Box>
                <CompTreeView/>

                <Box sx={{
                    height:"60px",
                    borderTop:"solid 1px #CCCCCC",
                    backgroundColor:"#f3f3f3",
                    display:"flex",
                    flexDirection:"row",
                    justifyContent:"start",
                    alignItems:"center",
                }}>
                    <Stack direction={"row"} spacing={1} >
                        <DataSetControlls/>
                    </Stack>
                </Box>
                 <DynamicParameters/>
            </Grid>

            <Grid xs sx={{padding:0}}>
                <TemplateCanvas/>
            </Grid>
             <Grid  xs={2.2} sx={{
                 borderLeft:"solid 1px #CCCCCC",
                 backgroundColor:"#f3f3f3",
             }}>
                 <PropertyList/>
             </Grid>
             <Grid xs={12}  sx={{padding:0}}>
                 <LayersList/>
             </Grid>
        </Grid>
        </>
    );
}

const DatasetComponent = observer(Template);
export default React.memo(DatasetComponent);
