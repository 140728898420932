import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import {Add, Delete} from "@mui/icons-material";
import {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import * as Mobx from "mobx-react";
import { Modal, Stack} from "@mui/material";
import {
    DataGrid,
    GridColDef,
} from "@mui/x-data-grid";
import {datasetStore} from "../../stores/DatasetStore";
import DialogCustom from "../DialogCustom";
import {templateStore} from "../../stores/templates/TemplateStore";
import {useNavigate} from "react-router-dom";


const AddLinkModalstyle = {
    position: 'absolute' as 'absolute',
    top: '20%',
    left: '60%',
    transform: 'translate(-60%, -20%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Template name',
        width: 150,
        editable: false,
    },
    {
        field: 'discription',
        headerName: 'Discription',
        width: 150,
        editable: false,
    },
    {
        field: 'dataSource',
        headerName: 'Data source',
        type: 'number',
        width: 110,
        editable: false,
    },
    {
        field: "delete",
        width: 75,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ( params ) => {
            return (
                <IconButton
                    onClick={async () => {
                        console.log("delete", params.row.id)
                        await templateStore.delete(params.row.id)
                    }}
                >
                    <Delete/>
                </IconButton>
            );
        }
    }
];


 function TemplateList() {

    const navigate = useNavigate();

    const [DatasetModalShow, setDatasetModalShow] = useState(false)
    const [TemplateName, setTemplateName] = useState("Default name")
    const [TemplateDescription, setTemplateDescription] = useState("Default description")
    const [TemplateWidth, setTemplateWidth] = useState(1080)
    const [TemplateHeight, setTemplateHeight] = useState(1080)
    const handleOpen = () => {
        setTemplateName("Default name")
        setTemplateDescription("Default description")
        setTemplateWidth(1080)
        setTemplateHeight(1080)
        setDatasetModalShow(true);
    }
    const handleClose = () => {
        setDatasetModalShow(false);
    }

    useEffect(()=>{
        const callOnce  = async ()=> {
            await templateStore.fetch();
        }
        callOnce().catch(console.error);
    },[])

    return (
        <Paper sx={{ margin: '0 0 0 0', overflow: 'hidden' }}>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
            >
                <Button variant="outlined" startIcon={<Add />} onClick={()=> handleOpen()}>
                    New ad template
                </Button>
                <DialogCustom
                    open={DatasetModalShow}
                    titleEl={"New ad template"}
                    actions = {{
                       accept: async ( )=>{
                            await templateStore.create(TemplateName, TemplateDescription, TemplateWidth, TemplateHeight)
                            setDatasetModalShow(false);
                        },
                        close: handleClose
                    }}>
                    <Stack sx={{
                        width: "100%",
                        gap: 2,
                        padding: "10px"
                    }}>
                    <TextField sx={{
                        border: "1px solid #CCCCCC",
                        borderRadius: "10px",
                    }}  label="name:" value={ TemplateName} variant="outlined" size={"small"}  onChange={
                        (el)=>{
                            setTemplateName(el.target.value)
                        }
                    } />
                    <img style={{
                        borderRadius: "10px",
                        border: "1px solid #CCCCCC",
                    }} height={"300px"} src={`https://placehold.co/${TemplateWidth}x${TemplateHeight}`} alt={"error"}/>

                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%"
                    }}>

                    <TextField sx={{
                        border: "1px solid #CCCCCC",
                        borderRadius: "10px",
                    }}  label="width:" type={"number"} defaultValue={1080} value={ TemplateWidth} variant="outlined" size={"small"}  onChange={
                        (el)=>{
                            setTemplateWidth(Number.parseInt(el.target.value))
                        }
                    } />
                    <TextField sx={{
                        border: "1px solid #CCCCCC",
                        borderRadius: "10px",
                    }}  label="height:"  type={"number"} defaultValue={1080}  value={ TemplateHeight} variant="outlined" size={"small"}  onChange={
                        (el)=>{
                            //setTemplateName(el.target.value)
                            setTemplateHeight(Number.parseInt(el.target.value))

                        }
                    } />
                    </Box>
                        <TextField sx={{
                            border: "1px solid #CCCCCC",
                            borderRadius: "10px",

                        }}  multiline={true} label="Description:" value={ TemplateDescription} variant="outlined" size={"small"}  onChange={
                            (el)=>{
                                setTemplateDescription(el.target.value)
                            }
                        } />
                    </Stack>
                </DialogCustom>
            </AppBar>

            <Box sx={{ flexGrow: 1, p: 2 , width: '100%'  }} >
                <DataGrid
                    rows={templateStore.List()}
                    columns={columns}
                    onRowDoubleClick={(params) => {
                        console.log("edit", params.row.id)
                        navigate("/template/"+params.row.id)

                    }}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                />

            </Box>

        </Paper>
    );
}
export default Mobx.observer(TemplateList)
