
import React from 'react';


import {observer} from "mobx-react";
import TextField from "@mui/material/TextField";

interface DropboxProps {
    children: React.ReactNode;
    disabled?: boolean;
    label?: string;
    className?: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    defaultValue?: string;
}

function Dropbox(props: DropboxProps) {
  const { children, ...rest } = props;
  return (
      <TextField
          select
          variant="outlined"
          disabled={props.disabled}
          margin={"none"}
          size={"small"}
          label={props.label}
          defaultValue={props.defaultValue}
          inputProps={{
              style: {

                  height: "30px",
                  width: "130px",
                  fontSize:"0.8rem",
              }
          }}
          SelectProps={{
              style: {

                  height: "30px",
                  width: "130px",
                  fontSize:"0.8rem",
              }
          }}
          InputLabelProps={{
              style: {

                  height: "25px",
                  width: "130px",
                  fontSize:"0.8rem",
              }
          }}
          value={props.disabled}
          onChange={props.onChange}
      >
          {props.children}
    </TextField>
  );
}

export default observer(Dropbox);
