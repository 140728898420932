import {observer} from "mobx-react";
import Grid from "@mui/material/Grid";
import {Checkbox, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {templateStore} from "../../stores/templates/TemplateStore";
import {runInAction} from "mobx";
import {TText} from "../../contracts/implementations/TTextItem";
import uniqKeyGenerator from "../../utils/kegen";
import DynamicFieldsCB from "../widgets/DynamicFieldsCB";
import {TBaseItem} from "../../contracts/implementations/TBaseItem";
import {TDynamicValueTypes} from "../../contracts/TemplateContracts";


interface DynamicParametersProps {

}

function listFontFamilies() {
    // @ts-ignore
    const fontFaces = [...document.fonts.values()];
    const families = fontFaces.map(font => font.family);

    // converted to set then to array to remove duplicates
    // @ts-ignore
    return [...new Set(families)];
}

const TextParametersBase = (props: DynamicParametersProps) => {


    const handleSetFontSize = (e: React.ChangeEvent<HTMLInputElement>) => {

            let textNode = templateStore.selectedNode as TText
            if(textNode) {
                textNode.SetFontSize(parseFloat(e.target.value))
            }

    }

    useEffect(() => {

    }, []);

    return (
        templateStore?.selectedNode?.type === "TEXT" ?
        <>
            <Typography sx={{
                fontSize: "0.8rem",
            }}>Text Parameters</Typography>
            <Grid container
                  sx={{
                      border: "solid 1px #CCCCCC",
                      borderRadius: "4px",
                      padding: "15px",
                      height: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexGrow: 1,
                      backgroundColor: "#FFFFFF",

                  }}>
                    <Grid xs={6} item>

                    <FormControl sx={{ m: 0, minWidth: 80 }}>
                          <InputLabel sx={{fontSize:"0.7rem"}}>Font</InputLabel>
                          <Select label="Font" sx={{
                                fontSize: "0.8rem",
                                height: "20px",
                                borderRadius: "0px",
                            }}  size={"small"} defaultValue={templateStore.textNode?.text_style?.fontFamily}
                                    onChange={(e) => {
                                        let textNode = templateStore.selectedNode as TText
                                        if(textNode) {
                                            textNode.setFontFamily(e.target.value as string)
                                        }
                                    }}>
                                {listFontFamilies().map((font) => {
                                    return <MenuItem  key={uniqKeyGenerator()} value={font}>{font}</MenuItem >
                                })}
                            </Select>
                      </FormControl>

                    </Grid>
                    <Grid xs={6} item sx={{
                        fontSize: "0.8rem",

                    }}> size
                        <input
                            type={"number"}
                            min={0}
                        style={{
                            width: "50px",
                            height: "20px",
                        }}
                        value={templateStore.textNode?.text_style?.fontSize} id="outlined-basic"
                        onChange={handleSetFontSize}
                    />

                    </Grid>

                <Grid xs={12} item sx={{
                    fontSize: "0.8rem",

                }}> LineHeight:
                    <input
                        type={"number"}
                        min={0}
                        style={{
                            width: "80px",
                            height: "20px",
                        }}
                        value={templateStore.textNode?.text_style?.lineHeightPercentFontSize} id="outlined-basic"
                        onChange={(e)=>{
                            let textNode = templateStore.selectedNode as TText
                            if(textNode) {
                                textNode.setLineHeightPx(parseFloat(e.target.value))
                            }
                        }}
                    />

                </Grid>
                <Grid xs={12} item sx={{  fontSize: "0.8rem",  }}> LetterSpacing:
                    <input
                        type={"number"}
                        min={0}
                        style={{
                            width: "80px",
                            height: "20px",
                        }}
                        value={templateStore.textNode?.text_style?.letterSpacing} id="outlined-basic"
                        onChange={(e)=>{
                            let textNode = templateStore.selectedNode as TText
                            if(textNode) {
                                textNode.setLetterSpacing(parseFloat(e.target.value))
                            }
                        }}
                    />
                </Grid>

                <Grid xs={12} item sx={{  fontSize: "0.8rem",  }}>
                    Scalable:
                   <Checkbox
                       size={"small"}
                       checked={templateStore.textNode?.text_style?.isScalable}
                       onChange={(e)=>{runInAction(() => {
                           let textNode = templateStore.selectedNode as TText
                           if(textNode) {
                               textNode.setTextScalable(e.target.checked)
                           }
                       })}}></Checkbox>
                     Dynamic field : <DynamicFieldsCB node={templateStore.selectedNode as TBaseItem } type={TDynamicValueTypes.Text}/>
                </Grid>
                {templateStore.selectedNode?.is_dynamic&&
                <Grid xs={12} item style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    flexGrow: 1,
                    height: "100%",
                }}>
                   <textarea
                    style={{
                        width: "210px",
                        height: "50px",
                        position: "relative",
                    }}
                    value={templateStore.textNode?.text} id="outlined-basic"
                    onChange={(e)=>{runInAction(() => {
                        let textNode = templateStore.selectedNode as TText
                        if(textNode) {
                            textNode.setText(e.target.value)
                        }
                    })}}
                />

                </Grid>}
            </Grid>
        </>:<></>
    );
}

const TextParameters = observer(TextParametersBase)
export default TextParameters
