import {action, makeAutoObservable, runInAction} from "mobx";
import {AssetDelete, GetAssetsList, StorageUploadFile} from "../api/assets";

class AssetsStore{

    assets: Map<string, any> = new Map<string, any>()

    constructor() {
        makeAutoObservable(this)
    }

    @action
    async fetchAssets(dataset_id: string| undefined){
        if (dataset_id === undefined){
            console.log("dataset_id is undefined")
            return
        }
        assetsStore.assets.clear()

        try {
            const resp = await GetAssetsList(dataset_id)
            resp.forEach((itemAsset: any) => {
                const item = itemAsset
                runInAction(() => {
                    assetsStore.assets.set(item.id, {
                        src: item.url,
                        meta: item,
                    })
                })

            })

            //console.log(assetsStore.assetsArray)
            return resp
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    @action
    async upload(file: any){
        try {
            const resp = await StorageUploadFile("3f206a5a-a763-44f3-a856-bc3782515aff", file)

            assetsStore.assets.set(resp.meta.id,{
                src: resp.url,
                meta: resp.meta,
            })

            console.log(resp)
            return resp.meta.id

        } catch (e) {
            console.log(e);
            throw e;
        }
    }
    @action
    async delete(id:string){
        try {
                await AssetDelete(id)
                assetsStore.assets.delete(id)
                return
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

}



export const assetsStore = new AssetsStore();
