import * as React from 'react';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import {TreeItem, treeItemClasses, TreeItemProps} from "@mui/x-tree-view/TreeItem";
import {templateStore} from "../../stores/templates/TemplateStore";
import {TFrame, ITemplate, IText, TVector} from "../../contracts/TemplateContracts";
import {styled, SvgIconProps} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import MailIcon from '@mui/icons-material/Mail';
import { LuFrame } from "react-icons/lu";
import { LiaVectorSquareSolid } from "react-icons/lia";
import { PiTextTThin } from "react-icons/pi";
import { PiRectangleThin } from "react-icons/pi";
import { BsMask } from "react-icons/bs";
import { GrDomain } from "react-icons/gr";
import {useEffect, useState} from "react";
import {canvasStore} from "../../stores/templates/CanvasStore";

declare module 'react' {
    interface CSSProperties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    bgColorForDarkMode?: string;
    color?: string;
    colorForDarkMode?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
    node?: TFrame | TVector | IText;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
})) as unknown as typeof TreeItem;

const StyledTreeItem = React.forwardRef(function StyledTreeItem(
    props: StyledTreeItemProps,
    ref: React.Ref<HTMLLIElement>,
) {
    const [node, setNode] = React.useState<TFrame | TVector | IText | undefined>(props.node);
    const theme = useTheme();
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;

    const styleProps = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <StyledTreeItemRoot
            label={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 0.5,
                        pr: 0,
                    }}
                >
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={styleProps}
            {...other}
            ref={ref}
        />
    );
});


const mapIcon = (type: string) => {
    switch (type) {

        case "main_frame":
            return GrDomain

        case "container":
            return LuFrame
        case "solid_vector":
            return LiaVectorSquareSolid
        case "text":
            return PiTextTThin
        case "rectangle":
            return PiRectangleThin
        case "mask_vector":
            return BsMask


        default:
            return PiRectangleThin
    }
}

export default function CompTreeView() {

    const [expanded, setExpanded] = useState<string[]>([]);


    //id generator UID
    const IdGenerator = () => {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    useEffect(() => {
        setExpanded((oldExpanded:any) => {
            if (!oldExpanded.includes(templateStore?.template?.id)) {
                return [...oldExpanded, templateStore?.template?.id];
            }
            return oldExpanded;
        });
    }, [templateStore?.template?.id]);

    const renderTree = (nodes: any) => {
       return(
            <StyledTreeItem key={IdGenerator()} nodeId={nodes?.id?nodes?.id:"null"} labelText={nodes?.name} labelIcon={mapIcon(nodes?.convert_type)}>
            {Array.isArray(nodes?.children)
                ? nodes.children.map((node:any) => renderTree(node))
                : null}
             </StyledTreeItem>
        )
    }

    const handleToggle = (event:any, nodeIds:string[]): void =>{
        let id = templateStore?.template?.id
        if (nodeIds.includes(id?id:"")) {
            setExpanded(nodeIds)
        }
    };
    const selectNode = (event: React.SyntheticEvent, nodeIds: string) => {
       // console.log("selectNode", nodeIds)
        templateStore.setSelectedNode(nodeIds)
        canvasStore.selectElement()
    }

    return (
        <Box sx={{ minHeight: 110, flexGrow: 1, maxWidth: 300 }}>
            <TreeView
                expanded={expanded}
                onNodeToggle={handleToggle}
                //disabledItemsFocusable={false}
                aria-label="rich object"
                // @ts-ignore
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={[templateStore?.template?.id?templateStore?.template?.id:""]}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeSelect={selectNode}
            >
                {renderTree(templateStore.template)}
            </TreeView>
        </Box>
    );
}

