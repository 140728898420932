import {action, makeAutoObservable, runInAction} from "mobx";
import {templateStore} from "./TemplateStore";
import {TemplateAPI} from "../../api/templates";



class CanvasStore {

    iframe: HTMLIFrameElement|undefined
    width: number = 0
    height: number = 0
    scale: number = 0.5
    manualResizing: boolean = false
    resizeObserver: ResizeObserver | undefined
    SelectionBoundsElement: HTMLElement | null = null
    SelectionBoundsElementName: HTMLElement | null = null
    IsMainFrameSelected: boolean = false
    isRendering: boolean = false
    shadowDom: ShadowRoot | undefined

    get isMainFrameSelected(){
        return this.IsMainFrameSelected
    }


    get currentElement(){

        if (this.iframe === undefined)
            return null
        if (templateStore.selectedNode === null)
            return null
        let currentNode = templateStore.selectedNode
        let id = currentNode.id
        if(id) {
            runInAction(() => {
                if (currentNode.convert_type === "main_frame") {
                    this.IsMainFrameSelected = true
                } else {
                    this.IsMainFrameSelected = false
                }
            })
            let res =  this.currentDocument?.getElementById(id) as HTMLElement
            if (res !== null) {
                console.log("currentElement", res)
                return res
            }
        }
        return null
    }


    get currentDocument(){
        if (this.iframe === undefined)
            return null
        return this.iframe?.contentWindow?.document
        //return this.shadowDom.
    }

    @action
    selectElement(){
        let element = this.currentElement
        if (element === null)
            return
        let doc = this.currentDocument || undefined
        if (doc === undefined)
            return
        if (this.iframe === undefined)
            return
        if (this.SelectionBoundsElement == null){
            this.SelectionBoundsElement = doc.createElement("div")
            this.SelectionBoundsElement.style.position =  "fixed"
            this.SelectionBoundsElement.style.border = `${1/(this.scale!==0?this.scale:1)}px solid #FFFFFF`
            this.SelectionBoundsElement.style.borderStyle = "dashed "
            this.SelectionBoundsElement.style.pointerEvents = "none"
            this.SelectionBoundsElement.style.zIndex = "1000000"
            this.SelectionBoundsElement.style.boxSizing = "border-box"
            this.SelectionBoundsElement.style.backgroundColor = "rgba(0,0,0,0)"
            this.SelectionBoundsElement.style.overflow =  "visible"
            this.SelectionBoundsElementName = doc.createElement("div")
            this.SelectionBoundsElement.appendChild( this.SelectionBoundsElementName)
            this.SelectionBoundsElementName.style.position = "absolute"
            this.SelectionBoundsElementName.style.left = "0"
            this.SelectionBoundsElementName.style.top = "-40px"
            this.SelectionBoundsElementName.style.fontSize = "24px"
            this.SelectionBoundsElementName.style.fontFamily = "sans-serif"
            this.SelectionBoundsElementName.style.color = "#FFFFFF"
            this.SelectionBoundsElementName.style.padding = "0"
            this.SelectionBoundsElementName.innerText = "test"
            this.SelectionBoundsElementName.style.zIndex = "1000001"


            doc.body.appendChild(this.SelectionBoundsElement)


        }else {
            let iframeRect = this.iframe.getBoundingClientRect()
            let rect = element.getBoundingClientRect()
            this.SelectionBoundsElement.style.left =rect.left + "px"
            this.SelectionBoundsElement.style.top = rect.top + "px"

            this.SelectionBoundsElement.style.width = rect.width+ "px"
            this.SelectionBoundsElement.style.height = rect.height + "px"
            if(this.SelectionBoundsElementName){
                if(templateStore.selectedNode?.name) {
                    this.SelectionBoundsElementName.innerText = templateStore.selectedNode?.name
                }
            }
        }
    }

    @action
    setManualResize(value: boolean){
        this.manualResizing = value
    }

    @action
    setIframe(iframe: HTMLIFrameElement){
        this.iframe = iframe
    }

    @action
    set_width(value:number | undefined){
        if(value === undefined)
            return
        this.width = value

    }

    @action
    setLayerConstraintV(value: string ){
        console.log("set_layer_constraint_vertical", value)

    }

    @action
    setLayerConstraintH(value: string ){
        console.log("set_layer_constraint_horizontal", value)

    }

    @action
    set_height(value:number | undefined){
        if(value === undefined)
            return
        this.height = value
    }


    RenderCanvas(){

             let iframe = this.iframe
             let id = templateStore.template?.orm_id

            TemplateAPI.RenderTemplate(id as string, templateStore.template).then((data) => {
                if(iframe?.srcdoc) {
                        const baseUrl = window.location.origin+"/api/template/"
                        data = data.replace(/<head>/, `<head><base href="${baseUrl}">`)
                        iframe.srcdoc = data
                }
                this.selectElement()

            }).catch((err) => {
                console.log(err);
            });

    }


    constructor() {
        makeAutoObservable(this)
    }
    @action
    setScale(number: number) {
        this.scale = number
    }
}

export const canvasStore = new CanvasStore();
