import {
    IBaseItem,
    IValue,
    TColorFill,
    TConstraint,
    TGradient,
    TImage,
    Transform
} from "../TemplateContracts";
import {templateStore} from "../../stores/templates/TemplateStore";
import {action, makeAutoObservable, makeObservable, observable} from "mobx";
import {TValue} from "./TValue";


/**
 * TBaseItem Class
 * Represents a base item in a template.
 * @implements {IBaseItem}
 */
export class TBaseItem implements IBaseItem {
    /**
     * The name of the variable (optional).
     *
     * @type {string | undefined}
     */
    @observable name?: string | undefined;
    /**
     * Represents a variable of type string, which can be either a string value or undefined.
     *
     * @typedef {string|undefined} StringType
     */
    @observable type?: string | undefined;
    /**
     * Represents an ID.
     * @typedef {string|undefined} ID
     */
    @observable id?: string | undefined;
    /**
     * Represents a file path.
     *
     * @typedef {string} Path
     */
    @observable path?: string | undefined;
    /**
     * Converts the given value to the specified type.
     *
     * @param {string | undefined} convert_type - The type to convert the value to.
     * @returns {any} - The converted value.
     */
    @observable convert_type?: string | undefined;
    /**
     * Represents the width value.
     *
     * @param {TValue} width - The width value to be represented.
     * @constructor
     */
    @observable width: TValue;
    /**
     * Represents the value of height.
     *
     * @typedef {TValue} height
     */
    @observable height: TValue;
    /**
     * The parentId variable specifies the identifier of a parent entity.
     *
     * @type {string}
     */
    @observable parentId: string = "";
    /**
     * Represents the constraints for a variable.
     * @typedef {TConstraint | undefined} Constraints
     */
    @observable constraints?: TConstraint | undefined;
    /**
     * Represents the variable `transform` which can be of type `Transform`.
     *
     * @typedef {Transform | undefined} transform
     */
    @observable transform?: Transform | undefined;
    /**
     * An array of fill objects which can be either a gradient, an image, or a color fill.
     *
     * @typedef {TGradient | TImage | TColorFill} Fill
     */
    @observable fills?: (TGradient | TImage | TColorFill)[] | undefined;
    /**
     * Represents the current visibility state.
     *
     * @typedef {(boolean | IValue | undefined)} visible
     */
    @observable visible?: boolean | IValue | undefined;
    /**
     * Represents whether a variable is dynamic or not.
     *
     * @typedef {boolean | null | undefined} is_dynamic
     */
    @observable is_dynamic?: boolean | null | undefined;
    /**
     * A function to retrieve the parent node from the template store.
     *
     * @returns {Object} The parent node object.
     */
    parent = () => {
        if (templateStore.nodesIndex) {
            return templateStore.nodesIndex.get(this.parentId);
        }
    };

    /**
     * Constructor for creating an instance of the class.
     * Initializes the width and height properties with new TValue objects.
     * Also makes the instance observable.
     *
     * @constructor
     * @memberof ClassName
     */
    constructor() {
        this.width = new TValue();
        this.height = new TValue();
        makeObservable(this);
    }
    @action
    setDynamic(b: boolean) {
        this.is_dynamic = b;
    }
}
