import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {Modal, Stack, styled} from "@mui/material";
import {templateStore} from "../../../stores/templates/TemplateStore";
import {observer} from "mobx-react";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import {AddBox, Delete} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {LuEqual} from "react-icons/lu";
import {TbMathEqualGreater, TbMathEqualLower, TbMathGreater, TbMathLower} from "react-icons/tb";
import Typography from "@mui/material/Typography";
import {TCondition, TConditionKey, TConditionOperator} from "../../../contracts/TemplateContracts";
import variantStore from "../../../stores/templates/VariantStore";

const conditionOperators =
[
    { value: TConditionOperator.EQUAL, label: <LuEqual /> },
    { value: TConditionOperator.GREATER_THAN, label:  <TbMathGreater />},
    { value: TConditionOperator.GREATER_THAN_OR_EQUAL, label: <TbMathEqualGreater />},
    { value: TConditionOperator.LESS_THAN, label: <TbMathLower/> },
    { value: TConditionOperator.LESS_THAN_OR_EQUAL, label: <TbMathEqualLower/> },
];

const fieldOptions = [
    { value: 'width', label: 'width' },
    { value: 'height', label: 'height' },
    { value: 'aspectRate', label: 'aspectRate' },
];






const TextFieldStyled = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        borderRadius: "0px",
        border: `0px solid ${theme.palette.secondary.main}`,
        color: "#0c2d36",
        fontSize: "12px",
        padding: "0px 5px 0px 5px",
        margin: "5px 0px 5px 0px",
        width: "100px",
        height: "30px",
    },
    "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before": {
        border: `0px solid ${theme.palette.secondary.main}`,
    },
    "& .MuiInputBase-root.Mui-focused": {
        border: `0px solid ${theme.palette.secondary.main}`,
    },

    '& .MuiInputBase-input': {
        padding: "0px 5px 0px 5px",
        margin: "0px",
        width: "100px",
        height: "30px",
        fontSize: "10px",
    },

}));

type ChildComponentProps = {
    conditionNode: TCondition;
    index: number;
    onUpdate: (newValue: any, index:number, type:string) => void;
    onDelete: (idx: number) => void;
};

const ConditionBox: React.FC<ChildComponentProps> = ( { conditionNode, onUpdate, index, onDelete }) => {


    return (<Box sx={{
        display: "flex",
        flexDirection: "column",
        justifyItems: "start",
        justifyContent: "start",
        alignItems: "center",
        backgroundColor: "#f3f3f3",
        border: "1px solid #CCCCCC",
        borderRadius: "5px",
        padding: "5px",
        margin: "5px",
    }}>
        <Box>
        <Grid container>

       <Grid item xs={12}>
        <TextFieldStyled
            select
            label="Feature"
            value={conditionNode.feature}
            onChange={(v)=>{
                onUpdate(v.target.value,   index, "Feature")
            }}
        >
            {fieldOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextFieldStyled>
        <TextFieldStyled

            select
            label="Operator"
            value={conditionNode.operator}
            onChange={(v)=>{
                onUpdate(v.target.value, index, "Operator")
            }}
        >
            {conditionOperators.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextFieldStyled>
           <TextFieldStyled
               label="value"
               type={"number"}
               value={conditionNode.value}
               onChange={(v)=>{
                   onUpdate(v.target.value, index, "value")
               }}
           >
               {fieldOptions.map((option) => (
                   <MenuItem key={option.value} value={option.value}>
                       {option.label}
                   </MenuItem>
               ))}
           </TextFieldStyled>
           <IconButton onClick={()=>{
                onDelete(index)
           }}>
               <Delete/>
           </IconButton>
            </Grid>
        </Grid>
        </Box>

    </Box> )
}
type Props = {
    children: JSX.Element | JSX.Element[];
};
const FieldBox: React.FC<Props>  = ({ children }:Props) => {

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
        }}>
            {children}
        </Box>
    )
}



const VariantsBuilderBase: React.FC = () => {

    const [variantName, setVariantName] = useState('Variant Name');
    const [defaultWidth, setDefaultWidth] = useState(0);
    const [defaultHeight, setDefaultHeight] = useState(0);
    const [conditionsList, setConditionsList] = useState<TCondition[]>([]);
    const [aspectRatio, setAspectRatio] = useState('');
    const [editMode, setEditMode] = useState(false);

    const handleAddCondition = () => {
        const newCondition: TCondition = {
            feature:  TConditionKey.WIDTH,
            operator: TConditionOperator.EQUAL,
            value: "100",
        };
        setConditionsList([...conditionsList, newCondition]);
    }

    const generateMediaQuery = () => {

        templateStore.variants.addVariant(variantName, conditionsList);
        templateStore.variants.variantsDlgOpen = false;
    };

    const updateVariant = async () => {
        templateStore.variants.updateVariantConditions(variantName, conditionsList);
        templateStore.variants.variantsDlgOpen = false;
        templateStore.variants.EditMode = false;
        await templateStore.variants.updateCurrent()

    }

    useEffect(() => {
        setEditMode(templateStore.variants.EditMode)
        if(!templateStore.variants.EditMode) {
            setDefaultWidth(templateStore.template.width.value as number);
            setDefaultHeight(templateStore.template.height.value as number);
            setConditionsList([]);
            setVariantName('Variant Name');
        }
           else {
                setDefaultWidth(templateStore.template.width.value as number);
                setDefaultHeight(templateStore.template.height.value as number)
                setConditionsList(templateStore.variants?.selectedVariant?.conditions as TCondition[]);
                setVariantName(templateStore.variants?.selectedVariant?.name as string);
            }
    }, [templateStore.variants.variantsDlgOpen]);

    return (
        <Modal
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

            }}
            open={templateStore.variants.variantsDlgOpen}
            onClose={() => {
                templateStore.variants.variantsDlgOpen = false;
            }}
        >
        <Box sx={{
            width: "45vw",
            position:"absolute",
            top:"50vh",
            left:"50vw",
            transform:"translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
        }}>

            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Typography >
                        Variant editor
                    </Typography>
                </Grid>
                <Grid item xs={12} >
                    <Typography sx={{
                        backgroundColor: "#f3f3f3",
                        border: "1px solid #CCCCCC",
                        padding: "5px",
                        fontSize: "0.8rem"
                    }}>
                        Result:
                        <Typography sx={{
                            backgroundColor: "#faf9f9",
                            border: "1px solid #CCCCCC",
                            padding: "5px",
                            fontWeight: "bold",
                            textAlign: "center",
                        }}>
                        ({templateStore.variants.buildFormula(conditionsList)})
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",

                }}>
                    <FieldBox>
                        <Typography sx={{ marginRight: "10px" , fontSize: "0.8rem" }}   >
                             Name
                        </Typography>
                        <TextFieldStyled
                            value={variantName}
                            onChange={ (event) => {
                                setVariantName(event.target.value)
                            } }
                        />
                    </FieldBox>
                    <FieldBox>
                    <Typography sx={{ marginRight: "10px" , fontSize: "0.8rem"   }} >
                        Default width
                    </Typography>
                    <TextFieldStyled
                        type={"number"}
                        value={defaultWidth}
                        onChange={ (event) => {
                            setDefaultWidth(parseInt(event.target.value))
                        } }
                    />
                    </FieldBox>
                    <FieldBox>
                    <Typography sx={{ marginRight: "10px" , fontSize: "0.8rem"   }} >
                        Default height
                    </Typography>
                    <TextFieldStyled
                        value={defaultHeight}
                        onChange={ (event) => {
                            setDefaultHeight(parseInt(event.target.value))
                        } }
                    />
                    </FieldBox>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <IconButton onClick={handleAddCondition}>
                            <AddBox/>
                        </IconButton>
                    </Grid>
                    <List>
                        <Stack>
                            {conditionsList.map((condition:TCondition, index) => {
                                console.log("condition", condition)
                                // @ts-ignore
                                return (  <ConditionBox conditionNode={condition} index={index} onUpdate={(value, index,type:string)=>{

                                                switch (type) {
                                                    case "Feature": {
                                                        let tmp: TCondition[] = [...conditionsList];
                                                        tmp[index].feature = value;
                                                        setConditionsList(tmp);
                                                    }
                                                        break;
                                                    case "Operator": {
                                                        let tmp: TCondition[] = [...conditionsList];
                                                        tmp[index].operator = value;
                                                        setConditionsList(tmp);
                                                    }
                                                        break;
                                                    case "value": {
                                                        let tmp: TCondition[] = [...conditionsList];
                                                        tmp[index].value = value;
                                                        setConditionsList(tmp);
                                                    }
                                                        break;
                                                }


                                }} onDelete={(idx:number)=>{
                                    const newList = [...conditionsList];
                                    newList.splice(idx, 1);
                                    setConditionsList(newList);

                                }}/> )
                            })}
                        </Stack>
                    </List>
                </Grid>


                </Grid>
           {!editMode?
                <Button variant="outlined" onClick={generateMediaQuery}>
                    Create Variant
                </Button>
             :
                <Button variant="outlined" onClick={updateVariant}>
                    Update Variant
                </Button>
            }

        </Box>
        </Modal>
    );
};
const VariantsBuilder  = observer(VariantsBuilderBase);
export default React.memo(VariantsBuilder)
