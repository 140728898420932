import {observer} from "mobx-react";
import {FontsList} from "./FontsList";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {ImportContacts, ImportExport, Search, UploadFile, UploadSharp} from "@mui/icons-material";
import {ButtonGroup, Input, Modal} from "@mui/material";
import Button from "@mui/material/Button";
import {BsFonts} from "react-icons/bs";
import Divider from "@mui/material/Divider";
import GoogleFontsSelector from "./GoogleFontsSearch";
import {useState} from "react";
import {UploadFonts} from "./UploadFonts";


function FontsBase(){

    const [googleFontsImportOpen, setGoogleFontsImportOpen] = useState(false)
    const [uploadFontsOpen, setUploadFontsOpen] = useState(false)
    return( <Box sx={{
        backgroundColor:"#f3f3f3",
        padding:"10px",
        minHeight:"85vh",
    }}>
                <Modal open={googleFontsImportOpen} onClose={()=>setGoogleFontsImportOpen(false)}>
                    <GoogleFontsSelector onClose={()=>setGoogleFontsImportOpen(false)} />
                </Modal>
                <Modal open={uploadFontsOpen} onClose={()=>setUploadFontsOpen(false)}>
                    <UploadFonts onClose={()=>setUploadFontsOpen(false)} />
                </Modal>
                <Grid container>
                    <Grid item xs={12}>
                    <h1>Fonts</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                        <Button onClick={()=>setUploadFontsOpen(true)}  startIcon={<UploadFile/>}>Upload font</Button>
                        <Button onClick={()=>setGoogleFontsImportOpen(true)}  startIcon={<BsFonts/>}>Import from Google Fonts</Button>
                  </ButtonGroup>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                       <FontsList/>
                    </Grid>
                </Grid>
            </Box>)
}

export const FontsPage = observer(FontsBase)
