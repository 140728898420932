import {action, flow, makeAutoObservable} from "mobx";
import {StorageUploadFile} from "../api/assets";
import {authStore} from "./AuthStore";
import {FontsAPI} from "../api/fonts";
import {IFont} from "../contracts/interfaces/IFonts";


class FontsStore {
        fontList: IFont[] = []

    constructor() {
            makeAutoObservable(this)
            this.GetFontsList().then(
                () => console.log("Fonts loaded")
            ).catch(e=>console.log(e))

    }

    get FontList(){

        return this.fontList
    }

    GetFontsList = flow(function* (this:FontsStore) {
        let fontList =  yield FontsAPI.GetFontsList()
        this.fontList =  [...fontList]
    })

    async LoadFont(font:IFont, doc:Document = document) {
        let file = await FontsAPI.GetFontFile(font)
        let new_font = new FontFace(font.familyName, `url(${URL.createObjectURL(file)})`)
        new_font = await new_font.load();
        font.loaded = true
        doc.fonts.add(new_font);
    }

    @action
    async AddFont(currentFont: any, fontFile: File) {
        try {

            const font: IFont = {
                id: "",
                type: currentFont.type,
                fullName: currentFont.fullName,
                familyName: currentFont.familyName,
                subfamilyName: currentFont.subfamilyName,
                postscriptName: currentFont.postscriptName,
                filename: fontFile.name,
                owner: authStore.user?.id,
            }

            const resp = await FontsAPI.UploadFont(font, fontFile) as IFont
            await this.LoadFont(resp)
            this.fontList = [...this.fontList, resp]

        } catch (e) {
            console.log(e);
            throw e;
        }

    }
    @action
    DeleteFont(font: IFont) {
        try {
            this.fontList = this.fontList.filter(f => f.id !== font.id)
            return FontsAPI.DeleteFont(font)
        } catch (e) {
            console.log(e);
            throw e;
        }

    }
}


export const fontsStore = new FontsStore();
