

import React, {useEffect, useState} from "react";
import {templateStore} from "../../stores/templates/TemplateStore";
import {useParams} from "react-router-dom";
import {Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, Input, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import ConstraintsControl from "./ConstraintsControls";
import {observer} from "mobx-react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextParameters from "./TextParameters";
import Box from "@mui/material/Box";
import FillsControls from "./FillsControls";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
//FormControlGroup
import FormControlGroup from '@mui/material/FormControl';

const PropertyListBase = () => {

    const { id } = useParams();
    const templates = templateStore.templates;
    const [selectedLayer, setSelectedLayer ] = useState("")


    const handleSave = async () => {
        if(templateStore.isVariantViewMode){
            await templateStore.variants.updateCurrent()
        }else {
            await templateStore.Update()
        }
    }
    // @ts-ignore
    return (
        <>
            <Stack sx={{
                top:0,
                maxHeight: "60vh",
                overflow:'auto',
                overflowY: 'scroll',
            }}>
                <Grid container spacing={2} direction="column" alignItems="left">
                    <Grid item sx={{
                        padding: "10px",
                        margin: "10px",
                    }}>
                        <Typography sx={{
                            fontSize: "0.8rem",
                        }}>  name: {templateStore.currentNode?.name}</Typography>
                        <Typography sx={{
                            fontSize: "0.8rem",
                        }}>  type: {templateStore.currentNode?.type}</Typography>
                        <Typography sx={{
                            fontSize: "0.8rem",
                        }}>  is mask: {templateStore.currentNode?.is_mask?"true":"false"}</Typography>
                        <FormControlGroup>
                        <FormControlLabel
                            sx={{
                              padding: "0px",
                                margin: "0px",
                                "& .MuiFormControlLabel-label": {
                                    fontSize: "0.8rem",
                                 },
                                 "& .MuiCheckbox-root": {
                                    padding: "0px",
                                    margin: "0px",
                                 }
                            }}
                            value={templateStore?.selectedNode?.clips_content}
                            control={<Checkbox  size={"small"} />}
                            label="Clips content"
                            labelPlacement="start"
                            onChange={(e:any) => {

                                if(templateStore.currentNode){
                                    templateStore.setClipsContent(e.target.checked)
                                }

                            }}
                        />

                        <FormControlLabel
                            sx={{
                                padding: "0px",
                                margin: "0px",
                                "& .MuiFormControlLabel-label": {
                                    fontSize: "0.8rem",
                                },
                                "& .MuiCheckbox-root": {
                                    padding: "0px",
                                    margin: "0px",
                                }
                            }}
                            value={templateStore?.selectedNode?.visible as boolean}
                            control={<Checkbox size={"small"}/>}
                            label="Visible: "
                            labelPlacement="start"
                            onChange={(e:any) => {

                                if(templateStore.currentNode){
                                    templateStore.setVisible(e.target.checked)
                                }

                            }}
                        />
                        </FormControlGroup>
                    </Grid>
                </Grid>
                {templateStore.currentNode && <FillsControls/>}
                <Divider/>
                {templateStore?.selectedNode?.convert_type === "solid_vector" ?
                    <>  <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      maxHeight: "200px",
                      backgroundColor: "#FFFFFF",
                  }}>
                      <img height={100} src={`data:image/svg+xml;utf8,${encodeURIComponent(templateStore?.selectedNode?.svg)}`}/>
                  </Box>
                   </>
                    : <> </>}
                {templateStore?.selectedNode?.convert_type !== "main_frame" ?
                    <>
                         {templateStore.currentNode&&<ConstraintsControl/>}
                        <Divider/>
                        <TextParameters/>
                        <Divider/>
                    </>
                    : <> </> }
            </Stack>
            <Button onClick={handleSave}>save</Button>
        </>
    );
};

const PropertyList = observer(PropertyListBase)
export default PropertyList
