import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Chip, Modal, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {canvasStore} from "../../stores/templates/CanvasStore";
import Typography from "@mui/material/Typography";
import {toPng , toCanvas, toJpeg} from "html-to-image";
import Button from "@mui/material/Button";
import {render} from "@testing-library/react";
import IconButton from "@mui/material/IconButton";
import {SaveAs} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import previews from "../../dev/previews";
import {templateStore} from "../../stores/templates/TemplateStore";
import Divider from "@mui/material/Divider";
import DataSetControlls from "./DataSetControlls";
import {renderQueue} from "../../stores/RenderqStore";


const textStyles =  {
    fontSize: "0.8rem",
        width: "100px",
        height: "30px",
        padding: "0px",
        paddingLeft: "10px",
        margin: "0px",
}


function RenderPreviewBase () {

    interface Resize {
        width: number;
        height: number;
    }

    const [images, setImages] = React.useState<string[]>([]); // [
    const [width, setWidth] = React.useState<number>(0)
    const [height, setHeight] = React.useState<number>(0)
    const [resizes, setResizes] = React.useState<Resize[]>([ ]);
    const [currentUrl, setCurrentUrl] = React.useState<string>("");

    const addPreviewhandle = () => {
        setResizes([...resizes, { width: width, height: height }]);
        setWidth(0)
        setHeight(0)
        console.log("resizes", resizes)
    }
    const handleRemoveResize = (index:number) => {
        setResizes(resizes.filter((_, i) => i !== index));
    }

    const handleClose = () => {
        templateStore.setRenderPreviewOpen(false)
    }

    const handleSaveImage = async (id:number) => {

                let a = document.createElement('a');
                a.href = images[id];
                console.log("img", images[id])
                a.download = 'download.png';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

    }
    const renderPreview = async () => {

            for(let i = 0; i < resizes.length; i++) {
                let w = resizes[i].width
                let h = resizes[i].height
                let iframe = document.getElementById("iframe-render-preview-"+(i).toString()) as HTMLIFrameElement
                if(iframe.contentDocument) {
                    let tmpImages = images
                    let resCanvas = await toCanvas(iframe.contentDocument.documentElement as HTMLElement,
                        {
                            width:w,
                            height:h,
                          //  canvasWidth: w,
                           // canvasHeight: h,
                            backgroundColor: "#FFFFFF"
                        }
                    )
                    let resizedCanvas = document.createElement("canvas")
                    resizedCanvas.width = w
                    resizedCanvas.height = h
                    let resizedCtx = resizedCanvas.getContext("2d")
                    resizedCtx?.drawImage(resCanvas, 0, 0, w, h)

                    let img_tag = document.getElementById("render-preview-" + (i).toString()) as HTMLImageElement
                    let img = resizedCanvas.toDataURL("image/png")
                    img_tag.src = img
                    tmpImages.push(img)
                    setImages(tmpImages)
                   // console.log( w, h, img, img_tag)
                }
            }
          //  console.log("img", img_tag)

    }

    const handleAddToRenderQ = () => {
        renderQueue.showStickPopup = !renderQueue.showStickPopup
    }

    useEffect(() => {
        let url = "../api/template/"+templateStore?.template?.orm_id+"/render_html"
        setCurrentUrl(url)

    }, [templateStore?.template?.orm_id]);



    return (
        <>
        <Modal open={templateStore.renderPreviewOpen}
               onClose={handleClose}
               sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <Paper sx={{
                backgroundColor: "#b9b9b9",
                width: "70%",
                height: "70%",
            }}>
            <Grid container >
                <Grid item xs={12} sx={{  height: "40px",
                    color: "#3b3b3b",
                    padding: "10px",
                }} >
                    <Typography sx={{
                        fontSize: "1rem",
                        fontFamily: "Roboto",
                    }}>
                        Preview
                    </Typography>
                    <Divider/>
                </Grid>

                <Grid item xs={12} sx={{  height: "100px",
                color: "#FFFFFF",
                    padding: "10px",

                }} >
                    <Stack direction={"row"} spacing={2} sx={{
                       height: "30px",
                    }}>
                    <Button color={"primary"} size={"small"} variant={"contained"} onClick={renderPreview}>
                        Render preview
                    </Button>

                    <TextField size={"small"} inputProps={{  style: textStyles  }} label={"width"} value={width} onChange={(e)=>setWidth(parseInt(e.target.value))}/>
                    <TextField size={"small"} inputProps={{  style: textStyles  }} label={"height"} value={height} onChange={(e)=>setHeight(parseInt(e.target.value))}/>
                    <Button size={"small"} variant={"contained"} onClick={addPreviewhandle}>
                        Add
                    </Button>
                    <Button size={"small"} variant={"contained"} onClick={()=>{
                        setResizes([])
                    }}>
                        Clear
                    </Button>
                        <Stack direction={"row"} spacing={1} >
                            <DataSetControlls/>
                        </Stack>
                        <Button color={"primary"} size={"small"} variant={"contained"} onClick={handleAddToRenderQ}>
                            Add to RenderQueue
                        </Button>
                    </Stack>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        justifyItems: "center",
                        alignItems: "center",
                        flexGrow: 1,
                        flexWrap: "wrap",
                        minHeight: "50px",
                    }}>
                        {resizes.map((preview, index) => {

                            //const colors = [ "error" , "primary" , "secondary" , "info" , "success" , "warning"]
                            // const randomColor = colors[Math.floor(index * colors.length)];

                            return (
                                <Chip  size="small" variant="filled" onClick={()=>handleRemoveResize(index)}
                                       sx={{
                                           color: "#FFFFFF",
                                           backgroundColor: "#868686",
                                       }}
                                       label={preview.width+"x"+preview.height}  />
                            )
                        })}

                    </Box>

                </Grid>
                <Grid item xs={12} sx={{
                    backgroundColor: "#ffffff",
                    height: "500px",
                    overflowX: "hidden",
                    overflowY: "scroll",

                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        justifyItems: "start",
                        alignItems: "start",
                        flexGrow: 1,
                        flexWrap: "wrap",
                    }}>
                    {resizes.map((resize, index) => {

                        return (
                            <Paper elevation={9} variant={"elevation"} sx={{
                                backgroundColor: "#FFFFFF",
                                margin: "20px",
                                padding: "2px",


                                width: "320px",
                                height: "320px",
                                overflow: "hidden",
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                             <Box>
                                   <IconButton onClick={()=>handleSaveImage(index)}>
                                       <SaveAs />
                                   </IconButton>
                             </Box>
                            <Box key={index} sx={{
                                backgroundColor: "#c9c9c9",
                                margin: "0px",
                                padding: "1px",
                                width: "290px",
                                height: "290px",
                                overflow: "hidden",
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <img style={{
                                    width: "257px",
                                    height: "257px",
                                    margin: "0px",
                                    padding: "0px",
                                    objectFit: "contain",
                                }} id={"render-preview-"+index} alt={""}/>
                                <iframe
                                    id={"iframe-render-preview-"+index}
                                    title={"iframe-editor"+index}
                                    width={resize.width}
                                    height={resize.height}
                                    style={{
                                        position: "absolute",
                                        overflow: "hidden",
                                        display: "none"

                                    }}

                                    src={currentUrl}/>

                            </Box>
                                <Box sx={{
                                    backgroundColor: "#e7e7e7",
                                    margin: "0px",
                                    padding: "0px",
                                    height: "15px",
                                    overflow: "hidden",
                                    position: "relative",

                                }}>
                                    <Typography sx={{
                                        fontSize: "0.8rem",
                                    }}>
                                        {resize.width}x{resize.height}
                                    </Typography>
                                </Box>
                            </Paper>
                        )

                    })}
                    </Box>
                </Grid>
            </Grid>
            </Paper>
        </Modal>
        </>
    )
}
const RenderPreview = observer(RenderPreviewBase);
export  default React.memo(RenderPreview);
