import {
    IBaseItem,
    THorizontalConstraint,
    Transform,
    Translating,
    TUnits,
    IValue,
    TVerticalConstraint, TVector, TRectangle, ITemplate, TFrame,
} from "../contracts/TemplateContracts";
import truth_table from "./truth_table.json";
import {TTruthTable} from "./TuthTableImp";
import {round} from "./MathUtils";
import {keys} from "mobx";
import {TBaseItem} from "../contracts/implementations/TBaseItem";
import {extend} from "dayjs";
import {TText} from "../contracts/implementations/TTextItem";



/**
 * Applies constraints to a given node.
 *
 * @param {T extends TBaseItem| TText| TVector| TRectangle | ITemplate| TFrame} node - The node to apply constraints to.
 * @return {void}
 */
export function ApplyConstraints<T extends TBaseItem| TText| TVector| TRectangle | ITemplate| TFrame  >(node: T):void {
    if(!node){
        return;
    }
    const parent = node?.parent();
    const vertical = node.constraints?.vertical as TVerticalConstraint;
    const horizontal = node.constraints?.horizontal as THorizontalConstraint;
    const parentWidth = parent?.width.value as number;
    const parentHeight = parent?.height.value as number;

    let transform = node.transform as Transform;

    let new_transform =  TTruthTable.GetTransform(horizontal, vertical);
    new_transform.x = transform?.x;
    new_transform.y = transform?.y;


    let width = node?.width.value as number;
    let height = node?.height.value as number;
    let top = transform?.y.value as number;
    let left = transform?.x.value as number;
    let right = parentWidth - (left + width);
    let bottom = parentHeight - (top + height);

    const topRel = top * 100 / parentHeight;
    const leftRel = left * 100 / parentWidth;
    const rightRel = right * 100 / parentWidth;
    const bottomRel = bottom * 100 / parentHeight;

    const centerX = parentWidth / 2;
    const centerY = parentHeight / 2;

    const offsetX = left - centerX;
    const offsetY = top - centerY;

    const adjustedOffsetXRel = offsetX * 100 / (width || 1);
    const adjustedOffsetYRel = offsetY * 100 / (height || 1);


    // Handle vertical constraints
    switch (vertical) {
        case TVerticalConstraint.Bottom: {
                let bottom_new = new_transform?.bottom as IValue;
                bottom_new.value = round(bottom)
                bottom_new.isUsed = true
                bottom_new.unit = TUnits.px

                let height_new = new_transform?.height as IValue;
                height_new.value = round(height)
                height_new.isUsed = true
                height_new.unit = TUnits.px
            }  break;

        case TVerticalConstraint.Top: {
            let top_new =  new_transform?.top as IValue;
            top_new.value  = round(top)
            top_new.isUsed = true
            top_new.unit = TUnits.px

            let height_new =  new_transform?.height as IValue;
            height_new.value  = round(height)
            height_new.isUsed = true
            height_new.unit = TUnits.px

        } break;

        case TVerticalConstraint.TopBottom: {

            let top_new =  new_transform?.top as IValue;
            top_new.value  = round(top)
            top_new.isUsed = true
            top_new.unit = TUnits.px

            let bottom_new =  new_transform?.bottom as IValue;
            bottom_new.value  = round(bottom)
            bottom_new.isUsed = true
            bottom_new.unit = TUnits.px

            let height_new =  new_transform?.height as IValue;
            height_new.value  = round(height)
            height_new.isUsed = true
            height_new.unit = TUnits.px

        } break;

        case TVerticalConstraint.Scale: {

           let top_new =  new_transform?.top as IValue;
            top_new.value  = round(topRel)
            top_new.isUsed = true
            top_new.unit = TUnits.percent

            let bottom_new =  new_transform?.bottom as IValue;
            bottom_new.value  = round(bottomRel)
            bottom_new.isUsed = true
            bottom_new.unit = TUnits.percent

            let height_new =  new_transform?.height as IValue;
            height_new.value  = round(height)
            height_new.isUsed = true
            height_new.unit = TUnits.auto

        } break;

        case TVerticalConstraint.Center: {

            let top_new =  new_transform?.top as IValue;
            top_new.value  = 50
            top_new.isUsed = true
            top_new.unit = TUnits.percent

            let height_new =  new_transform?.height as IValue;
            height_new.value  = round(height)
            height_new.isUsed = true
            height_new.unit = TUnits.px

            let translate = new_transform?.translate as Translating;
            translate.y = {
                value: adjustedOffsetYRel,
                isUsed: true,
                unit: TUnits.percent
            }

        } break;
    }

    // Handle horizontal constraints
    switch (horizontal) {
        case THorizontalConstraint.Right: {

            let right_new =  new_transform?.right as IValue;
            right_new.value  = round(right)
            right_new.isUsed = true
            right_new.unit = TUnits.px

            let width_new =  new_transform?.width as IValue;
            width_new.value  = round(width)
            width_new.isUsed = true
            width_new.unit = TUnits.px


        }break;

        case THorizontalConstraint.Left: {

                let left_new =  new_transform?.left as IValue;
                left_new.value  = round(left)
                left_new.isUsed = true
                left_new.unit = TUnits.px

                let width_new =  new_transform?.width as IValue;
                width_new.value  = round(width)
                width_new.isUsed = true
                width_new.unit = TUnits.px

        } break;

        case THorizontalConstraint.LeftRight: {

            let left_new =  new_transform?.left as IValue;
            left_new.value  = round(left)
            left_new.isUsed = true
            left_new.unit = TUnits.px

            let right_new =  new_transform?.right as IValue;
            right_new.value  = round(right)
            right_new.isUsed = true
            right_new.unit = TUnits.px

            let width_new =  new_transform?.width as IValue;
            width_new.value  = round(width)
            width_new.isUsed = true
            width_new.unit = TUnits.auto

        } break;

        case THorizontalConstraint.Scale: {

            let left_new =  new_transform?.left as IValue;
            left_new.value  = round(leftRel)
            left_new.isUsed = true
            left_new.unit = TUnits.percent

            let right_new =  new_transform?.right as IValue;
            right_new.value  = round(rightRel)
            right_new.isUsed = true
            right_new.unit = TUnits.percent

            let width_new =  new_transform?.width as IValue;
            width_new.value  = round(width)
            width_new.isUsed = true
            width_new.unit = TUnits.auto

        }break;

        case THorizontalConstraint.Center: {

            let left_new =  new_transform?.left as IValue;
            left_new.value  = 50
            left_new.isUsed = true
            left_new.unit = TUnits.percent

            let width_new =  new_transform?.width as IValue;
            width_new.value  = round(width)
            width_new.isUsed = true
            width_new.unit = TUnits.px

            let translate = new_transform?.translate as Translating;
            translate.x = {
                value: adjustedOffsetXRel,
                isUsed: true,
                unit: TUnits.percent
            }

        } break;
    }

    node.transform = new_transform;

}

export function transformToElement(node: IBaseItem, element: HTMLElement) {

    let transform: Transform = node.transform as Transform;

    element.removeAttribute("width");
    element.removeAttribute("height");
    element.removeAttribute("left");
    element.removeAttribute("right");
    element.removeAttribute("top");
    element.removeAttribute("bottom");
    element.removeAttribute("transform");


    if (transform.width.isUsed) {
        if(transform.width.unit === TUnits.auto) {
            element.style.width =  "auto";
        }else {
            element.style.width = `${transform.width.value}${transform.width.unit}`;
        }
    }

    if (transform.height.isUsed) {
        if(transform.height.unit === TUnits.auto) {
            element.style.height =  "auto";
        }else {
            element.style.height = `${transform.height.value}${transform.height.unit}`;
        }
    }

    if (transform.left.isUsed) {
        element.style.left = `${transform.left.value}${transform.left.unit}`;
    }

    if (transform.right.isUsed) {
        element.style.right = `${transform.right.value}${transform.right.unit}`;
    }

    if (transform.top.isUsed) {
        element.style.top = `${transform.top.value}${transform.top.unit}`;
    }

    if (transform.bottom.isUsed) {
        element.style.bottom = `${transform.bottom.value}${transform.bottom.unit}`;
    }

    if (transform.translate) {
        let translateX = transform.translate.x as IValue;
        if (translateX.isUsed) {
            element.style.transform = `translate(${translateX.value}${translateX.unit}) 0`;
        }
        let translateY = transform.translate.y as IValue;
        if (translateY.isUsed) {
            element.style.transform = `translate(0 ${translateY.value}${translateY.unit})`;
        }

        if(translateX.isUsed && translateY.isUsed) {
            element.style.transform = `translate(${translateX.value}${translateX.unit} ${translateY.value}${translateY.unit})`;
        }
    }
    if(element.ownerDocument.defaultView) {



        let subWindow = element.ownerDocument.defaultView;
        let computedStyles = subWindow.getComputedStyle(element);
        if(computedStyles) {


               // let leftCalc = parseFloat(computedStyles.left.replace("px", ""));
               // transform.x.value = leftCalc;
             //   let rightCalc = parseFloat(computedStyles.right.replace("px", ""));
              //  transform.x.value = rightCalc;

               // let leftWidth = parseFloat(computedStyles.width.replace("px", ""));
              //  node.width.value = leftWidth;

              //  let rightHeight = parseFloat(computedStyles.height.replace("px", ""));
              //  node.height.value = rightHeight;




            transform.computed = {
                left: computedStyles.left,
                right : computedStyles.right,
                top:  computedStyles.top,
                bottom:  computedStyles.bottom,
                width: computedStyles.width,
                height:  computedStyles.height,
                rotation: "0"
            }

        }
    }
}

export function getConstraintsUsages(): void {
    truth_table.forEach((row) => {

        console.log(row);
    })
}
