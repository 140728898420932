import {autorun, makeAutoObservable, observable} from "mobx"
import projectStore from './project_store'
import {makePersistable} from "mobx-persist-store";


const name = "Application"
class AD{
    width: number = 0
    height: number = 0
}
class ApplicationStore {
    projects =  projectStore;
    public user_info: any = {}
    public token: string = ""
    public username: string = ""
    public password: string = ""
    public remember_me: boolean = false
    public authorized: boolean = false


    private _pages = ["home", "content", "template_select", "mapping", "render", "publish"]
    public currentPage = this._pages[0];
    public language: string = "en_US"
    public templateLink = ""


    setLanguage(lang: string){
        this.language = lang;
    }


    RemoveDs(){
        if(this.projects.activeProject>=0){
           // this.projects[this.active_project].DataFeed.DataSet = []
          //  this.projects[this.active_project].DataFeed.DataSetColumns = []
          //  this.projects[this.active_project].DataFeed.ColumnVisibilityModel = {}
           // this.projects[this.active_project].DataFeed.Step = 0
        }
    }
    constructor(){
        makeAutoObservable(this)
        makePersistable(this, { name: 'StateStore', properties: ['currentPage','token', 'remember_me', 'username', 'password', 'authorized'], storage: window.localStorage });
        /*
        const storedJson = localStorage.getItem(name)
        if (storedJson) Object.assign(this, JSON.parse(storedJson))
        autorun(() => {
            localStorage.setItem(name, JSON.stringify(this))
        })*/
    }

    setPage(idx: number) {
        this.currentPage = this._pages[idx];
    }

    setLink(value: string) {
        this.templateLink = value;
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new ApplicationStore();
