import {MenuItem, Modal, Stack, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import {useEffect, useState} from "react";
import {IDataSet} from "../../contracts/IDataSet";
import {datasetStore} from "../../stores/DatasetStore";
import {observer} from "mobx-react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {AddLink, ConnectedTv, InsertLink, LinkedIn, LinkOff} from "@mui/icons-material";
import Link from "@mui/material/Link";
import {BiLink} from "react-icons/bi";
import {BsDatabase} from "react-icons/bs";
import MappingIcon from "@mui/icons-material/Commit";
import DataMapping from "react-data-mapping";
import Paper from "@mui/material/Paper";



function DataSetControllsBase () {

  const [ dataSetList, setDataSetList ] = useState<any>([]);
  const [ selectedDataSet, setSelectedDataSet ] = useState<any>("");
  const [ Linked , setLinked ] = useState(false);
  const [ mappingDlgOpen, setMappingDlgOpen ] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            await datasetStore.fetchDatasets();
            setDataSetList(datasetStore.List())
            //console.log(dataSetList)
        }
           fetch().catch(console.error);
    },[])

    const idGenerator = () => {
        return '_' + Math.random().toString(36).substr(2, 9);
    }
    const handleLinkOn = () => {
        setLinked(true)
    }
    const handleLinkOff = () => {
        setLinked(false)
    }
    const handleMappingDlg = () => {
        //console.log("mapping")
        setMappingDlgOpen(true)
    }

  return (
    <Box sx={{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"center",
        height:"30px",
        width:"100%",
        margin:"0px",
        padding:"0px",

    }}>
        <Modal open={mappingDlgOpen} onClose={()=>setMappingDlgOpen(false)}>
            <Paper sx={{
                position: 'absolute',
                transform: 'translate(50%, 50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                width:'50vw',
                height:'50vh',
                overflow:"auto",
                rounded:"lg",
            }}>
                Fields Mapping
            </Paper>

        </Modal>
        {!Linked&&
        <TextField
            select={!Linked}
            variant="outlined"
            disabled={Linked}
            margin={"none"}
            size={"small"}
            label="Linked dataset"

            sx={{
                "& .MuiOutlinedInput-root": {
                    height: "30px",
                    width: "130px",
                    fontSize:"0.8rem",
                },
            }}

            inputProps={{
                style: {

                    height: "30px",
                    width: "130px",
                    fontSize:"0.8rem",
                }
            }}
            SelectProps={{
                style: {

                    height: "30px",
                    width: "130px",
                    fontSize:"0.8rem",
                }
            }}
            InputLabelProps={{
                style: {

                    height: "25px",
                    width: "130px",
                    fontSize:"0.8rem",
                }
            }}
            value={selectedDataSet}
            onChange={(e)=>{
                setSelectedDataSet(e.target.value)
            }}
        >
            <MenuItem key={idGenerator()} value={""}>None</MenuItem>
            {dataSetList.map((ds:IDataSet)=>{
                return <MenuItem key={idGenerator()} value={ds.id}>{ds.name}</MenuItem>
            })}
        </TextField>}
        {Linked&&
        <Stack direction={"row"} spacing={1} sx={{
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            justifyContent:"center",
            height:"30px",
            width:"100%",
            margin:"0px",
            padding:"0px",
        }}>
        <BsDatabase/>
        <Typography sx={{
            fontSize:"0.8rem",
            marginRight:"5px",
        }}>
            <span style={{
            fontWeight:"bold"
        }}>{dataSetList.find((ds:IDataSet)=>ds.id===selectedDataSet)?.name}</span>
        </Typography>
            <IconButton onClick={handleMappingDlg} >
                <MappingIcon />
            </IconButton>
        <IconButton onClick={handleLinkOff} >
            <LinkOff color={"info"}/>
        </IconButton>
        </Stack>}
        {!Linked &&
        <IconButton onClick={handleLinkOn} >
            <InsertLink />
        </IconButton>
        }
    </Box>
  );
}

const DataSetControlls = observer(DataSetControllsBase)

export default DataSetControlls;
