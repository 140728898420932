import {action, makeAutoObservable, runInAction} from "mobx";
import {User, AuthResponse, MeResponse} from "../types/Auth";
import * as authAPI from '../api/auth';
import {makePersistable} from "mobx-persist-store";

class AuthStore {

    user: User | null = null;
    token: string | null = null;
    isLoggedIn: boolean = false;
    isLoading: boolean = false;
    constructor() {

        makeAutoObservable(this);
        //this.isLoggedInCheck().then();
        makePersistable(this, { name: 'AuthStore', properties: ['token', 'isLoggedIn'], storage: window.localStorage })
            .then(() => console.log('AuthStore saved'));
    }
    @action
    set IsLoading(value: boolean) {
        this.isLoading = value;
    }

    get IsLoading() {
        return this.isLoading;
    }



    @action
    async isLoggedInCheck() {
     return  await  runInAction(async () => {
         try {


            if (!authStore.isLoggedIn) {
                return false;
            }

            if (!authStore.token) {
                authStore.isLoggedIn = false;
                return false;
            }
            const token = localStorage.getItem('token');
            if (token) {
                try {

                    authStore.token = token;
                    const user_data: MeResponse = await authAPI.me();
                    runInAction(() => {
                        authStore.user = user_data.user;
                        authStore.isLoggedIn = true;
                    });
                    return true;
                } catch (error) {
                    console.error('Error during login:', error);
                    authStore.isLoggedIn = false;

                }
            }
          authStore.isLoggedIn = false;
          return false;

         } catch (error) {
                console.error('Error during login:', error);
                authStore.isLoggedIn = false;
                return false;
         }
        });
    }

    @action
    async loginUser(username: string, password: string) {
     return  await runInAction(async () => {
            try {
                const response = await authAPI.login(username, password);
                const data: AuthResponse = await response.json();
                authStore.token = data.access_token;
                console.log(this.token);
                localStorage.setItem('token', authStore.token);
                console.log('Login successful');
                const user_data: MeResponse = await authAPI.me();
                console.log(user_data)
                runInAction(() => {
                    authStore.user = user_data.user;
                    authStore.isLoggedIn = true;
                });
                return [true, null];
            } catch (error) {
                console.error('Error during login:', error);
                return [false, error];
            }
        });
    }
    @action
    logoutUser() {
        authStore.user = null;
        authStore.token = null;
        localStorage.removeItem('token');
        localStorage.removeItem('isLoggedIn');
        if( window.location.href.indexOf('/login') !== -1){
            console.log(window.location.href)
          //  window.location.href = '/login';
        }
    }
}

export const authStore = new AuthStore();
