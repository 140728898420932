import {observer} from "mobx-react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Modal,
    Select, SelectChangeEvent,
    Stack
} from "@mui/material";
import {useCallback, useEffect, useRef, useState} from "react";
import {Form, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {Delete, UploadFile} from "@mui/icons-material";
import ReactCrop, {Crop} from "react-image-crop";
import {assetsStore} from "../../stores/AssetsStore";
import TextField from "@mui/material/TextField";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function AssetEditModalBase({ open, setOpen, asset_id }: {open: boolean, setOpen:any, asset_id: string}) {

    const params = useParams();
    const datasetId = params.id;
    const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
    const [crop, setCrop] = useState<Crop>();
    const [removeBGchecked, setRemoveBGchecked] = useState(false);
    const [removeBgInProgress, setRemoveBgInProgress] = useState(false);
    const [removeBgDone, setRemoveBgDone] = useState(false);
    const [removeBgOptionsAM, setRemoveBgOptionsAM] = useState<boolean>(false);
    const [removeBgOptionsFT, setRemoveBgOptionsFT] = useState<number>(240);
    const [removeBgOptionsBT, setRemoveBgOptionsBT] = useState<number>(10);
    const [removeBgOptionsAMES, setRemoveBgOptionsAMES] = useState<number>(10);

    const [image, setImage] = useState<any>(new Image());
    const [imageRmBg, setImageRmBg] = useState<any>(new Image());

    const refCallback = useCallback((canvas: HTMLCanvasElement) => {
        if(!canvas) return;
        const context = canvas.getContext('2d');
        console.log(context);
        setCtx(context);
    }, [])
    const [asset, setAsset] = useState<any>({})
    const [aiModel, setAiModel] = useState<string>("u2net")


    useEffect(() => {
        setAsset(assetsStore.assets.get(asset_id))
        setOpen(open);
        let img = new Image();
        img.src = asset.src;
        setImage(img)
    }, [open]);

    useEffect(() => {
        //console.log(ctx);
        if (ctx) {
            drawGrid(ctx, 400, 400, 10);
        }
    }, [ctx]);

    function drawGrid(ctx: CanvasRenderingContext2D, width: number, height: number, gridSize: number) {
        // Vertical lines
        for (let i = 0; i <= width; i += gridSize) {
            ctx.moveTo(i, 0);
            ctx.lineTo(i, height);
        }

        // Horizontal lines
        for (let j = 0; j <= height; j += gridSize) {
            ctx.moveTo(0, j);
            ctx.lineTo(width, j);
        }

        ctx.strokeStyle = "#ddd"; // Grid color
        ctx.stroke();
    }


    const handleChangeAiModel = (event: SelectChangeEvent) => {
        setAiModel(event.target.value as string);
    };
    const handleRemoveBGChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setRemoveBGchecked(checked);

    }

    const removeBackgroundProcess = async () => {
        console.log("removeBackgroundProcess")
        if(!removeBgInProgress) {
            setRemoveBgInProgress(true)
            //remove background
            let formdata = new FormData();
            let image = new Image();
            image.crossOrigin = '*';
            image.src = asset.src;
            image.onload = function () {
                let canvas = document.createElement('canvas');
                let context2 = canvas.getContext('2d');
                canvas.height = image.naturalHeight;
                canvas.width = image.naturalWidth;
                // @ts-ignore
                context2.drawImage(image, 0, 0);
                canvas.toBlob((blob) => {
                    if(!blob) return;
                    const file = new File([blob], "image.png");

                    formdata.append("file", file, "Your_iamge_URL");
                    formdata.append("options", JSON.stringify({
                        "remove_bg": removeBGchecked,
                        "remove_bg_options": {
                            "ai_model": aiModel,
                            "alpha_matting": removeBgOptionsAM,
                            "foreground_threshold": removeBgOptionsFT,
                            "background_threshold": removeBgOptionsBT,
                            "alpha_matting_erode_size": removeBgOptionsAMES
                        }
                    }) );

                    fetch('http://127.0.0.1:8033/remove-bg', {
                        method: 'POST',

                        body: formdata,

                    }).then(async resp=>{
                        let blob = await resp.blob();
                        let objUrl = URL.createObjectURL(blob)
                        let img = new Image();
                        img.src = objUrl;
                        setImageRmBg(img)
                        setRemoveBgInProgress(false)
                        setRemoveBgDone(true)
                    }).catch((error) => {
                        console.error('Error:', error);
                    })

                })
            }
        }
    }

    const handleClose = () => {
        setOpen(false);
    }


    return (
        <Modal
            open={open}
            onClose={()=>{
                setOpen(false);
            }}
        >
            <Box sx={style}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography id="modal-modal-title" variant="h6" component="h6">
                            Edit:
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 1, color:"#3e90ea" }}>
                            <b>Asset name</b> : {asset.meta?.name}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 1, color:"#3e90ea" }}>
                            <b> Asset id</b> : {asset.meta?.id}
                        </Typography>

                    </Grid>
                    <Grid item xs={7} >
                        <Box sx={{ position:"absolute" }} >
                            <canvas  ref={refCallback} width="400" height="400" style={{border:"1px solid #000000"}}/>
                        </Box>
                        <Box sx={{ position:"absolute",
                            display:"flex",
                            width:"400px",
                            height:"400px",
                            flexDirection:"column",
                            justifyContent:"center",
                            justifyItems: "center"
                        }}>
                            <Box sx={{

                            }}>
                                {removeBGchecked&&removeBgInProgress ?<Typography>Removing background...</Typography>:
                                <ReactCrop onChange={setCrop} crop={crop}>
                                    {removeBGchecked && removeBgDone ?
                                        <img width="400px" style={{
                                            objectFit:"contain",
                                            maxHeight:"400px",
                                        }} src={imageRmBg.src}/>
                                        :
                                        <img width="400px" style={{
                                            objectFit:"contain",
                                            maxHeight:"400px",

                                        }} src={image.src}/>
                                    }
                                </ReactCrop>
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>

                              <FormGroup>

                                       <TextField
                                            value={asset.meta?.name}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant={"outlined"}
                                            size={"small"}
                                            label={"Name"}
                                        />

                                      <FormControlLabel control={
                                      <Checkbox
                                          checked={removeBGchecked}
                                          onChange={handleRemoveBGChange}
                                          disabled={removeBgInProgress}
                                      />

                                  } label="Remove background" />
                                  {removeBGchecked?
                                    <FormGroup>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">AI Model</InputLabel>
                                        <Select size={"small"}
                                                labelId="demo-simple-select-label"
                                                label={"AI Model"}
                                                value={aiModel}
                                                onChange={handleChangeAiModel}
                                        >
                                            <MenuItem value={"u2net"}>u2net</MenuItem>
                                            <MenuItem value={"u2netp"}>u2netp</MenuItem>
                                            <MenuItem value={"u2net_human_seg"}>u2net_human_seg</MenuItem>
                                            <MenuItem value={"u2net_cloth_seg"}>u2net_cloth_seg</MenuItem>
                                            <MenuItem value={"isnet-general-use"}>isnet-general-use</MenuItem>
                                            <MenuItem value={"silueta"}>silueta</MenuItem>
                                        </Select>
                                        </FormControl>
                                        <Button onClick={()=>{ removeBackgroundProcess()}}>Preview</Button>
                                    </FormGroup>
                                      :<></>
                                  }

                                  <Button variant="outlined" startIcon={<UploadFile />}> Replace </Button>
                                  <Button variant="outlined" startIcon={<Delete />}> Delete  </Button>
                              </FormGroup>

                    </Grid>
                    <Grid item xs={12} height={"15vw"}>
                    </Grid>
                    <Grid item xs={12} sx={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>

                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose} autoFocus>
                        Save
                    </Button>

                    </Grid>
              </Grid>

            </Box>



        </Modal>
    )

}

const AssetEditModal = observer(AssetEditModalBase);
export default AssetEditModal;
