import {action, makeAutoObservable, runInAction} from "mobx";
import {IVariant, TCondition} from "../../contracts/TemplateContracts";
import {TemplateAPI} from "../../api/templates";
import {templateStore} from "./TemplateStore";


class VariantStore {

    variantsList: IVariant[] = []
    private _idCounter: number = 0

    private _selectedVariant: IVariant | null = null
    private variantsDlgOpenFlag: boolean = false
    private variantsDlgOpenEditFlag: boolean = false

    constructor() {
        makeAutoObservable(this)
    }
    buildFormula(conditions: TCondition[]): string {
        function processCondition(cond: TCondition): string {
            const result = `(${cond.feature} ${cond.operator} ${cond.value})`;
            return result;
        }

        return conditions.map(processCondition).join(' and ');
    }

    get EditMode(): boolean {
        return this.variantsDlgOpenEditFlag
    }
    set EditMode(value: boolean) {
        this.variantsDlgOpenEditFlag = value
    }
    @action
    openEditDlg() {
        this.variantsDlgOpenEditFlag = true
        this.variantsDlgOpenFlag = true
    }

    get selectedVariantFormula(): string {
        if (!this._selectedVariant || this._selectedVariant.name === 'default') {
            return '';
        }
        return this.buildFormula(this._selectedVariant.conditions);
    }

    get selectedVariant(): IVariant|null {
        return this._selectedVariant
    }

    set selectedVariant(value: IVariant|null) {
        this._selectedVariant = value
    }
    @action
    selectVariantById(name: string) {
        this._selectedVariant = this.variantsList.find((variant) => variant.id === name) || null
        console.log(name, this._selectedVariant)
    }

    @action
    async addVariant(name: string, conditions: TCondition[] = []) {
          this._idCounter++
         let variant: IVariant = {
            id: this._idCounter.toString(),
            template_id: templateStore.template.orm_id,
            name: name,
            conditions: conditions
        }
        variant =  await TemplateAPI.VariantsCreate(variant) as IVariant

        this.variantsList.push(variant)
    }

    get variants(): IVariant[] {
        return this.variantsList
    }

    set variantsDlgOpen(value: boolean) {
        this.variantsDlgOpenFlag = value
    }
    get variantsDlgOpen(): boolean {
        return this.variantsDlgOpenFlag
    }
    @action
    ReadList(orm_id: string) {
        if(orm_id===undefined)
            return
         TemplateAPI.VariantsList(orm_id).then(action("fetchSuccess",result => {
                        this.variantsList = result as IVariant[]
                })
            )
    }
    @action
    deleteCurrent(deleteCallback: () => void) {
        if(this._selectedVariant){
                TemplateAPI.VariantsDelete(templateStore.template.orm_id, this._selectedVariant.id).then(action((result) => {
                if(result){
                    this.variantsList = this.variantsList.filter((variant) => variant.id !== this._selectedVariant?.id)
                    if(deleteCallback)
                        deleteCallback()
                    this._selectedVariant = null
                }
            }))
        }
    }
    @action
    updateVariantConditions(variantName: string, conditionsList: TCondition[]) {
        if(this._selectedVariant){
            if(this._selectedVariant.name === "default"){
                alert("You can't update default variant")
                return
            }
            this._selectedVariant.name = variantName
            this._selectedVariant.conditions = conditionsList
        }

    }

   async updateCurrent() {
        if(this._selectedVariant) {
            let res = await TemplateAPI.VariantsUpdate(this._selectedVariant)
            console.log("update VariantName", res)

        }
    }

}

export default VariantStore;

