import * as React from 'react';
import Box from '@mui/material/Box';
import Navigator from './navigator';
import Header from './header';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import DnsRoundedIcon from "@mui/icons-material/DnsRounded";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import MappingIcon from "@mui/icons-material/Commit";
import PublicIcon from "@mui/icons-material/Public";
import PublishIcon from "@mui/icons-material/Publish";
import {useContext, useEffect} from "react";
import menuStore from "../stores/navigatorStore";



const drawerWidth = 230;

export default function MainPage() {
    const [Loading, setLoading] = React.useState(true);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const isSmUp = false;
    const location = useLocation()
    useEffect(() => {

    }, []);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
               <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                <Navigator  />
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Header onDrawerToggle={handleDrawerToggle} />
                    <Box component="main" sx={{ flex: 1, py: 1, px: 2, bgcolor: '#eaeff1', flexGrow: 1 }}>
                        {location.pathname === '/'? <Navigate to="/projects" /> :
                            <Outlet/>
                        }
                    </Box>

                </Box>
               </Box>

    );
}
