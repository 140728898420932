import {action, makeAutoObservable} from "mobx";
import {useLocation} from "react-router-dom";
import DnsRoundedIcon from "@mui/icons-material/DnsRounded";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import MappingIcon from "@mui/icons-material/Commit";
import PublicIcon from "@mui/icons-material/Public";
import PublishIcon from "@mui/icons-material/Publish";
import * as React from "react";

class MenuItem {
    idx: number = 0;
    name: string = '';
    icon: React.ElementType = DnsRoundedIcon;
    active: boolean = false;
    path: string = '';
}

class LeftMenuStore {
    id: number = 0;
    path: string = '';
    location: string = '/';  // начальное значение
    category: string = '';
    submenus: Array<MenuItem> = [];
    constructor() {
        makeAutoObservable(this);
    }
    get currentMenu() {
        return this.submenus.find((item) => item.active);
    }
    @action
    setMenu(menu:any ) {
        this.id = menu.id;
        this.path = menu.path;
        this.category = menu.categoryName;
        this.submenus = menu.children;
    }
    push(path: string) {
        this.location = path;
        window.history.pushState(null, '', path);
    }

    replace(path: string) {
        this.location = path;
        window.history.replaceState(null, '', path);
    }

    goBack() {
        window.history.back();
    }
}
const menuStore = new LeftMenuStore();
export default menuStore;
