import React, {useEffect, useState} from "react";
import './App.css';
import MainPage from "./components/main";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Login from "./components/login";
import {authStore} from "./stores/AuthStore";
import ProjectsList from "./components/projects/ProjectsList";
import Project from "./components/projects/projects";
import Template from "./components/templates/Template";
import TemplateLists from "./components/templates/TemplateList";
import Layout from "./components/layout";
import NotFoundPage from "./components/NotFoundPage";
import DatasetsList from "./components/datasets/DatasetsList";
import Dataset from "./components/datasets/dataset";
import DatasetAssets from "./components/datasets/DatasetAssets";
import {FontsList} from "./components/fonts/FontsList";
import {FontsPage} from "./components/fonts/Fonts";
import UnderConstruction from "./components/UnderConstruction";
import RenderQueue from "./components/renderq/RenderQueue";



function App() {

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Routes>
                         <Route  element={    <Layout/>    } >
                             <Route path={"/"} element={
                                 <RequireAuth>
                                     <MainPage />
                                 </RequireAuth>
                             }>
                                 <Route path="/projects" element={ <RequireAuth><ProjectsList/></RequireAuth>} />
                                 {/* <Route path="/projects/:id" element={<RequireAuth><Project/></RequireAuth>} />*/}
                                  <Route path="/projects/:id" element={<RequireAuth><UnderConstruction/></RequireAuth>} />
                                 <Route path="/datasets" element={<RequireAuth><DatasetsList/></RequireAuth>} />
                                 <Route path="/dataset/:id" element={<RequireAuth><Dataset/></RequireAuth>} />
                                 <Route path="/dataset/:id/assets" element={<RequireAuth><DatasetAssets/></RequireAuth>} />
                                 <Route path="/assets" element={<RequireAuth><UnderConstruction/></RequireAuth>} />
                                 <Route path="/renders" element={<RequireAuth><RenderQueue/></RequireAuth>} />
                                 <Route path="/templates" element={<RequireAuth><TemplateLists/></RequireAuth>} />
                                 <Route path="/template/:id" element={<RequireAuth><Template/></RequireAuth>} />
                                 <Route path="/template/:id/variants/:variant_id" element={<RequireAuth><Template/></RequireAuth>} />
                                 <Route path="/fonts" element={ <RequireAuth><FontsPage/></RequireAuth>} />
                             </Route>
                         </Route>


                     <Route path="/login" element={<Login />} />
                     <Route path="*" element={<NotFoundPage />} />


          </Routes>
      </LocalizationProvider>
  );
}
function RequireAuth({ children  }: { children: JSX.Element }) {
    let location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            const isLoggedIn = await authStore.isLoggedInCheck();
            setIsAuthenticated(isLoggedIn);

            if (!isLoggedIn && location.pathname !== "/login") {
                setRedirectToLogin(true);
            }
        };

        checkAuth().catch((e) => {
            console.log(e);
        });
    }, [location]); // Добавьте location в массив зависимостей

    if (redirectToLogin) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return isAuthenticated ? children : null;
}
export default App;
