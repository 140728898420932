import {observer} from "mobx-react";
import Grid from "@mui/material/Grid";
import React, {useEffect} from "react";
import TextField from "@mui/material/TextField";
import {TColor, TColorFill} from "../../../contracts/TemplateContracts";
import {templateStore} from "../../../stores/templates/TemplateStore";


function RGBAtoSvg(rgba: any) {
    const r = Math.round(rgba.r * 255);
    const g = Math.round(rgba.g * 255);
    const b = Math.round(rgba.b * 255);
    return  `rgba(${r},${g},${b},${rgba.a})`;
}

function rgbaToHex(rgba: any) {
    const r = Math.round(rgba.r * 255);
    const g = Math.round(rgba.g * 255);
    const b = Math.round(rgba.b * 255);
    const a = Math.round(rgba.a * 255);
    const red = r.toString(16).padStart(2, '0');
    const green = g.toString(16).padStart(2, '0');
    const blue = b.toString(16).padStart(2, '0');
    // const alpha = Math.round(a * 255).toString(16).padStart(2, '0');
    // return `#${red}${green}${blue}${alpha}`;
    return `#${red}${green}${blue}`;
}

function hexToRgb(hex: string) {
    const r = parseInt(hex.substr(1,2), 16) / 255;
    const g = parseInt(hex.substr(3,2), 16) / 255;
    const b = parseInt(hex.substr(5,2), 16) / 255;
    return {r,g,b};
}


function SolidFillBase( {fill, index, id} : {fill:TColorFill, index:number, id:string} )  {

    const pickerRef = React.useRef(null);
    const [color, setColor] = React.useState<TColor>({r:0,g:0,b:0,a:1})
    const [indexFill, setIndexFill] = React.useState<number>(index)

    useEffect(() => {
        setColor(fill.color)
    }, [fill])
    useEffect(() => {
        setIndexFill(index)
    }, [index]);

    const handleOpen = () => {
        // @ts-ignore
        pickerRef.current.click()
    }
    const handleChangeComplete = (e: any) => {

        let rgb = hexToRgb(e.target.value)
        setColor({r:rgb.r,g:rgb.g,b:rgb.b,a:1})
        console.log("color", color, indexFill)
        templateStore.setColorFill(color, indexFill)

    }

    return (
        <Grid container sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "1px",
            padding: "1px",
        }}>
            <Grid item xs={2}     sx={{
                margin: "0px",
                padding: "0px",

            }}>
                <svg width="20" height="20" onClick={ handleOpen} style={{
                    border: "1px solid #CCCCCC",
                }}>
                    <rect width="20" height="20" fill={RGBAtoSvg(color)} />
                </svg>
                { <input ref={pickerRef} style={{ width:0, height:0, margin:0, padding:0 }} type={"color"}  value={rgbaToHex(color) }  onInput={ handleChangeComplete } />}
            </Grid>
            <Grid item xs={10} sx={{
                margin: "0px",
                padding: "0px",
            }}>
                <TextField
                    id="outlined-basic"
                    size={"small"}
                    InputProps={{ sx: {
                            borderRadius: 0 ,
                            width: "150px",
                            height: "20px",
                            padding: "0px",
                            margin: "0px",
                            fontSize: "0.8rem",
                        }}}
                    variant="outlined"
                    value={rgbaToHex(color)}
                />
            </Grid>
        </Grid>
    )
}


const SolidFill = observer(SolidFillBase)
export default SolidFill
