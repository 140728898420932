import {observer} from "mobx-react";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import React from "react";
import List from "@mui/material/List";
import {templateStore} from "../../stores/templates/TemplateStore";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {AiFillPropertySafety} from "react-icons/ai";
import {SiChainlink} from "react-icons/si";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";


interface DynamicParametersProps {

}

const DynamicParametersBase = (props: DynamicParametersProps) => {


    return (
        <>
            <Typography sx={{
                fontSize: "0.8rem",
            }}>Dynamic fields</Typography>
        <Grid container
              sx={{
                  border: "solid 1px #CCCCCC",
                  borderRadius: "4px",
                  padding: "5px",
                  minHeight: "100px",
                  maxWidth: "190px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  flexGrow: 1,
                  backgroundColor: "#FFFFFF",
              }}
        >


            <Grid item xs={12}>
                {!templateStore.template?.dynamic_values?.length&&
                <Typography sx={{
                    fontSize: "0.8rem",
                }}>Empty</Typography>
                }
                {templateStore.template?.dynamic_values?.length&&
                <List dense={true} component="nav" >
                    {
                        templateStore.template?.dynamic_values?.map((item, idx)=>{
                            return  (

                                <ListItem key={item.id} sx={{
                                    border: "solid 1px #CCCCCC",
                                    borderRadius: "4px",
                                    maxHeight: "40px",
                                    "&:hover": {
                                        backgroundColor: "#f5f5f5",
                                        cursor: "pointer"
                                    }
                                }}  secondaryAction={
                                    <IconButton edge="end" aria-label="comments">
                                        <Delete  fontSize={"small"}/>
                                    </IconButton>
                                }>
                                    <ListItemIcon>
                                        <SiChainlink fontSize={"small"}/>
                                    </ListItemIcon>

                                    <ListItemText sx={{
                                        "& .MuiTypography-root": {
                                            fontSize: "0.7rem",
                                        }
                                    }} primary={item.name} secondary={item.type} />

                                </ListItem>
                            )
                        })
                    }

                </List>
                }
            </Grid>

        </Grid>
        </>
    );
}

const DynamicParameters = observer(DynamicParametersBase)
export default DynamicParameters
