import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {Add, UploadFile} from "@mui/icons-material";
import {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import * as Mobx from "mobx-react";
import {
    Card,
    CardActionArea, CardActions,
    CardContent, CardMedia,
    Modal,
    Stack,
    styled
} from "@mui/material";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {GridDeleteIcon} from "@mui/x-data-grid";
import {ProjectContract} from "../../stores/project_store";
import api  from "../../api/api";
import projectStore from "../../stores/project_store";
import {observer} from "mobx-react";
import {ProjectList, ProjectCreate} from "../../api/projects";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import DnsRoundedIcon from "@mui/icons-material/DnsRounded";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import MappingIcon from "@mui/icons-material/Commit";
import PublicIcon from "@mui/icons-material/Public";
import PublishIcon from "@mui/icons-material/Publish";
import menuStore from "../../stores/navigatorStore";
import EmptyProjectImg from "../../images/file.png"



const AddLinkModalstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const projectCategories =
    {
        categoryName: 'projects',
        path: "/projects/",
        children: [
            { idx:1 , name: 'Datasets', icon: <DnsRoundedIcon/> , active: true, path: "dataset" },
            { idx:2 , name: 'Templates', icon: <SelectAllIcon/> , active: false, path: "template" },
            { idx:2 , name: 'Assets', icon: <SelectAllIcon/> , active: false, path: "assets" },
        ],
    };



function ProjectsList() {

    const [AddProjectModal, setAddProjectModal] = useState(false)
    const [NewProjectName, setNewProjectName] = useState("")
    let location = useLocation();
    const navigate = useNavigate();

    useEffect( ()=>{
        const callOnce  = async ()=> {
            await projectStore.initProjectList()
        }
        callOnce().catch(console.error);
        menuStore.setMenu(projectCategories);

    },[]);


    const handleOpen = () => setAddProjectModal(true);
    const handleClose = () => setAddProjectModal(false);


    return (
        <Paper sx={{ margin: '0 0 0 0', overflow: 'hidden' }}>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
            >
                <Button variant="outlined" startIcon={<Add />} onClick={()=>handleOpen()}>
                    New project
                </Button>
                <Modal
                    open={AddProjectModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={AddLinkModalstyle}>

                        <Stack spacing={3} direction="column">
                            <TextField  label="Project name:" value={ NewProjectName} variant="filled"  onChange={
                                (e)=>setNewProjectName(e.target.value)
                            } />
                            <Button  variant="contained" component="label" onClick={(el)=>{

                                projectStore.createProject(NewProjectName, "test");
                                handleClose();
                            }
                            }>Create</Button>
                        </Stack>
                    </Box>
                </Modal>
            </AppBar>

            <Box sx={{ flexGrow: 1, p: 2 , width: '100%'  }} >
                <Grid

                    alignItems="start"
                    justifyContent="start"
                    container
                    spacing={2}
                    sx={{
                        '--Grid-borderWidth': '1px',
                        borderTop: 'var(--Grid-borderWidth) solid',
                        borderLeft: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        '& > div': {
                            borderRight: 'var(--Grid-borderWidth) solid',
                            borderBottom: 'var(--Grid-borderWidth) solid',
                            borderColor: 'divider',
                        },
                        alignItems:"center",

                        // padding:"2px"
                    }}
                >
                    {projectStore.List().map((v, n)=>{
                        return <Grid item key={"project_"+v.id}  sx={{  padding:"12px"}} >
                            <Card sx={{ maxWidth: 345, minWidth: 140 }} >
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        width="64px"
                                        image= {EmptyProjectImg}
                                        alt="image"
                                        onClick={()=> navigate("/projects/"+v.id) }
                                    />
                                </CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="subtitle2" component="div" >
                                        {v.name}
                                    </Typography>
                                </CardContent>
                                <CardActions
                                    disableSpacing
                                    sx={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "flex-start",
                                        // 👇 Edit padding to further adjust position
                                        p: 0,
                                    }}
                                >

                                    <IconButton  sx={{alignItems:"end"}} onClick={()=>{
                                        projectStore.deleteProject(v.id);
                                    }
                                    }>
                                        <GridDeleteIcon fontSize="small"  />
                                    </IconButton>
                                </CardActions>
                            </Card>
                        </Grid>
                    })}
                </Grid>
            </Box>

        </Paper>
    );
}

const ProjectListComponent = observer(ProjectsList);
export default React.memo(ProjectListComponent);
