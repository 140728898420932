import React from 'react';
import {observer} from "mobx-react";
import {canvasStore} from "../../../stores/templates/CanvasStore";
import {templateStore} from "../../../stores/templates/TemplateStore";
import IconButton from "@mui/material/IconButton";
import {Delete, Edit} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {MenuItem, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import uniqKeyGenerator from "../../../utils/kegen";
import Dropbox from "../../widgets/Dropbox";
import {useNavigate, useParams} from "react-router-dom";
import {runInAction} from "mobx";
import {PiKeyReturn} from "react-icons/pi";

function VariantsControlsBase( ){

    const navigate = useNavigate()
    const { id,variant_id } = useParams();

    const handleVariantDelele = () => {
        templateStore.variants.deleteCurrent(()=>{
            navigate(`/template/${id}`)
        })
    }

    return (
       <Box sx={{
              display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              height:"30px",
              width:"100%",
              backgroundColor:"#f0f0f0",
              margin:"5px",
       }}>
           {!templateStore.isVariantViewMode?
           <Dropbox defaultValue={variant_id?variant_id:"default"} onChange={async (e)=> {
               //console.log(e.target.value)
               if(e.target.value==="default"){
                     templateStore.variants.selectVariantById("default")
                     navigate(`/template/${id}`)
                       runInAction(()=> {
                           templateStore.isVariantViewMode = false
                       })
                     await templateStore.readTemplate(id as string)
                }else {

                   templateStore.variants.selectVariantById(e.target.value)
                   navigate(`/template/${id}/variants/${e.target.value}`)
                   runInAction(()=> {
                       templateStore.isVariantViewMode = true
                   })
                   await templateStore.readTemplate(id as string, e.target.value)
               }

           }}>
               <MenuItem key={uniqKeyGenerator()} value={"default"}>Default</MenuItem>
               {templateStore.variants.variants.map((variant)=>{
                   return <MenuItem key={uniqKeyGenerator()} value={variant.id}>{variant.name}</MenuItem>
               })}
           </Dropbox>:
               <IconButton title={"Switch to template" } onClick={()=>{
                   templateStore.variants.selectVariantById("default")
                   navigate(`/template/${id}`)
                   runInAction(()=> {
                       templateStore.isVariantViewMode = false
                       templateStore.readTemplate(id as string)
                   })
               }}>
                   <PiKeyReturn/>
               </IconButton>
           }
           <Divider orientation={"vertical"} flexItem={true} sx={{height:"20px", marginRight:"5px", marginLeft:"5px"}}/>
           <input  type={"number"} style={{

               width:"50px",
               textAlign:"center",
               border: "none"
           }} value={canvasStore.width||0}
                   onChange={(e)=>{
                       let w = parseInt(e.target.value)
                       canvasStore.set_width(w)
                       canvasStore.setManualResize(false)
                   }}
                   onFocus={(e)=>{
                       canvasStore.setManualResize(true)
                   }}
           />
           <div style={{width:"10px"}}>
               x
           </div>
           <input
               type={"number"}
               style={{
                   width:"50px",
                   textAlign:"center",
                   border: "none",
               }}
               value={canvasStore.height||0}
               onChange={(e)=>{
                   let w = parseInt(e.target.value)
                   canvasStore.set_height(w)
                   canvasStore.setManualResize(false)
               }}
               onFocus={(e)=>{
                   canvasStore.setManualResize(true)
               }}
           />
           {  templateStore.isVariantViewMode&&<>
           <Divider orientation={"vertical"} flexItem={true} sx={{height:"20px", marginRight:"5px", marginLeft:"5px"}}/>

            <Box sx={{
                backgroundColor:"#ffffff",
                border:"solid 1px #CCCCCC",
                padding:"2px",
            }}>
                <Typography fontSize={"0.8rem"}>
                {templateStore.variants.selectedVariantFormula}
                </Typography>
            </Box>
           <Divider orientation={"vertical"} flexItem={true} sx={{height:"20px", marginRight:"5px", marginLeft:"5px"}}/>
           <IconButton size={"small"}  onClick={()=>templateStore.variants.openEditDlg()}>
               <Edit/>
           </IconButton>
           <IconButton size={"small"} onClick={()=>handleVariantDelele()}>
               <Delete/>
           </IconButton>
           </> }
       </Box>
    );
}

const VariantsControls = observer(VariantsControlsBase);
export default  React.memo(VariantsControls);
