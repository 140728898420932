import React, {useRef, useState} from "react";

interface IKeyFrame {
    value: number;
    time: number;
}


const TimeLineItem = (props: {name: string, frames:IKeyFrame[]}) =>{

    const { name,frames} = props;
    const [usingInFrames, setUsingInFrames] = useState<number[]>([0,2])
    const ref = useRef<HTMLDivElement>(null);

    const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
        event.dataTransfer.clearData();
        // @ts-ignore
        event.dataTransfer.setData("text/plain", event.target.id);
    }
    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }
    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const element  = ref.current;
        if (element) {
            const target = event.currentTarget;
            target.appendChild(element);
        }
    }

    const onMouseDownHandle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        console.log(event)
    }

    return(
        <div style={{
            display: "flex",
            flexDirection: "row",
        }}>
            {frames.map((frame: IKeyFrame, index: number) => {
                if(usingInFrames.findIndex((idx)=>idx===index)!==-1) {
                    return (
                        <div className="timeline-item"
                             style={{  width: 100/frames.length+"%"  }}
                             onDrop={onDrop}  onDragOver={onDragOver}
                             id={name+"_frame_"+index}
                        >
                            <div ref={ref} style={{
                                display: "flex",
                                flexDirection: "row",
                            }} >
                                <div key={index}
                                     draggable={true}
                                     className="timeline-item-content"
                                     onDragStart={onDragStart}>
                                </div>
                                <div draggable={false}
                                     className="timeline-item-drag-area"
                                     onMouseDown={onMouseDownHandle}
                                >:</div>
                            </div>
                        </div>
                    );
                }else {
                    return (<>
                        <div className="timeline-item" style={{  width: 100/frames.length+"%"  }} onDrop={onDrop}  onDragOver={onDragOver} >
                        </div>
                    </>)
                }
            })}

        </div>)
}

export default TimeLineItem;
