import DialogCustom from "../DialogCustom";
import {DatasetColumn, datasetStore} from "../../stores/DatasetStore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import TextField from "@mui/material/TextField";
import {runInAction} from "mobx";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import * as React from "react";
import {observer} from "mobx-react";


function FieldsEditor(params: any) {

    const {EditStructureModal, setEditStructureModal, id} = params;


    //handlers
    const handleStructureEditClose = () => {
        setEditStructureModal(false);
    }

    const handelAddColumn = () => {
        if(!id) return;
        try {
            datasetStore.addColumn(id,  "field name",  "string" )
        }catch (e) {
            console.log(e)
        }
    }

    return (
        <DialogCustom
            open={EditStructureModal}
            titleEl={"Dataset fields"}
            actions = {{
                accept: ( )=>{

                    let _ = id?datasetStore.save(id):""
                    setEditStructureModal(false);
                },
                close: handleStructureEditClose
            }}>

            <Box>
                <Button onClick={ handelAddColumn }
                > Add column</Button>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Function</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                datasetStore.datasets.get(id?id:"")?.columns?.map((row:DatasetColumn, index:number) => (
                                    <TableRow key={row.name+'_'+index}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>

                                            <TextField
                                                variant={"outlined"}
                                                size={"small"}
                                                value={row.name}
                                                defaultValue={row.name}
                                                onChange={(event)=>{
                                                    runInAction(()=>{
                                                        row.name = event.target.value as string;
                                                    })
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                variant={"outlined"}
                                                size={"small"}
                                                value={row.type}
                                                defaultValue={row.type}
                                                label="Type"
                                                onChange={(event)=>{
                                                    runInAction(()=> {
                                                        row.type = event.target.value as string;
                                                    })
                                                }}
                                            >
                                                <MenuItem value={"string"}> Text</MenuItem>
                                                <MenuItem value={"image"}> Image</MenuItem>
                                                <MenuItem value={"number"}> Number</MenuItem>
                                            </Select>

                                        </TableCell>
                                        <TableCell> function</TableCell>
                                        <TableCell>
                                            <IconButton onClick={()=>datasetStore.deleteColumn(id, row.id) }>
                                                <Delete/>
                                            </IconButton>
                                        </TableCell>

                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>

        </DialogCustom>
    )
}
const FieldsEditorComponent = observer(FieldsEditor);
export default React.memo(FieldsEditorComponent);
