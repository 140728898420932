
import api from "./api";
import {AuthResponse, MeResponse} from "../types/Auth";
import {authStore} from "../stores/AuthStore";
import {runInAction} from "mobx";
import {KyResponse, ResponsePromise} from "ky";
export async function login (username: string, password:string): Promise<KyResponse>
{

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    try {

        let response: KyResponse  = await api.post("api/auth/token", {
            body: formData
        });
        return response;

    } catch (e) {
        runInAction(() => {
        authStore.isLoggedIn = false;
        })
        console.log(e);
        throw e;


    }
}

export async function me (): Promise<MeResponse>
{
    try {
        let response: Promise<MeResponse> = api.get("api/auth/me").json();
        return response;

    } catch (e) {

        runInAction(() => {
            authStore.isLoggedIn = false;
        })
        console.log(e);
        throw e;
    }
}
