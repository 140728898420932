import {Modal, Stack, styled} from "@mui/material";
import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {GridRenderEditCellParams, GridRowEditStopReasons, useGridApiContext} from "@mui/x-data-grid";
import ReactCrop, { type Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import EmptyImage from "../../images/file.png"
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {assetsStore} from "../../stores/AssetsStore";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function ImageDlg ( props: GridRenderEditCellParams ){
    const apiRef = useGridApiContext();
    const [crop, setCrop] = useState<Crop>()
    const  [asset, setAsset] = useState<any>(props.value)
    const  [uploadedFile, setuploadedFile] = useState<any>(null)
    const [openDlg, setOpenDlg] = useState(true);

    //handlers
    const handlerClose = () => {

        apiRef.current.stopCellEditMode({
            id: props.row.id,
            field: props.field,
            ignoreModifications: true,
        });
        setOpenDlg(false);
    }

    const handlerSave = () => {

        //apiRef.current.updateRows([{ id: props.row.id, [props.field]: asset?.url}]);
        asset.meta.crop = crop;
        apiRef.current.setEditCellValue({ id: props.id, field: props.field, value: asset });

        apiRef.current.stopCellEditMode({
            id: props.row.id,
            field: props.field,
            ignoreModifications: false,
        });

         setOpenDlg(false);

    }

    return (
        <Dialog

            open={openDlg}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">

            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handlerClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent
                sx={{
                    width: 400,
                    height: 300,
                }}
                dividers>
                <TextField
                    style={{width: "100%"}}
                    value={ asset?.meta?.name}
                    InputProps={{
                        readOnly: true,
                    }}
                />

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                <ReactCrop crop={crop} onChange={c => setCrop(c)}>
                    <img width={"200px"} src={asset.src} alt={""} />
                </ReactCrop>
                        </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack sx={{margin:"5px"}}>
                <Button component="label"  startIcon={<CloudUploadIcon />}>
                    Upload file
                    <VisuallyHiddenInput type="file" onChange={async (e)=>{

                        console.log(e.target.files)
                        if (!e.target.files || !e.target.files.length) {
                            return;
                        }
                        //setuploadedFile(e.target.files[0])
                        const asset_id = await assetsStore.upload(e.target.files[0])
                        const asset_1 = await assetsStore.assets.get(asset_id)
                        setAsset(asset_1)

                    }} />
                </Button>
                <Button >From assets</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus  onClick={handlerSave}>
                    Save changes
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default ImageDlg;
