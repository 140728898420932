import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {GridColDef} from "@mui/x-data-grid";
import app_store from "./ui_state";




class RenderQueue{

    private _showStickPopup: boolean = false

    Items: any[] = []
    constructor(){
        makeAutoObservable(this)
    }

    get showStickPopup(){
        return this._showStickPopup
    }

    set showStickPopup(value: boolean){
        this._showStickPopup = value
    }
}

export const renderQueue = new RenderQueue()
