import * as React from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import CollectionsIcon from '@mui/icons-material/Collections';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DnsRoundedIcon from "@mui/icons-material/DnsRounded";
import SelectAllIcon from "@mui/icons-material/SelectAll";


import Typography from "@mui/material/Typography";
import {
    Breadcrumbs,
    DrawerProps,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar
} from "@mui/material";
import store from "../stores/ui_state"
import * as Mobx from  'mobx-react';
import app_store from "../stores/ui_state";
import {Link, useLocation, useNavigate} from "react-router-dom";
import menuStore from "../stores/navigatorStore";
import HomeIcon from "@mui/icons-material/Home";
import {useContext, useState} from "react";
import InboxIcon from "@mui/icons-material/Inbox";
import logoImage from "../images/logo2.png";
import theme from "tailwindcss/defaultTheme";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from "@mui/icons-material/Menu";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Tooltip from "@mui/material/Tooltip";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {authStore} from "../stores/AuthStore";
import UserMenu from "../components/UserMenu";
import {FaFonticons} from "react-icons/fa";
import {Collections, FormatSize} from "@mui/icons-material";
import ImageIcon from "@mui/icons-material/Image";
import {SiOctanerender} from "react-icons/si";


const MainMenu = [
        {
            name: 'Projects',
            path: "/projects",
            icon: <HomeIcon/>
        },
        {
            name: 'Datasets',
            path: "/datasets",
            icon: <DnsRoundedIcon/>,
        },
        {
            name: 'Templates',
            path: "/templates",
            icon: <SelectAllIcon/>,
        },
        {
            name: 'Renders',
            path: "/renders",
            icon: <SiOctanerender/>,
        },
        {
            name: 'Assets',
            path: "/assets",
            icon: <CollectionsIcon/>,
        },
    {
        name: 'Fonts',
        path: "/fonts",
        icon: <FormatSize/>,
    }
];

const drawerWidth = 180;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(6)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

interface HeaderProps {
    onDrawerToggle: () => void;
}


export default Mobx.observer(function Navigator(props: DrawerProps) {
    const [isDrawerCollapsed, setIsDrawerCollapsed] = useState(false);
    const { ...other } = props;
    const navigate = useNavigate()
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
              <>
                  <CssBaseline />
                  <AppBar position="fixed" open={open} sx={{
                      backgroundColor: "rgb(8,22,39)",
                  }}>
                      <Toolbar>
                          <IconButton
                              color="inherit"
                              aria-label="open drawer"
                              onClick={handleDrawerOpen}
                              edge="start"
                              sx={{
                                  marginRight: 5,
                                  ...(open && { display: 'none' }),
                              }}
                          >
                              <MenuIcon />
                          </IconButton>
                          <Grid container spacing={1} alignItems="center">

                              <Grid item xs />

                              <Grid item>
                                  <Tooltip title="Alerts • No alerts">
                                      <IconButton color="inherit">
                                          <NotificationsIcon />
                                      </IconButton>
                                  </Tooltip>
                              </Grid>
                              <Grid item>
                                  <Typography color="inherit" variant="subtitle2" component="h1">
                                      {authStore.user?.email}
                                  </Typography>
                              </Grid>
                              <Grid item>

                                  <UserMenu/>

                              </Grid>
                          </Grid>
                      </Toolbar>
                  </AppBar>
                <Drawer variant="permanent" open={open} >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose} sx={{  color: "rgba(50,193,225,0.85)"}}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>

                <Divider/>

                <List   >
                    {MainMenu.map(( cat:any, index: number) => (
                        <ListItem key={cat.name+'_'+index} disablePadding
                           sx={{

                            color: "rgba(50,193,225,0.85)",
                            }}>
                            <ListItemButton
                                onClick={()=>{
                                    navigate(cat.path);
                                }}
                                sx={{
                                ":hover": {
                                    color: "rgba(50,193,225,1)",
                                    bgcolor: "rgba(50,193,225,0.1)",
                                },
                            }}>
                                <ListItemIcon>
                                    {cat.icon}
                                </ListItemIcon>
                                <ListItemText  primary={cat.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>




            </Drawer>
                  </>
    );
})
