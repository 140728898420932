import Box from "@mui/material/Box";
import {Construction} from "@mui/icons-material";
import Paper from "@mui/material/Paper";


function UnderConstruction() {
  return (
    <Box sx={{
        backgroundColor: "#f3f3f3",
        padding: "10px",
        minHeight: "90vh",
        width:"100%",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
    }}>
      <Paper sx={{
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center",
            height:"40vh",
            width:"30vw",
      }}>
          <Construction  sx={{fontSize:"8rem" }}/>
          <h1>Under Construction</h1>
      </Paper>
    </Box>
  );
}


export default UnderConstruction;
