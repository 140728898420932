import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import {Add, Delete, Edit} from "@mui/icons-material";
import {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import * as Mobx from "mobx-react";
import { Modal, Stack} from "@mui/material";
import {
    DataGrid,
    GridColDef,
} from "@mui/x-data-grid";
import {datasetStore} from "../../stores/DatasetStore";
import DialogCustom from "../DialogCustom";
import {useNavigate} from "react-router-dom";


const AddLinkModalstyle = {
    position: 'absolute' as 'absolute',
    top: '20%',
    left: '60%',
    transform: 'translate(-60%, -20%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'id',
        width: 350,
        editable: false,
    },
    {
        field: 'name',
        headerName: 'Dataset name',
        width: 150,
        editable: true,
    },
    {
        field: 'discription',
        headerName: 'Discription',
        width: 150,
        editable: true,
    },
    {
        field: 'dataSource',
        headerName: 'Data source',
        type: 'number',
        width: 110,
        editable: true,
    },
    {
        field: "delete",
        width: 75,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ( params ) => {
            return (
                <IconButton
                    onClick={async () => {
                        console.log("delete", params.row.id)
                        await datasetStore.delete(params.row.id)
                    }}
                >
                    <Delete/>
                </IconButton>
            );
        }
    },
    {
        field: "edit",
        width: 75,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ( params ) => {

            return (
                <IconButton >
                    <Edit/>
                </IconButton>
            );
        }
    }
];


 function DataFeed() {

    const [TemplateModalShow, setTemplateModalShow] = useState(false)
    const [TemplateName, setTemplateName] = useState("")
    const handleOpen = () => setTemplateModalShow(true);
    const handleClose = () => setTemplateModalShow(false);

    const navigate = useNavigate();

    useEffect(()=>{
        const callOnce  = async ()=> {
            await datasetStore.fetchDatasets();
        }
        callOnce().catch(console.error);
    },[])

    return (
        <Paper sx={{ margin: '0 0 0 0', overflow: 'hidden' }}>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
            >
                <Button variant="outlined" startIcon={<Add />} onClick={()=> handleOpen()}>
                    New dataset
                </Button>
                <DialogCustom
                    open={TemplateModalShow}
                    titleEl={"Create new dataset"}
                    actions = {{
                       accept: ( )=>{
                            datasetStore.create(TemplateName, "test");
                            setTemplateModalShow(false);
                            setTemplateName("");
                        },
                        close: handleClose
                    }}>

                    <TextField  label="Dataset name:" value={ TemplateName} variant="standard"  onChange={
                        (el)=>{
                            setTemplateName(el.target.value)
                        }
                    } />

                </DialogCustom>
            </AppBar>

            <Box sx={{ flexGrow: 1, p: 2 , width: '100%'  }} >
                <DataGrid
                    rows={datasetStore.List()}
                    columns={columns}

                    onCellClick={(params, event) => {

                            console.log("edit", params.row.id)
                            navigate("/dataset/"+params.row.id)

                    }}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                />

            </Box>

        </Paper>
    );
}
export default Mobx.observer(DataFeed)
