import React from "react";
import Box from "@mui/material/Box";


const NotFoundPage = () => {

    return <Box style={{minHeight: '100vh'}}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#081627',
                    color: '#fff'
                }}>
        <h1></h1>
        <h1>404</h1>
        <h2>Page Not Found 😞</h2>
    </Box>
}
export default NotFoundPage;
