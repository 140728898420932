
import api from "./api";

export async function DatasetList (): Promise<any[]> {
    try {
        return api.get("api/dataset/").json();

    } catch (e) {
        console.log(e);
        throw e;
    }
}

export async function DatasetRead (id: string){
    try {
        return api.get("api/dataset/"+id).json();

    } catch (e) {
        console.log(e);
        throw e;
    }
}

export async function DatasetCreate (name: string, description: string){

    try {
        return api.post("api/dataset/", {json:
                {
                    name: name,
                    columns: {},
                    data: {},
                }
        }  ).json();
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export async function DatasetUpdate (dataset:any){
    try {
        return api.put("api/dataset/"+dataset.id, {json:
                {
                    name: dataset.name,
                    columns: dataset.columns,
                    data: dataset.data,
                }
        }  ).json();

    } catch (e) {
        console.log(e);
        throw e;
    }
}

export async function DatasetDelete (id: string){
    try {
        return api.delete("api/dataset/"+id).json();

    } catch (e) {
        console.log(e);
        throw e;
    }
}

export async function AddRecord (id:string){
    try {
        return api.post("api/dataset/"+id+"/add").json();

    } catch (e) {
        console.log(e);
        throw e;
    }
}

export async function DeleteRecord (id:string, recordId: string){
    try {
        return api.delete("api/dataset/"+id+"/"+recordId).json();

    } catch (e) {
        console.log(e);
        throw e;
    }
}

export async function UpdateDatasetRecord (dataset_id: string, rowId: string, value: any){
    try {
        return api.put("api/dataset/"+dataset_id+"/"+rowId, {json:
                {
                    data: value,
                }
        }  ).json();

    } catch (e) {
        console.log(e);
        throw e;
    }
}

