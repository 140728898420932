import {observer} from "mobx-react";
import * as React from "react";
import Box from "@mui/material/Box";
import {useCallback, useEffect, useRef} from "react";
import {useLocation, useNavigate, useNavigation, useParams} from "react-router-dom";
import {templateStore} from "../../stores/templates/TemplateStore";
import {canvasStore} from "../../stores/templates/CanvasStore";
import {autorun, runInAction} from "mobx";
import {IBaseItem} from "../../contracts/TemplateContracts";
import {Refresh, Scale, Search, ZoomIn, ZoomInSharp, ZoomOut, ZoomOutSharp} from "@mui/icons-material";
import {Zoom} from "@mui/material";
import Typography from "@mui/material/Typography";
import {TemplateAPI} from "../../api/templates";


function GenerateRulers() {
    const canvasWidth = 2000;
    const canvasHeight = 2000;
    const majorTickSpacing = 100;
    const minorTickSpacing = 10;
    const offsetX = -100;
    const offsetY = -100;

    let svgContent = `
        <rect x="${offsetX}" width="${canvasWidth}" height="20" fill="#E4E4E4"/>
        <rect x="${offsetX}" width="20" height="${canvasHeight}" fill="#E4E4E4"/>
    `;

    for (let x = offsetX; x <= canvasWidth + offsetX; x += minorTickSpacing) {
        const isMajorTick = x % majorTickSpacing === 0;
        const tickLength = isMajorTick ? 20 : 10;
        const yOffset = 20 - tickLength;
        svgContent += `<line x1="${x - offsetX }" y1="${yOffset}" x2="${x - offsetX }" y2="20" stroke="black"/>`;

        if (isMajorTick) {
            svgContent += `<text x="${x -offsetX + 3}" y="15" font-size="30" font-family="Arial" fill="black">${x}</text>`;
        }
    }

    for (let y = offsetY; y <= canvasHeight; y += minorTickSpacing) {
        const isMajorTick = y % majorTickSpacing === 0;
        const tickLength = isMajorTick ? 20 : 10;
        const xOffset = offsetX - tickLength;
        svgContent += `<line x1="${xOffset}" y1="${y-offsetY}" x2=${xOffset+20} y2="${y-offsetY}" stroke="black"/>`;

        if (isMajorTick) {
            svgContent += `<text x="${offsetX+5}" y="${y - offsetY+ 13}" font-size="30" font-family="Arial" fill="black">${y}</text>`;
        }
    }

    svgContent += ``;
    return <g dangerouslySetInnerHTML={{__html: svgContent}}></g>
}

function DrawGrid() {


    return <></>
}




const CanvasPlaceholder =  `<html>
                    <body style="overflow: hidden">
                        <div style="
                        font-size: 10rem; 
                        color: #CCCCCC;
                        width: 100vw; 
                        height: 100vh; 
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        justify-items: center"> 
                            <h3>Canvas</h3>
                        </div>
                    </body>
                </html>`

function TemplateCanvasBase() {


    const boxRef = useRef<any>(null);
    const [isResizing, setIsResizing] = React.useState(false);
    const [isResizingMode, setIsResizingMode] = React.useState(false);
    const [currentUrl, setCurrentUrl] = React.useState("")
    const [frameSelected, setFrameSelected] = React.useState(false);
    const [framePosition, setFramePosition] = React.useState({x:0,y:0});
    const [mousePosition, setMousePosition] = React.useState({x:0,y:0});
    const [mouseDelta, setMouseDelta] = React.useState({x:0,y:0});
    const [canvasBackground, setCanvasBackground] = React.useState("#FFFFFF");
    const [useSrcDoc, setUseSrcDoc] = React.useState(true);
    const [iframeReady, setIframeReady] = React.useState(false);
    const [srcdoc, setSrcdoc] = React.useState(CanvasPlaceholder)
    const ifRef =  useCallback( (node:HTMLIFrameElement) => {
        if (node !== null) {
            canvasStore.setIframe(node)
            setIframeReady(true)

        }
    }, []);
    const {id,variant_id} = useParams();
    const location = useLocation();

    useEffect(() => {
        const handleMouseWheel = (e:any) => {
            if (e.ctrlKey) {
                console.log("Ctrl + wheel detected");
                console.log("handleMouseWheel",e)
                if (e.deltaY > 0) {
                    canvasStore.setScale(canvasStore.scale - 0.03)
                } else {
                    canvasStore.setScale(canvasStore.scale + 0.03)
                }

                e.preventDefault();
            }
        };

           document.addEventListener('wheel', handleMouseWheel, { passive: false });

        return () => {
            document.removeEventListener('wheel', handleMouseWheel);
        };
    }, []);

    /*useEffect(() => {
        if(templateStore.template?.orm_id) {
            setUseSrcDoc(false)
            setCurrentUrl(`/api/template/${templateStore.template?.orm_id}/render_html`)

        }
    }, [templateStore.template?.orm_id]);*/
    useEffect(() => {
       // setUseSrcDoc(false)
        //const templateData = JSON.stringify(templateStore.template);
        if(templateStore.template.name) {

            const url = window.location.href.replace(location.pathname,"/api/template/")
            //setCurrentUrl(url)
            console.log("TemplateCanvasBase",url)
            canvasStore.RenderCanvas(url)

        }
    //setCurrentUrl(`/api/template/${templateStore.template?.orm_id}/render_html?template=${templateData}`)

    }, [templateStore.template.name, iframeReady])

    autorun(() => {
        console.log("autorun Rendering", canvasStore.isRendering)
    })

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        console.log("handleMouseDown",e)
        if(e.currentTarget.className === boxRef.current?.className){
            setFrameSelected(true)
            console.log("setFrameSelected",boxRef)
        }

        setMousePosition({x:e.clientX,y:e.clientY})
        //let fr =  ifRef.current?.contentDocument.getElementById("iframe-editor") as HTMLIFrameElement
        //let main = fr.contentWindow?.document.getElementById("Frame-LeftTop-7-18") as HTMLDivElement
        if(isResizing){
            setIsResizingMode(true)
        }

    }
    const handleMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        //console.log("handleMouseUp",e)
        setFrameSelected(false)
        setIsResizingMode(false)
        setIsResizing(false)
    }
    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
       // console.log("handleMouseMove",e)
        if(frameSelected && canvasStore.isMainFrameSelected && !isResizingMode && !isResizing) {
            setMousePosition({x:e.clientX,y:e.clientY})
            //console.log("handleMouseMove", e.clientX, e.clientY)
            let deltaX = e.clientX - mousePosition.x
            let deltaY = e.clientY - mousePosition.y
            //setMouseDelta({x:deltaX,y:deltaY})
            setFramePosition({x:framePosition.x + deltaX,y:framePosition.y + deltaY})
            //setCanvasBackground("#CCCCCC")
            //setCanvasBackground("#FFFFFF")
        }
        if(isResizingMode && canvasStore.isMainFrameSelected) {

            let clientRect = canvasStore.iframe?.getBoundingClientRect() as DOMRect
            let deltaX = (e.clientX - mousePosition.x) * canvasStore.scale
            let deltaY = (e.clientY - mousePosition.y)  * canvasStore.scale
            console.log("handle Resize:", deltaX, deltaY)
            if(deltaX > 0) {
                canvasStore.set_width(canvasStore.width+deltaX)
            } else if(deltaX < 0) {
                canvasStore.set_width(canvasStore.width+deltaX)
            }
            if(deltaY > 0) {
                canvasStore.set_height(canvasStore.height+deltaY)
            } else {
                canvasStore.set_height(canvasStore.height+deltaY)
            }


           setFramePosition({x:framePosition.x - deltaX,y:framePosition.y - deltaY})

        }
    }

    const handleCanvasRefresh = () => {
        console.log("handleCanvasRefresh")

        let iframe = canvasStore.iframe
        if (iframe && canvasStore?.iframe ) {
            setUseSrcDoc(true)
            iframe.src = canvasStore.iframe.src
        }

    }

    const handleMouseWheel = (e: any ) => {

        if(e.ctrlKey) {
            console.log("handleMouseWheel",e)
            if (e.deltaY > 0) {
                canvasStore.setScale(canvasStore.scale - 0.1)
            } else {
                canvasStore.setScale(canvasStore.scale + 0.1)
            }
        }
        e.preventDefault();
    }


    const FrameBoundingBox = (props: {  }) => {

        const [BoundSize, setBoundSize] = React.useState(20);
        const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            //console.log("handleMouseEnter",e)
            setIsResizing(true)
        }

        const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            //console.log("handleMouseLeave",e)
            setIsResizing(false)
        }
        return (
            <Box
                ref={boxRef}
                onMouseDown={handleMouseDown}
                sx={{
                position: "absolute",
                left:  canvasStore.iframe?.style.left,
                top:  canvasStore.iframe?.style.top,
                overflow: "auto",
                width: canvasStore.width,
                height: canvasStore.height,
                border: "solid 2px #FF00FF",
                zIndex: 12,
                transform: `scale(${canvasStore.scale}) translate(-${50}%, -${50}%)`,
                resize: "both",

            }}>

            </Box>
        )
    }



    // @ts-ignore
    return (
        <Box sx={{
                    backgroundColor:"#FFFFFF",
                    display:"flex",
                    flexDirection:"row",
                    justifyContent:"center",
                    alignItems:"center",
                 }}
        >


                <Box
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    sx={{
                    width:"100%",
                    backgroundColor: {canvasBackground},
                    overflow:"hidden",
                    height:"65vh",
                    display:"block",
                    position:"relative",
                }}>
                    <Box sx={{
                        position:"absolute",
                        right:"00px",
                        top:"0px",
                        width:"100px",
                        border:"solid 1px #CCCCCC",
                        display:"flex",
                        flexDirection:"row",
                        justifyContent:"start",
                        alignItems:"center",
                        backgroundColor:"rgba(250,250,250,0.56)",
                        zIndex: 10,
                        padding:"1px",
                        margin:"0px",
                    }}>
                      <Refresh sx={{
                            color:"#CCCCCC",
                            cursor:"pointer",
                            "&:hover":{
                                color:"#bebebe",
                            }
                      }} onClick={(e)=>{handleCanvasRefresh()} }/>
                      <ZoomInSharp sx={{
                          color:"#CCCCCC",
                          cursor:"pointer",
                          "&:hover":{
                              color:"#bebebe",
                          }
                      }}/>
                        <Typography sx={{
                            fontSize: "0.9rem",
                            color:"#CCCCCC",
                        }}>
                            { (canvasStore.scale >=0? canvasStore.scale*100: canvasStore.scale*-100).toFixed(0).toString() }%
                        </Typography>
                        <ZoomOutSharp sx={{
                            color:"#CCCCCC",
                            cursor:"pointer",
                            "&:hover":{
                                color:"#bebebe",
                            }
                        }}/>
                    </Box>
                             {canvasStore.isMainFrameSelected && <FrameBoundingBox/>}
                    {/* <Box
                                onMouseDown={handleMouseDown}
                                onMouseUp={handleMouseUp}
                                onMouseMove={handleMouseMove}

                                style={{
                                position: "absolute",
                                left: framePosition.x,
                                top:  framePosition.y,
                                width: canvasStore.width,
                                height: canvasStore.height,
                                overflow: "hidden",
                                border: "solid 0px #FF0000",
                                transform: `scale(${canvasStore.scale}) translate(-${50}%, -${50}%)`,
                                zIndex: 1,

                            }}>


                            </Box>*/}
                            <iframe
                                 ref={ifRef}
                                    onError={(e) => {
                                        setUseSrcDoc(true)
                                        console.log("onError",e)
                                    }}
                                    onLoad={(e) => {
                                       // setUseSrcDoc(false)
                                        //console.log("onLoad",e)
                                    }}
                                    width={canvasStore.width}
                                    height={canvasStore.height}
                                    title={"iframe-editor"}
                                    id={"iframe-editor"}
                                    style={{
                                        left: framePosition.x,
                                        top:  framePosition.y,
                                        position: "absolute",
                                        overflow: "hidden",
                                        width: canvasStore.width,
                                        height: canvasStore.height,
                                        transform: `scale(${canvasStore.scale}) translate(-${50}%, -${50}%) translate3d(0, 0, 0)`,
                                    }}
                                   // src={ currentUrl}
                                    srcDoc={useSrcDoc?srcdoc:undefined}
                            />
                </Box>

        </Box>
    )
}

const TemplateCanvas = observer(TemplateCanvasBase);
export { TemplateCanvas };
