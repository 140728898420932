import {useCallback, useEffect, useState} from "react";
import {Form, useParams} from "react-router-dom";
import menuStore from "../../stores/navigatorStore";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import {Card, CardActionArea, CardActions, CardContent, CardMedia, styled, Toolbar} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Add, Cancel, Delete, Edit, Image, Save, UploadFile} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import * as React from "react";
import {datasetStore, Dataset, DatasetColumn} from "../../stores/DatasetStore";
import IconButton from "@mui/material/IconButton";
import FieldsEditor from "./FieldsEditor";

import {assetsStore} from "../../stores/AssetsStore";
import './assets.css'
import AssetEditModal from "./AssetEditModal";


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const projectCategories = (ds_id:string|undefined) => {
    return {
        categoryName: '< Back',
        path: "/dataset/"+ds_id,
        children: [  ],
    };
}

const AssetView = (props:any) => {
    const v = props.value;
    const [visible, setVisible] = useState(false);
    const [AssetEditOpen, setAssetEditOpen] = useState(false)
    const handleDelete = async () => {
        await assetsStore.delete(v.meta.id)
        //alert(v.meta.id)
    }

    const handleEdit = async () => {
        setAssetEditOpen(true)
    }

    return (
        <Box style={{height:"200px", width:"200px", margin:"5px"}}

             onMouseEnter={(e)=>{
                 setVisible(true);
             }}
             onMouseLeave={(e)=>{
                 setVisible(false);
             }} >
            <AssetEditModal setOpen={setAssetEditOpen} open={AssetEditOpen} asset_id={v.meta.id} />
            <Box
                className={"asset"}
                style={{
                    opacity: visible?1:0,
                    width:"200px",
                    height:"40px",
                    position:"absolute",
                    backgroundColor:"rgba(176,175,175,0.36)"}}>
                <IconButton style={{position:"absolute", right:"0px", top:"0px"}}
                onClick={handleDelete}
                >
                    <Delete/>
                </IconButton>
                <IconButton style={{position:"absolute", right:"30px", top:"0px"}}
                onClick={handleEdit}
                >
                    <Edit/>
                </IconButton>
            </Box>
            <img style={{objectFit:"contain",height:"200px", width:"200px" }} src={v.src} alt={v.meta.name} />

        </Box>
    )

}


function DatasetAssets() {

    const params = useParams();
    const datasetId = params.id;
    const [EditStructureModal, setEditStructureModal] = useState(false)
    const [RowsModal, setRowsModal] = useState(false)
    const [RowEdit, setRowEdit] = useState<any>({})
    const [assets, setAssets] = useState<any>([]);
    const  [uploadedFile, setuploadedFile] = useState<any>(null)


    const handleStructureEditOpen = () => setEditStructureModal(true);

    const handleAddRecord = async () => {
        if(!datasetId) return;
        try {


        }catch (e) {
            console.log(e)
        }
    }

    //effects
    useEffect(() => {

        const callOnce  = async ()=> {

            await assetsStore.fetchAssets(datasetId);
            await datasetStore.fetchDatasets();


            //setAssets(tmp);
            if(datasetId) {
                await datasetStore.read(datasetId)
            }
        }
        callOnce().catch(console.error);
        const cats = projectCategories(datasetId)
        menuStore.setMenu(cats);

    }, []);

    return (
        <Paper sx={{ margin: '0 0 0 0', overflow: 'hidden' }}>

            <AppBar position="static" color="default" elevation={0} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Button component={"label"} variant="outlined" startIcon={<UploadFile />}>

                                Upload
                                <VisuallyHiddenInput type="file" value={uploadedFile} onChange={async (e)=>{

                                    console.log(e.target.files)
                                    if (!e.target.files || !e.target.files.length) {
                                        return;
                                    }
                                    //setuploadedFile(e.target.files[0])
                                    const asset_id = await assetsStore.upload(e.target.files[0])
                                    await assetsStore.fetchAssets(datasetId);

                                }} />
                            </Button>
                        </Grid>
                        <Grid item xs />

                        </Grid>
                </Toolbar>
            </AppBar>
            <Box sx={{ flexGrow: 1, display:"flex", p: 2 ,
                width: '100%',
                flexDirection:"row",
                flexWrap:"wrap"}} >
                {Array.from(assetsStore.assets.entries()).map(([key, value]) =>{

                    return <AssetView value={value} />

                })}
            </Box>
        </Paper>
    );
}

const DatasetAssetsComponent = observer(DatasetAssets);
export default React.memo(DatasetAssetsComponent);
