import {DataGrid, GridActionsCellItem, GridColDef, GridRowId, GridRowModel, useGridApiContext} from "@mui/x-data-grid";
import * as React from "react";
import {useEffect, useState} from "react";
import {DatasetColumn, datasetStore} from "../../stores/DatasetStore";
import ImageDlg from "./ImageDlg";
import {useParams} from "react-router-dom";
import {Delete} from "@mui/icons-material";
import {observer} from "mobx-react";


function AssetRenderer(params:any) {
    const asset = params.value;
    return (
        <img src={asset.src} width={64} alt={""}/>
    );

}

function DsDataGridBase({ready:boolean} : {ready:boolean}) {

    const params = useParams();
    const datasetId = params.id;

    const [rows, setRows] = useState<any[]>([])
    const [columns, setColumns] = useState<GridColDef[]>([])


    //functions
    const handleDeleteClick = (id: GridRowId) => async () => {

        if(!datasetId) return;
        await datasetStore.deleteDatasetRecord(datasetId,  id.toString())
        fetchRows();

    };

    const actions = (params:any)=>{

        const id = params.id;

        return [
            <GridActionsCellItem
                icon={<Delete/>}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
            />,
        ];


    }

    const fetchColumns = () => {
        let tmp : GridColDef[] = [];
        datasetStore.datasets.get(datasetId?datasetId:"")?.columns?.forEach((col_row:DatasetColumn, index:number) => {
            if (col_row.type === "image") {
                tmp.push({
                    field: col_row.name,
                    headerName: col_row.name,
                    type: col_row.type,
                    width: 100,
                    editable: true,
                    renderCell: (params) => {
                        return (
                            <AssetRenderer value={params.value}  />
                        );
                    },
                    renderEditCell: (params) => {

                        return (
                            <ImageDlg  {...params} />
                        );
                    }
                })
            } else {

                tmp.push({
                    field: col_row.name,
                    headerName: col_row.name,
                    type: col_row.type,
                    width: 150,
                    editable: true

                })
            }
        })
        tmp.push({
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return  actions({id})
            },
        })
        setColumns(tmp)
        console.log(tmp)
    }
    const fetchRows = () => {
        setRows([]);
        let tmp: any[] = [];
        const dataset = datasetStore.datasets.get(datasetId?datasetId:"")
        const records = dataset?.records;
        const columns = dataset?.columns;
        if(!records) return;
        records.forEach((row:any, index:number) => {
            let tmpRow: any = {}
            columns?.forEach((column:DatasetColumn, index:number) => {
                tmpRow[column.name] = ""
            })
            tmpRow["id"] = row.id;
            tmpRow = {...tmpRow, ...row.data}
            tmp.push(tmpRow)
        })
        setRows(tmp)
    }

    //handlers
    const processRowUpdate = async (newRow: GridRowModel) => {
        console.log(newRow)
        if(datasetId){
            const rowId = newRow.id.toString();
            await datasetStore.updateDatasetRecord(datasetId, rowId,  newRow)
            fetchRows()
        }
        return newRow;
    };

    //effects
   useEffect(() => {

           fetchColumns();
           fetchRows();


       console.log("useEffect")
    }, []);

    return (
        <DataGrid

            columns={columns}
            rows={rows}
            editMode={"cell"}
            processRowUpdate={processRowUpdate}
        />
    )

}




const DsDataGrid = observer(DsDataGridBase);
export default DsDataGrid;
