import Box from "@mui/material/Box";
import {fontsStore} from "../../stores/FontsStore";
import {observer} from "mobx-react";
import Paper from "@mui/material/Paper";
import {ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {ArrowDropDownIcon} from "@mui/x-date-pickers";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import {IFont} from "../../contracts/interfaces/IFonts";

interface FontCardProps {
    font:IFont,
    onDeleted?:any
}

const ButtonsStyle = {

    height:"25px",
    width:"25px",
    margin:"0px",
    padding:"0px",


}

function FontCard({font}: { font:IFont }) {

    const [formats, setFormats] = useState<string[]>(() => []);

    const handleFormat = (
        event: React.MouseEvent<HTMLElement>,
        newFormats: string[],
    ) => {
        setFormats(newFormats);
    };

    const handleDelete = async () => {

        await fontsStore.DeleteFont(font)
    }



    useEffect(() => {

        if(!font.loaded) {
            const loadFont = async (font: IFont) => {
                font.loaded = true
                await fontsStore.LoadFont(font)
            }
            loadFont(font).catch((e) => console.log(e))
        }

    }, []);



    return (
        <Paper sx={{
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center",
            height:"120px",
            width:"150px",
            margin:"10px",
            padding:"10px",
            border:"solid 1px #CCCCCC", borderRadius:"4px",
            backgroundColor:"#FFFFFF",
        //    flexGrow:1,

        }}><Box sx={{
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center",
            height:"120px",
            width:"100%",
            margin:"0px",
            padding:"0px",
        }}>
            <Typography textAlign={"center"} fontSize={"1.3rem"} fontFamily={font.familyName} fontWeight={formats} fontStyle={formats}>
            {font.familyName}
            </Typography>
            <Typography textAlign={"center"} fontSize={"0.8rem"} fontFamily={font.familyName} fontWeight={formats} fontStyle={formats}>
                {font.subfamilyName}
            </Typography>
          </Box>
            <Box sx={{
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                justifyContent:"center",
                height:"30px",
                width:"100%",
                margin:"0px",
                padding:"0px",
            }}>
            <ToggleButtonGroup
                size="small"
                value={formats}
                onChange={handleFormat}
                aria-label="text formatting"
            >
                <ToggleButton sx={ButtonsStyle} value="bold" aria-label="bold">
                    <FormatBoldIcon sx={{ fontSize:"0.8rem"}} />
                </ToggleButton>
                <ToggleButton sx={ButtonsStyle} value="italic" aria-label="italic">
                    <FormatItalicIcon sx={{ fontSize:"0.8rem"}}/>
                </ToggleButton>
            </ToggleButtonGroup>
                <IconButton sx={ButtonsStyle} onClick={()=>handleDelete()}>
                    <Delete sx={{ fontSize:"1rem"}}/>
                </IconButton>
            </Box>
        </Paper>
    )
}


function FontsListBase() {

  return (
    <Box sx={{
        display:"flex",
        flexDirection:"row",
        alignItems:"start",
        justifyContent:"start",
        width:"100%",
        flexGrow:1,
        flexWrap:"wrap",
        margin:"10px",
    }}>
      {fontsStore.FontList.map((font:any, idx:number) => {
        return (<FontCard key={idx} font={font}/>)
      })}
    </Box>
  );

}

export const FontsList = observer(FontsListBase);
