import {IValue, TUnits} from "../TemplateContracts";
import {action, makeAutoObservable, makeObservable, observable} from "mobx";


export class TValue implements IValue {
    @observable
    isUsed: boolean;
    @observable
    unit: TUnits;
    @observable
    value: number | string | boolean;

    constructor() {
        this.isUsed = false;
        this.unit = TUnits.px;
        this.value = 0;
        makeObservable(this)
    }
    @action
    setValue<T extends number | string | boolean>(value: T){
        this.value = value
    }
}


