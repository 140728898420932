/**
 * Represents the vertical constraint options for positioning elements.
 */
export enum TVerticalConstraint {
    Top = 'TOP',
    Center = 'CENTER',
    Bottom = 'BOTTOM',
    TopBottom = 'TOP_BOTTOM',
    Scale = 'SCALE'
}

/**
 * Enum representing horizontal constraints.
 * @enum {string}
 */
export enum THorizontalConstraint {
    Left = 'LEFT',
    Center = 'CENTER',
    Right = 'RIGHT',
    LeftRight = 'LEFT_RIGHT',
    Scale = 'SCALE'
}

/**
 * The `ISerializable` interface defines a contract for classes that can be serialized.
 */
interface ISerializable {
    serialize: <T> (obj:any) => void;
}

/**
 * Represents a color in the RGB model with optional alpha
 * transparency channel.
 *
 * @typedef {Object} TColor
 * @property {number} r - The red component of the color. Must be a number
 *     between 0 and 255.
 * @property {number} g - The green component of the color. Must be a number
 *     between 0 and 255.
 * @property {number} b - The blue component of the color. Must be a number
 *     between 0 and 255.
 * @property {number} a - The alpha (transparency) channel of the color.
 *     Must be a number between 0 and 1, where 0 is fully transparent and
 *     1 is fully opaque. Optional, defaults to 1.
 */
interface TColor {
    r: number;
    g: number;
    b: number;
    a: number;
}

/**
 * Represents a color fill.
 *
 * @interface TColorFill
 */
interface TColorFill {
    blendMode: string;
    type: string;
    color: TColor;
}

/**
 * Represents the position of a gradient handle.
 *
 * @interface
 * @property {number} x - The x-coordinate of the handle position.
 * @property {number} y - The y-coordinate of the handle position.
 */
interface TGradientHandlePosition {
    x: number;
    y: number;
}

/**
 * Represents a gradient stop in a gradient.
 *
 * @interface
 * @property {TColor} color - The color of the gradient stop.
 * @property {number} position - The position of the gradient stop within the gradient.
 */
interface TGradientStop {
    color: TColor;
    position: number;
}

/**
 * Represents a gradient object
 *
 * @interface TGradient
 */
interface TGradient {
    blendMode: string;
    type: string;
    gradientHandlePositions: TGradientHandlePosition[];
    gradientStops: TGradientStop[];
}

/**
 * Represents an image in the application.
 * @interface
 */
interface TImage {
    blendMode: string;
    type: string;
    scaleMode: string;
    imageRef: string;
}

/**
 * Represents the style of a text element.
 *
 * @interface
 */
interface ITextStyle {
    fontFamily: string;
    fontPostScriptName: string;
    fontWeight: number;
    fontSize: number;
    textAlignHorizontal: string;
    textAlignVertical: string;
    textAutoResize: string|null;
    letterSpacing: number;
    lineHeightPx: number;
    lineHeightPercent: number;
    lineHeightPercentFontSize: number;
    lineHeightUnit: string;
    isScalable: boolean;
}

/**
 * Represents a constraint for positioning an element vertically and horizontally.
 *
 * @interface
 */
interface TConstraint {
    vertical: TVerticalConstraint;
    horizontal: THorizontalConstraint;
}


/**
 * Enumeration representing various units of measurement.
 * @enum {string}
 */
export enum TUnits {
    px = "px",
    percent = "%",
    auto = "auto"
}

/**
 * Represents a value that varies over time.
 *
 * @interface
 */
interface TVarying {
    value: number | string | boolean;
    unit: TUnits;
    time: number;
    frame: number;
}


/**
 * Represents a value with its associated unit and usage status.
 * @interface IValue
 */
interface IValue {
    value: number | string | boolean;
    unit: TUnits;
    isUsed: boolean;
    varying?: TVarying[];
}

/**
 * Represents a translating class which provides methods to perform translation operations.
 *
 * @interface Translating
 */
interface Translating {
    x?: IValue;
    y?: IValue;
}


//client side contract
/**
 * Represents the computed transformation of an element.
 *
 * @interface
 * @typedef {object} ComputedTransform
 * @property {string} left - The value of the CSS `left` property.
 * @property {string} right - The value of the CSS `right` property.
 * @property {string} top - The value of the CSS `top` property.
 * @property {string} bottom - The value of the CSS `bottom` property.
 * @property {string} width - The value of the CSS `width` property.
 * @property {string} height - The value of the CSS `height` property.
 * @property {string} rotation - The value of the CSS `rotation` property.
 */
interface ComputedTransform {

    left: string;
    right: string;
    top: string;
    bottom: string;
    width: string;
    height: string;
    rotation: string;
}

/**
 * Represents a transformation of an element.
 * @interface Transform
 */
interface Transform {
    x: IValue;
    y: IValue;
    left: IValue;
    right: IValue;
    top: IValue;
    bottom: IValue;
    width: IValue;
    height: IValue;
    rotation?: IValue;
    translate?: Translating;
    computed?:ComputedTransform
}


/**
 * Represents the possible dynamic value types.
 *
 * @enum {string}
 */
export enum TDynamicValueTypes{
    Text = "text",
    Image = "image",
    Color = "color",
    Gradient = "gradient",
    Bool = "bool",
    Number = "number",
}

/**
 * Represents a dynamic value.
 * @interface TDynamicValue
 */
interface TDynamicValue {
    type: TDynamicValueTypes;
    id: string;
    name: string;
    path: string;
    parent_id: string;
}

/**
 * Represents an item in a base class.
 *
 * @interface IBaseItem
 */
interface IBaseItem {
    name?: string;
    type?: string;
    id?: string;
    path?: string;
    convert_type?: string;
    width: IValue;
    height: IValue;
    constraints?: TConstraint;
    transform?: Transform;
    fills?: (TGradient | TImage | TColorFill)[];
    visible?: IValue | boolean;
    is_dynamic?: boolean | null;
}

/**
 * Represents a text item.
 *
 * @interface
 * @extends IBaseItem
 */
interface IText extends IBaseItem {
    text: string;
    scalable?: boolean;
    text_style: ITextStyle;
}

/**
 * Represents a rectangle.
 * @interface
 * @extends IBaseItem
 */
interface TRectangle extends IBaseItem {
    is_rectangle: boolean;
}

/**
 * Represents a vector item.
 * @interface TVector
 * @extends IBaseItem
 */
interface TVector extends IBaseItem {
    is_mask: boolean;
    mask_paths?: string;
    mask_winding_rule?: string;
    svg?: string;
}


/**
 * The TFrame class represents a frame in a user interface.
 * It extends the IBaseItem interface.
 *
 * @interface
 * @extends IBaseItem
 */
interface TFrame extends IBaseItem {
    clips_content?: boolean;
    mask_id?: string;
    has_mask?: boolean;
    children: (IText | TRectangle | TVector | TFrame)[];
}

/**
 * Interface representing a template.
 * @extends IBaseItem
 */
interface ITemplate extends IBaseItem {
    is_root?: boolean;
    figma_url: string;
    mask_id?: string;
    orm_id: string;
    description?: string;
    has_mask?: boolean;
    clips_content?: boolean;
    images?: TImage[];
    fonts?: { [key: string]: { [key: string]: string } };
    aspect_ratio?: number;
    children: (IText | TRectangle | TVector | TFrame)[];
    dynamic_values?: TDynamicValue[];
}

/**
 * Represents the interface for a truth table.
 *
 * @interface ITruthTable
 */
interface ITruthTable {
    VerticalConstraint: TVerticalConstraint,
    HorizontalConstraint: THorizontalConstraint,
    TopUsed: boolean,
    BottomUsed: boolean,
    LeftUsed: boolean,
    RightUsed: boolean,
    WidthUsed: boolean,
    HeightUsed: boolean,
    TranslateXUsed: boolean,
    TranslateYUsed: boolean,
    TopUnit: string,
    BottomUnit: string,
    LeftUnit: string,
    RightUnit: string,
    WidthUnit: string,
    HeightUnit: string,
    TranslateXUnit: string,
    TranslateYUnit: string
}


export enum TConditionKey {
    WIDTH = "width",
    HEIGHT = "height",
    MIN_WIDTH = "min-width",
    MAX_WIDTH = "max-width",
    MIN_HEIGHT = "min-height",
    MAX_HEIGHT = "max-height",
    ASPECT_RATIO = "aspect-ratio",
    MIN_ASPECT_RATIO = "min-aspect-ratio",
    MAX_ASPECT_RATIO = "max-aspect-ratio",
    ORIENTATION = "orientation",
    RESOLUTION = "resolution",
    MIN_RESOLUTION = "min-resolution",
    MAX_RESOLUTION = "max-resolution",
}

export enum TConditionOperator {
    GREATER_THAN = ">",
    LESS_THAN = "<",
    GREATER_THAN_OR_EQUAL = ">=",
    LESS_THAN_OR_EQUAL = "<=",
    EQUAL = ":",
    AND = "and",
    OR = "or",
    NOT = "not",
}

interface TCondition {
    feature: TConditionKey;
    value: string;
    operator?: TConditionOperator;
}

interface IVariant {
    id: string;
    template_id: string;
    name: string;
    template?: ITemplate
    conditions: TCondition[];
    properties?: Map<string, string>;
}



export type {
    IVariant,
    TCondition,
    ITemplate,
    TFrame,
    IText,
    TRectangle,
    TVector,
    IBaseItem,
    TColor,
    TColorFill,
    TGradient,
    TGradientHandlePosition,
    TGradientStop,
    TImage,
    ITextStyle,
    TConstraint,
    Transform,
    IValue,
    TVarying,
    Translating ,
    ComputedTransform,
    ITruthTable,
    TDynamicValue,
    ISerializable};

