import React, { useState, useEffect } from 'react';
import {Autocomplete, Checkbox, TextField, Typography} from "@mui/material";
import { Box } from '@mui/system';
import Paper from "@mui/material/Paper";
// @ts-ignore
import {create as FontkitCreate} from "fontkit";

//TODO: fix this
// @ts-ignore
import WebFont from "webfontloader";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {Add} from "@mui/icons-material";
import FormControlGroup from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import {fontsStore} from "../../stores/FontsStore";

interface GoogleFont {
    family: string;
    category: string;
    variants: string[];
    subsets: string[];
    version: string;
    lastModified: string;
    files: Map<string, string>;
}

interface ImportFontFile{
    fontFamily:string,
    fileUrl:string,
    variant:string
}

interface UploadFontsProps {
    onClose?:any
}
const GoogleFontsSelector: React.FC<UploadFontsProps> = (props: UploadFontsProps) => {
    const [fonts, setFonts] = useState<GoogleFont[]>([]);
    const [inputValue, setInputValue] = useState<string>();
    const [currentFont, setCurrentFont] = useState<GoogleFont>();
    const [selectedFonts, setSelectedFonts] = useState<ImportFontFile[]>([]);

    useEffect(() => {
        fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDxbYK4ckhQAxck9tExSHEnZdCYiSlOGAw')
            .then(response => response.json())
            .then(data => setFonts(data.items));
    }, []);

    const GetFont = (fontName:string) => {
        let res =  fonts.find(font => font.family === fontName)
        if(res){
            WebFont.load({
                google: {
                    families: [fontName]
                }
            });
            setSelectedFonts([])
            return res
        }
    }

    const handleAddToLib =  () => {
        if(selectedFonts.length > 0){
          //  console.log(selectedFonts)
            selectedFonts.forEach(async font => {
                await downloadFont(font)
            })
        }
    }

    const downloadFont = async (font:ImportFontFile) => {
        const httpsUrl = font.fileUrl.replace("http://", "https://")
        const response = await fetch(httpsUrl)
        const buffer =   new Uint8Array(await response.arrayBuffer())

        const filename = font.fileUrl.split("/").pop()
        const file = new File([buffer], filename as string, {type: "font/otf"})
        const face = FontkitCreate(buffer);
        //console.log(face, file);
        await fontsStore.AddFont(face, file)
        props.onClose()
    }

    const handleSelectVariant = (variant:string, checked: boolean) => {
        if(currentFont) {
            let files:Map<string,string> = currentFont.files
            console.log(files)
            // @ts-ignore
            const file_url = files[variant]
            if (file_url) {
                if(checked) {
                    setSelectedFonts([...selectedFonts, {
                        fontFamily: currentFont?.family as string,
                        fileUrl: file_url,
                        variant: variant
                    }])
                }else{
                    setSelectedFonts([...selectedFonts.filter(font => font.variant !== variant)])
                }
            }
        }
    }

    return (
        <Paper sx={{
            display:"flex",
            flexDirection:"column",
            alignItems:"start",
            justifyContent:"start",
            height:"50vh",
            width:"40vw",
            padding:"10px",
            translate:"80% 30%",
        }}>

        <Box sx={{ width: "100%" }}>
            <Typography textAlign={"center"} variant={"h6"} >Google Fonts import</Typography>
            <Divider sx={{
                width:"100%",
                marginBottom:"10px",
            }}/>
            <Autocomplete
                size={"small"}
                placeholder={"Search fonts"}
                options={fonts.map((option) =>  option.family )}
                inputValue={inputValue}

                onInputChange={(event, newInputValue) => {

                    setInputValue(newInputValue);
                    setCurrentFont(GetFont(newInputValue))
                }}
                renderInput={(params) => (
                     <TextField {...params} label="Choose a font" variant="outlined" />
                )}
            />
            { selectedFonts.length > 0 && <Box sx={{
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                justifyContent:"center",
                width:"100%",
                height:"40px",
            }}>
            files({selectedFonts.length})<Button sx={{
            marginLeft:"10px",
            }} size={"small"} variant={"outlined"} onClick={handleAddToLib} startIcon={<Add/>}>Add to library</Button>
            </Box>}
            { currentFont &&
                <Grid container sx={{
                    backgroundColor:"#f3f3f3",
                }}>
                        <Grid item xs={2} sx={{
                            padding:"5px",
                            alignSelf:"center",
                        }}>
                         <Typography fontSize={"1rem"}>Font family:</Typography>
                        </Grid>
                        <Grid item xs={10} sx={{
                            padding:"5px",
                            borderRadius:"4px",
                            display:"flex",
                            flexDirection:"row",
                            alignItems:"start",
                            justifyContent:"start",
                            justifyItems:"start",
                        }}>
                            <span  style={{
                            backgroundColor:"#FFFFFF",
                            fontFamily: currentFont.family,
                            fontSize: "1.5rem",
                            fontWeight: "normal",
                            fontStyle: "normal",

                        }}>  { currentFont.family}</span>
                        </Grid>

                        <Grid item xs={7}>
                                <Grid container sx={{
                                    padding:"5px",
                                    overflowY:"scroll",
                                    minHeight:"25vh",
                                }}>
                                    <Grid  xs={4} item>Category:</Grid> <Grid xs={8} item> {currentFont.category}</Grid>
                                    <Grid xs={4} item>Subsets: </Grid> <Grid xs={8} item>{currentFont.subsets.join(", ")}</Grid>
                                    <Grid xs={4} item>Version: </Grid> <Grid xs={8} item>{currentFont.version}</Grid>
                                    <Grid xs={4} item>Last modified:</Grid> <Grid xs={8} item> {currentFont.lastModified}</Grid>
                                </Grid>
                        </Grid>
                    <Grid item xs={5} sx={{
                        padding:"5px",
                        overflowY:"scroll",
                        minHeight:"25vh",
                        maxHeight:"25vh",
                        display:"flex",
                        flexDirection:"row",
                        alignItems:"start",
                        justifyContent:"start",
                        justifyItems:"start",
                        flexWrap:"wrap",
                    }}>
                         <Box sx={{ width:"100%", height:"40px" }}>
                            <Typography fontSize={"1rem"}>Variants:</Typography>
                         </Box>
                        <Box sx={{ width:"100%", height:"80%" }}>
                        {currentFont.variants.map((variant, index) => {
                            return   <FormControlLabel control={<Checkbox onChange={(e)=>handleSelectVariant(variant,e.target.checked )} defaultChecked={false}/>} label={variant} /> })
                        }
                        </Box>
                    </Grid>

                </Grid>
            }
        </Box>

        </Paper>
    );
};
export default GoogleFontsSelector;
