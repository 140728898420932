import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as Mobx from  'mobx-react';
import app_store from "../stores/ui_state";
import {authStore} from "../stores/AuthStore";
import {Breadcrumbs, ClickAwayListener, Grow, MenuItem, MenuList, Popper} from "@mui/material";
import Paper from "@mui/material/Paper";
import UserMenu from "../components/UserMenu";
import {useLocation, useParams} from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from "@mui/material/Link";
const lightColor = 'rgba(255, 255, 255, 0.7)';

interface HeaderProps {
    onDrawerToggle: () => void;
}

const getBreadcrumbs  = (location: string)=> location.split('/').map((item)=> {
      const href = location.substr(0, location.indexOf(item)+item.length)+"/";
      return  <Link underline="hover" key="1" color="inherit" href={href}>
            {item}
        </Link>
    }
);

export default Mobx.observer(function  Header(props: HeaderProps) {
    const { onDrawerToggle } = props;
    let { id } = useParams();
    let  location  = useLocation()
    return (
        <React.Fragment>
            <AppBar sx={{minHeight: "50px"}} color={"inherit"} position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">

                        <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onDrawerToggle}
                                edge="start"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>

                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                {getBreadcrumbs(location.pathname)}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs />

                        <Grid item>
                            <Tooltip title="Alerts • No alerts">
                                <IconButton color="inherit">
                                    <NotificationsIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Typography color="inherit" variant="subtitle2" component="h1">
                                {authStore.user?.email}
                            </Typography>
                        </Grid>
                        <Grid item>

                            <UserMenu/>

                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>


        </React.Fragment>
    );
})
