




import api from "./api";




export async function StorageUploadFile (dataset_id: string, file: File): Promise<any>
{
    try {
        const form = new FormData();
      //  form.append("dataset_id", dataset_id);
        form.append("file", file);

        return api.post("api/storage?dataset_id="+dataset_id, {
            body: form
        }).json();

    } catch (e) {
        console.log(e);
        throw e;
    }
}

export async function GetAssetsList (dataset_id: string): Promise<any[]>
{
    try {
        return api.get("api/storage/assets?dataset_id="+dataset_id).json();
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export async function AssetDelete (id: string): Promise<any>{

    try {
        return api.delete("api/storage/asset/"+id).json();
    } catch (e) {
        console.log(e);
        throw e;
    }
}
