import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import {renderQueue} from "../../stores/RenderqStore";



const StickyStyle = {
    position: "absolute",
    top: "0",
    right: "0",
    width: "300px",
    zIndex: 10000,
    backgroundColor: "#e8e8e8",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 1px #CCCCCC",

}

function RenderqStickyBase() {

    return (<>
        {renderQueue.showStickPopup &&
        <Box sx={StickyStyle}>
            <h5>RenderQ</h5>
        </Box>
        }</>)
}
const RenderQSticky = observer(RenderqStickyBase);
export default RenderQSticky;
