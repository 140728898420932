import {observer} from "mobx-react";
import Paper from "@mui/material/Paper";
import {Input, Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {styled} from "@mui/material/styles";

// @ts-ignore
import {create as FontkitCreate} from "fontkit";
import Grid from "@mui/material/Grid";
import React from "react";
import {Add} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {fontsStore} from "../../stores/FontsStore";
import Divider from "@mui/material/Divider";


const AcceptFontTypes= ".otf, .ttf, .woff, .woff2, .eot, .svg"
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,

});

interface UploadFontsProps {
    onClose?:any
}

function UploadFontsBase(props:UploadFontsProps){
    const [currentFont, setCurrentFont] = React.useState<any>();
    const [file, setFile] = React.useState<File | null>(null);
    const handleUploadFont = async (fileList:FileList | null) => {

        if(fileList){
            const file = fileList[0];
            //console.log(file);
            const reader = new FileReader();
            reader.onload = async (event) => {
                try {
                    // console.log(event.target?.result);
                    const buffer = new Uint8Array(event.target?.result as ArrayBuffer);
                    const face = FontkitCreate(buffer);
                    setCurrentFont(face)
                    console.log(face);
                    let new_font = new FontFace(face.familyName, `url(${URL.createObjectURL(file)})`)
                    console.log(new_font);
                    document.fonts.add(new_font);
                    setFile(file);
                    //console.log([...document.fonts.values()].map(font => font.family))
                } catch (e) {
                    alert(e);
                    setCurrentFont(undefined)
                    setFile(null);
                }
            }
            reader.readAsArrayBuffer(file);

        }
    }
    const handleAddToLib = async () => {
        if(currentFont){
               await fontsStore.AddFont(currentFont, file as File)
               props.onClose()
        }
    }

    return(
        <Paper sx={{
            display:"flex",
            flexDirection:"column",
            alignItems:"start",
            justifyContent:"start",
            height:"40vh",
            width:"30vw",
            padding:"10px",
            translate:"115% 50%",
        }}>
            <Box sx={{
                width:"100%",
            }} >
               <Typography textAlign={"center"} variant={"h6"} >Upload Fonts</Typography>
            </Box>
            <Divider sx={{
                width:"100%",
                marginBottom:"10px",
            }}/>
            { !currentFont &&
            <Box sx={{
                width:"100%",
                height:"100%",
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                justifyContent:"center",
            }}>
                <Button size={"large"} component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                    Upload file
                    <VisuallyHiddenInput multiple={false} accept={AcceptFontTypes} type="file" onChange={(e)=>handleUploadFont(e.target.files)}/>
                </Button>
            </Box>
            }

            { currentFont &&
                <Grid container sx={{
                    backgroundColor:"#f3f3f3",
                }}>
                    <Grid item xs={4} sx={{
                        padding:"5px",
                        alignSelf:"center",
                    }}>
                        <Typography fontSize={"1rem"}>Family Name:</Typography>
                    </Grid>
                    <Grid item xs={8} sx={{
                        padding:"5px",
                        borderRadius:"4px",
                    }}>
                        <span  style={{
                            backgroundColor:"#FFFFFF",
                            fontFamily: currentFont.familyName,
                            fontSize: "1.5rem",
                            fontWeight: "normal",
                            fontStyle: "normal",

                        }}>  { currentFont.familyName}</span>
                    </Grid>
                    <Grid item xs={12} sx={{
                        marginRight:"10px",
                    }}>
                        <Grid container sx={{
                            padding:"5px",
                        }}>
                            <Grid  xs={4} item>fullName:</Grid> <Grid xs={8} item> {currentFont.fullName}</Grid>
                            <Grid xs={4} item>postscriptName:</Grid> <Grid xs={8} item>{currentFont.postscriptName}</Grid>
                            <Grid xs={4} item>Subsets: </Grid> <Grid xs={8} item>{currentFont.subfamilyName}</Grid>
                            <Grid xs={4} item>Version: </Grid> <Grid xs={8} item>{currentFont.version}</Grid>
                            <Grid xs={4} item>copyright:</Grid> <Grid xs={8} item> {currentFont.copyright}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {currentFont &&
                            <Button size={"small"} component="label" variant="outlined" startIcon={<Add />} onClick={handleAddToLib}>
                                Add to library
                            </Button>
                        }
                    </Grid>
                </Grid>
            }
        </Paper>
    )


}


export const UploadFonts = observer(UploadFontsBase)
