import React, {useEffect} from "react";
import {
    Grid,
    Typography,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    TableBody,
    Table,
    TableRow, TableCell
} from "@mui/material";
import Divider from "@mui/material/Divider";
import {Add, HorizontalRule} from "@mui/icons-material";
import {templateStore} from "../../stores/templates/TemplateStore";
import {autorun, runInAction} from "mobx";
import {observer} from "mobx-react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import {canvasStore} from "../../stores/templates/CanvasStore";
import {round} from "../../utils/MathUtils";


const styleMenuItem = {
   fontSize: "0.8rem",
    '&:hover': {
        backgroundColor: "#27728a",
    }

};


const ConstraintsControlBase = () => {

    const [left, setLeft] = React.useState(0);
    const [right, setRight] = React.useState(0);
    const [top, setTop] = React.useState(0);
    const [bottom, setBottom] = React.useState(0);


    const SelectMenusBase = ({}) => {

        const handleChangeVertical = (event: any) => {

            templateStore.setVConstraint(event.target.value)
           // canvasStore.setLayerConstraintV(event.target.value )
        }
        const handleChangeHorizontal = (event: any) => {

            templateStore.setHConstraint(event.target.value)
           // canvasStore.setLayerConstraintH(event.target.value )
        }
        return (
            templateStore.currentNode?
            <>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                        <InputLabel id="demo-simple-select-standard-label">Horizontal</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={templateStore?.currentNode?.constraints?.horizontal}
                            onChange={handleChangeHorizontal}
                            label="horizontal"
                            sx={styleMenuItem}
                        >
                            <MenuItem value={"LEFT"}>Left</MenuItem>
                            <MenuItem value={"RIGHT"}>Right</MenuItem>
                            <MenuItem value={"LEFT_RIGHT"}>Left & Right</MenuItem>
                            <MenuItem value={"CENTER"}>Center</MenuItem>
                            <MenuItem value={"SCALE"}>Scale</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                        <InputLabel id="demo-simple-select-standard-label">Vertical</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={templateStore?.currentNode?.constraints?.vertical}
                            onChange={handleChangeVertical}
                            label="vertical"
                            sx={styleMenuItem}
                        >
                            <MenuItem value={"TOP"}>Top</MenuItem>
                            <MenuItem value={"BOTTOM"}>Bottom</MenuItem>
                            <MenuItem value={"TOP_BOTTOM"}>Top & Bottom</MenuItem>
                            <MenuItem value={"CENTER"}>Center</MenuItem>
                            <MenuItem value={"SCALE"}>Scale</MenuItem>

                        </Select>
                    </FormControl>
                </>:<></>
        );

    }
    const SelectMenus = observer(SelectMenusBase)





    const BoundsListBase  = ({})=> {
        return (
            templateStore.currentNode?
                <>   <Typography sx={{
                    fontSize: "0.8rem",
                }}>Transform</Typography>
                <Grid container
                sx={{
                    border: "solid 1px #CCCCCC",
                    borderRadius: "4px",
                    padding: "15px",
                    minHeight: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexGrow: 1,
                    backgroundColor: "#FFFFFF",
                    fontSize: "0.8rem",
                }}
                >
                    <Grid xs={12} item sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        Editable fields:
                    </Grid>
                    <Grid xs={6} item sx={{  display: "flex",  justifyContent: "space-between",   alignItems: "center", }}>
                        X({templateStore?.currentNode?.transform?.x?.unit}): <input
                        type={"number"}
                        title={"Left"}
                        style={{   width: "80px",   height: "20px",  border: "none",   }}
                        value={round(templateStore.currentNode?.transform?.x.value as number)} id="outlined-basic"
                        onChange={(e)=>{runInAction(() => {
                            console.log("e.target.value",e.target.value)
                            templateStore.setX(parseFloat(e.target.value))
                        })}}
                    />
                    </Grid>
                    <Grid xs={6} item sx={{   display: "flex",   justifyContent: "space-between",   alignItems: "center",  }}>
                        Y({templateStore?.currentNode?.transform?.y?.unit}): <input
                        title={"Top"}
                        type={"number"}
                        style={{    width: "80px",     height: "20px",   border: "none",  }}
                        value={round(templateStore.currentNode?.transform?.y.value as number)} id="outlined-basic"
                        onChange={(e)=>{runInAction(() => {
                            console.log("e.target.value",e.target.value)
                            templateStore.setY(parseFloat(e.target.value))
                        })}}
                    />
                    </Grid>
                    <Grid xs={6} item sx={{  display: "flex",  justifyContent: "space-between",  alignItems: "center",  }}>
                        L({templateStore?.currentNode?.transform?.left?.unit}): <input
                        readOnly={true}
                        type={"number"}
                        title={"Left"}
                        style={{
                            width: "80px",
                            height: "20px",
                            border: "none",
                        }}
                        value={round(templateStore.currentNode?.transform?.left?.value as number )} id="outlined-basic"
                      onChange={(e)=>{runInAction(() => {
                           //console.log("e.target.value",e.target.value)
                           //templateStore.setX(parseFloat(e.target.value))
                      })}}
                        />
                    </Grid>
                      <Grid xs={6} item sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                      }}>
                          T({templateStore?.currentNode?.transform?.top?.unit}): <input
                          readOnly={true}
                          title={"Top"}
                          type={"number"}
                          style={{
                              width: "80px",
                              height: "20px",
                              border: "none",
                          }}
                          value={round(templateStore.currentNode?.transform?.top?.value  as number )} id="outlined-basic"
                          onChange={(e)=>{runInAction(() => {
                              //console.log("e.target.value",e.target.value)
                              //templateStore.setY(parseFloat(e.target.value))
                          })}}
                      />
                    </Grid>
                    <Grid xs={6} item sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        R({templateStore?.currentNode?.transform?.right?.unit}): <input
                        readOnly={true}
                        title={"Right"}
                        type={"number"}
                        style={{
                            width: "80px",
                            height: "20px",
                            border: "none",
                        }}
                        value={round(templateStore.currentNode?.transform?.right.value as number)} id="outlined-basic"
                        onChange={(e)=>{runInAction(() => {
                           //onsole.log("e.target.value",e.target.value)
                           // templateStore.setX(parseFloat(e.target.value))
                        })}}
                    />
                    </Grid>
                    <Grid xs={6} item sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        B({templateStore?.currentNode?.transform?.bottom?.unit}): <input
                        readOnly={true}
                        title={"Bottom"}
                        type={"number"}
                        style={{
                            width: "80px",
                            height: "20px",
                            border: "none",
                        }}
                        value={round(templateStore.currentNode?.transform?.bottom.value as number)} id="outlined-basic"
                        onChange={(e)=>{runInAction(() => {
                           // console.log("e.target.value",e.target.value)
                           // templateStore.setY(parseFloat(e.target.value))
                        })}}
                    />
                    </Grid>
                    <Grid xs={6} item sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        W({templateStore?.currentNode?.width.unit}): <input
                        title={"Width"}
                        type={"number"}
                        style={{
                            width: "80px",
                            height: "20px",
                            border: "none",

                        }}
                        value={round(templateStore?.currentNode?.width.value as number) } id="outlined-basic"
                                      onChange={(e)=>{runInAction(() => {
                                          //templateStore.currentNode.width = parseInt(e.target.value)
                                          templateStore.setWidth(parseFloat(e.target.value))
                                      })}}
                        />

                    </Grid>
                        <Grid xs={6} item sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}>
                        H({templateStore?.currentNode?.height.unit}): : <input
                            title={"Height"}
                            type={"number"}
                        style={{
                            width: "80px",
                            height: "20px",
                            border: "none",
                        }}
                        value={round(templateStore?.currentNode?.height.value as number)} id="outlined-basic"
                                        onChange={(e)=>{runInAction(() => {
                                            //templateStore.currentNode.height = parseInt(e.target.value)
                                            templateStore.setHeight(parseFloat(e.target.value))
                                        })}}
                        />
                    </Grid>
                    <Grid  xs={12} item sx={{
                        height: "30px",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "end",
                    }}>
                        <Divider/>
                        Computed fields(Read only):
                    </Grid>
                    <Grid xs={12} item sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <Table >
                            <TableBody sx={{
                                padding: "0px",
                                margin: "0px",
                                border: "none",
                                width: "100%",
                                backgroundColor: "#f3f3f3",
                            }}>
                                <TableRow >
                                    <TableCell sx={{ height: "10px",  padding: "0px", fontSize: "0.8rem", }}>L:{templateStore?.currentNode?.transform?.computed?.left}</TableCell>
                                    <TableCell sx={{ height: "10px",  padding: "0px", fontSize: "0.8rem",  }}>R:{templateStore?.currentNode?.transform?.computed?.right} </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ height: "10px",  padding: "0px", fontSize: "0.8rem",  }}>T:{templateStore?.currentNode?.transform?.computed?.top}</TableCell>
                                    <TableCell sx={{ height: "10px",  padding: "0px", fontSize: "0.8rem",  }}>B:{templateStore?.currentNode?.transform?.computed?.bottom}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ height: "10px",  padding: "0px", fontSize: "0.8rem",  }}>W:{templateStore?.currentNode?.transform?.computed?.width}</TableCell>
                                    <TableCell sx={{ height: "10px",  padding: "0px" , fontSize: "0.8rem", }}>H:{templateStore?.currentNode?.transform?.computed?.height}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>


                </Grid></>:
                <></>);
    }
    const BoundsList = observer(BoundsListBase)


    return (
        <Grid container spacing={2} direction="column" alignItems="left">
            <Grid item xs={12}>
                <Typography sx={{
                    fontSize: "0.8rem",
                }}>Constraints</Typography>
            </Grid>

            <Grid  item  sx={{

            }}>
                <Grid container item xs={12} direction={"row"}
                      sx={{
                          backgroundColor: "#FFFFFF",
                          border: "solid 2px #CCCCCC",
                          padding: "5px",
                      }}
                >
                    <Grid xs={6}
                          container
                          item
                          direction="row"
                          alignItems="left"
                          sx={{
                              backgroundColor: "#FFFFFF",
                              border: "solid 2px #CCCCCC",
                          }}
                    >
                    <Grid xs={3} sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} item >

                    </Grid>
                    <Grid xs={6} sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} item>
                        <HorizontalRule sx={{
                            transform: "rotate(90deg)",
                        }}/>
                    </Grid>
                    <Grid xs={3} sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} item>

                    </Grid>
                    <Grid xs={3} sx={{

                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} item >
                        <HorizontalRule/>
                    </Grid>
                    <Grid xs={6} sx={{
                        border: "solid 1px #000000",
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} item>
                        <Add/>
                    </Grid>
                    <Grid xs={3} sx={{
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} item>
                        <HorizontalRule/>
                    </Grid>
                    <Grid xs={3} sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} item >

                    </Grid>
                    <Grid xs={6} sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} item>
                        <HorizontalRule sx={{
                            transform: "rotate(90deg)",
                        }}/>
                    </Grid>
                    <Grid xs={3} sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} item>

                    </Grid>

                </Grid>
                <Grid  item xs={6} sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    flexDirection: "column",
                }}>

                    <SelectMenus/>
                </Grid>


                </Grid>
            </Grid>
            <Grid item>
                <BoundsList/>
            </Grid>
        </Grid>
    );
}

const ConstraintsControl = observer(ConstraintsControlBase);
export default ConstraintsControl;
