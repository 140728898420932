import React, {useState} from "react";
import {observer} from "mobx-react";
import {Checkbox, CircularProgress, Modal, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {templateStore} from "../../stores/templates/TemplateStore";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {useParams} from "react-router-dom";
import FormControlGroup from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";


interface CheckboxImportProps {
    checked:boolean
    onChange: (e:any)=>void
    label:string
}
const CheckboxImport: React.FC<CheckboxImportProps> = (props:CheckboxImportProps) => {
    const {checked, onChange, label} = props
    return (
        <FormControlLabel
            sx={{
                padding: "0px",
                margin: "0px",
                "& .MuiFormControlLabel-label": {
                    fontSize: "0.8rem",
                },
                "& .MuiCheckbox-root": {
                    padding: "0px",
                    margin: "0px",
                }
            }}
            label={label}
            control={
                <Checkbox
                    checked={checked}
                    defaultChecked={true}
                    onChange={(e)=>onChange(e)}
                />
            }
        />)
}



interface FigmaImportDlgProps {
    isOpen: boolean,
    onClose:any
    id:string
}

const FigmaImportDlgBase: React.FC<FigmaImportDlgProps> = (props) => {

    const {isOpen, onClose, id} = props
    const [figma_url, setFigma_url] = useState(templateStore.template?.figma_url)
    const [isLoadingInProgress, setIsLoadingInProgress] = useState(false)
    const {template_id, variant_id} = useParams();
    const handleImport = async () => {
        if(id === undefined) return;
         if(!templateStore.isVariantViewMode) {
             await templateStore.importFromFigma(id, figma_url as string)
         }else{
             if(figma_url)
             await templateStore.importFromFigmaVariant(id, variant_id, figma_url)
         }
    }



    return(  <Modal open={isOpen} onClose={()=>onClose()} >
        <Box style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}
             sx={{
                 //backgroundColor: 'rgba(255, 255, 255, 0.4)',
             }}  >
            {isLoadingInProgress?
                <Paper  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '300px',
                    minHeight: '100px',
                    direction:"column",
                    flexWrap:"wrap",
                }}  >
                    {templateStore.currentImportInProgress?<>
                        <CircularProgress />
                        <Typography >
                            Importing template from figma...
                        </Typography>
                    </>:<>
                        <Typography >
                            Importing completed!
                        </Typography>
                    </>}
                    <Typography >
                        { templateStore.currentImportTaskStatus } : { templateStore.currentImportTaskMessage }
                    </Typography>
                    {templateStore.currentImportInProgress?
                        <Typography >
                            Please wait...
                        </Typography>
                        :
                        <Button variant={"outlined"} onClick={()=>{
                            setIsLoadingInProgress(false)
                            onClose()
                        }}>
                            Close
                        </Button>
                    }
                </Paper>
                :
                <Paper  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '420px',
                    minHeight: '200px',
                    direction:"column",
                    flexWrap:"wrap",
                }}  >
                    <Typography variant={"h6"}>
                        {templateStore.isVariantViewMode?"Import variant from figma":"Import template from figma"}
                    </Typography>

                    <Stack sx={{padding:"5px", width:"400px"}} spacing={2}>

                        <TextField size={"small"} value={figma_url} id="outlined-basic" label="Figma element url" variant="outlined"
                                   onChange={(e)=>{setFigma_url(e.target.value)}}
                        />
                        {templateStore.isVariantViewMode&&
                        <FormControlGroup >
                            <CheckboxImport label={"Skip images"} onChange={()=>{}} checked={true} />
                            <CheckboxImport label={"Override all"}  onChange={()=>{}} checked={true} />
                            <CheckboxImport label={"Validate base template structure"}  onChange={()=>{}} checked={true} />
                        </FormControlGroup>
                        }
                        <Button variant={"outlined"} onClick={async ()=>{
                            setIsLoadingInProgress(true)
                            await handleImport()
                        }}>
                            Import
                        </Button>
                    </Stack>

                </Paper>
            }
        </Box>
    </Modal>)

}


export const FigmaImportDlg = observer(FigmaImportDlgBase)
