import {useCallback, useEffect, useState} from "react";
import {Form, useParams} from "react-router-dom";
import menuStore from "../../stores/navigatorStore";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import {Toolbar} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Add, Cancel, Delete, Edit, Image, Save, UploadFile} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import * as React from "react";
import DnsRoundedIcon from "@mui/icons-material/DnsRounded";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import {datasetStore, Dataset, DatasetColumn} from "../../stores/DatasetStore";
import FieldsEditor from "./FieldsEditor";
import DsDataGrid from "./DsDatagrid";



const projectCategories = (ds_id:string|undefined) => {
    return {
        categoryName: '< Back',
        path: "/dataset/"+ds_id,
        children: [
            {idx: 1, name: 'Dataset Assets', icon: <DnsRoundedIcon/>, active: true, path: "dataset/"+ds_id+"/assets"},
            {idx: 2, name: 'Dataset Links', icon: <SelectAllIcon/>, active: false, path: "dataset/"+ds_id+"/datalinks"},
        ],
    };
}
function DatasetView() {

    const params = useParams();
    const datasetId = params.id;
    const [EditStructureModal, setEditStructureModal] = useState(false)
    const [RowsModal, setRowsModal] = useState(false)
    const [RowEdit, setRowEdit] = useState<any>({})
    const [loaded, setLoaded] = useState(false)

    const handleStructureEditOpen = () => setEditStructureModal(true);

    const handleAddRecord = async () => {
        if(!datasetId) return;
        try {
            await datasetStore.addDatasetRecord(datasetId)
            await datasetStore.read(datasetId)
            //fetchRows();


        }catch (e) {
            console.log(e)
        }
    }
    const handleAttachData = async () => {
        if(!datasetId) return;
        try {
            await datasetStore.attachData(datasetId)
            await datasetStore.read(datasetId)
            //fetchRows();
        }catch (e) {
            console.log(e)
        }
    }

    //effects
    useEffect(() => {

        const callOnce  = async ()=> {
            await datasetStore.fetchDatasets();
            if(datasetId) {
                await datasetStore.read(datasetId)
            }
            setLoaded(true)
        }
        callOnce().catch(console.error);
        const cats = projectCategories(datasetId)
        menuStore.setMenu(cats);

    }, []);

    return (
        <Paper sx={{ margin: '0 0 0 0', overflow: 'hidden' }}>

            <AppBar position="static" color="default" elevation={0} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Button onClick={handleStructureEditOpen} variant="contained" startIcon={<Add />}>
                                Edit fields
                            </Button>
                             <FieldsEditor EditStructureModal={EditStructureModal} setEditStructureModal={setEditStructureModal} id={datasetId}/>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleAddRecord} startIcon={<Add />}>
                                Add record
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleAttachData} startIcon={<Add />}>
                                Attach data
                            </Button>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            <Button variant="outlined" startIcon={<UploadFile />}>
                                Save
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" startIcon={<UploadFile />}>
                                Load
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Box sx={{ p: 2, flexGrow: 1 }}>
                <Grid container spacing={2} >
                    <Box sx={{ flex: 1, p: 2 , width: '70vw' , height:'60vh' }} >
                        Dataset: {datasetStore.datasets.get(datasetId?datasetId:"")?.name}
                        {loaded?
                        <DsDataGrid ready={true} />
                            :""}

                    </Box>
                </Grid>
            </Box>
        </Paper>
    );
}

const DatasetComponent = observer(DatasetView);
export default React.memo(DatasetComponent);
