import {observer} from "mobx-react";
import Grid from "@mui/material/Grid";
import React, {useEffect} from "react";
import TextField from "@mui/material/TextField";
import {TColor, TColorFill, TDynamicValueTypes, TImage} from "../../../contracts/TemplateContracts";
import {templateStore} from "../../../stores/templates/TemplateStore";
import DynamicFieldsCB from "../../widgets/DynamicFieldsCB";
import {TBaseItem} from "../../../contracts/implementations/TBaseItem";



function RGBAtoSvg(rgba: any) {
    const r = Math.round(rgba.r * 255);
    const g = Math.round(rgba.g * 255);
    const b = Math.round(rgba.b * 255);
    return  `rgba(${r},${g},${b},${rgba.a})`;
}

function rgbaToHex(rgba: any) {
    const r = Math.round(rgba.r * 255);
    const g = Math.round(rgba.g * 255);
    const b = Math.round(rgba.b * 255);
    const a = Math.round(rgba.a * 255);
    const red = r.toString(16).padStart(2, '0');
    const green = g.toString(16).padStart(2, '0');
    const blue = b.toString(16).padStart(2, '0');
    // const alpha = Math.round(a * 255).toString(16).padStart(2, '0');
    // return `#${red}${green}${blue}${alpha}`;
    return `#${red}${green}${blue}`;
}

function hexToRgb(hex: string) {
    const r = parseInt(hex.substr(1,2), 16) / 255;
    const g = parseInt(hex.substr(3,2), 16) / 255;
    const b = parseInt(hex.substr(5,2), 16) / 255;
    return {r,g,b};
}


function ImageFillBase( {fill, index, id} : {fill:TImage, index:number, id:string} )  {

    const [image, setImage] = React.useState<TImage>( fill )
    const [indexFill, setIndexFill] = React.useState<number>(index)

    useEffect(() => {
        setImage(fill)
    }, [fill])
    useEffect(() => {
        setIndexFill(index)
    }, [index]);


    return (
        <Grid container sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "1px",
            padding: "1px",
        }}>
            <Grid item xs={2}     sx={{
                margin: "0px",
                padding: "0px",

            }}>
                <img
                    src={"/api/template/assets/"+image.imageRef}
                    style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "contain",
                    border: "solid 1px #CCCCCC",
                }}/>
            </Grid>
            <Grid item xs={8} sx={{
                margin: "0px",
                padding: "0px",
            }}>
                <TextField
                    id="outlined-basic"
                    size={"small"}
                    InputProps={{ sx: {
                            borderRadius: 0 ,
                            width: "150px",
                            height: "20px",
                            padding: "0px",
                            margin: "0px",
                            fontSize: "0.8rem",
                        }}}
                    variant="outlined"
                    value={ image.imageRef}
                />
            </Grid>
            <Grid item xs={2}     sx={{
                margin: "0px",
                padding: "0px",
            }}>
             <DynamicFieldsCB node={templateStore.selectedNode as TBaseItem } type={TDynamicValueTypes.Image}/>
            </Grid>
        </Grid>
    )
}


const ImageFill = observer(ImageFillBase)
export default ImageFill
