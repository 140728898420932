
import api from "./api";

export async function ProjectList ()
{
    try {
        return api.get("api/project/").json();

    } catch (e) {
        console.log(e);
        throw e;
    }
}

export async function ProjectCreate (name: string, description: string){


    try {
        return api.post("api/project/", {
            json: {  "name": name  }
        }).json();

    } catch (e) {
        console.log(e);
        throw e;
    }
}

export async function ProjectDelete (id: string){
    try {
        return api.delete("api/project/"+id).json();

    } catch (e) {
        console.log(e);
        throw e;
    }
}


