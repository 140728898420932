import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import Box from "@mui/material/Box";

const AddLinkModalstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': AddLinkModalstyle
}));

export default function DialogCustom({titleEl, children, open, actions}:{ titleEl:string, children:ReactJSXElement, open:boolean, actions:any} ) {
    //const [open, setOpen] = React.useState(false);


    return (
            <BootstrapDialog
                onClose={actions.close}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {titleEl}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={actions.close}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent  dividers>

                    {children}

                </DialogContent>
                <DialogActions>
                    <Button sx={{
                        padding: "5px 15px 5px 15px",
                        marginRight: "15px",
                        border: "1px solid #CCCCCC",
                        color: "#000000",
                        ":hover": {
                            backgroundColor: "#CCCCCC",
                            color: "#FFFFFF",
                            border: "1px solid #CCCCCC",
                        }
                    }} variant={"outlined"} autoFocus onClick={actions.accept}>
                        Apply
                    </Button>
                </DialogActions>
            </BootstrapDialog>
    );
}
