import React, {useEffect, useRef, useState} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import VectorIcon from '@mui/icons-material/Gesture';
import TextIcon from '@mui/icons-material/TextFields';
import ImageIcon from '@mui/icons-material/Image';
import {templateStore} from "../../stores/templates/TemplateStore";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {runInAction} from "mobx";
import {PagesOutlined, RoofingTwoTone} from "@mui/icons-material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Grid, Typography } from "@mui/material";
import './timeline-item.css';
import TimeLineItem from "./TimeLine";
import Button from "@mui/material/Button";
import {TBaseItem} from "../../contracts/implementations/TBaseItem";



const NumberInput = (props: { min: number, step: number}) => {

    const { min, step } = props;
    const [ value, setValue] = useState(0);

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "80px",
            height: "20px",
            backgroundColor: "#f3f3f3",
            border: "1px solid #CCCCCC",
            borderRadius: "4px",
            padding: "0px 5px 0px 5px",
        }}>
            <div style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#f3f3f3",
                border: "1px solid #CCCCCC",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#0c2d36",
                fontSize: "12px"
            }}
                 onClick={() => {
                     setValue(value - step)
                 }}
            >-</div>

            <div style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#f3f3f3",
                border: "1px solid #CCCCCC",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#0c2d36",
                fontSize: "12px"
            }}>
                {value}
            </div>
            <div style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#f3f3f3",
                border: "1px solid #CCCCCC",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#0c2d36",
                fontSize: "12px"
            }}
                 onClick={() => {
                     setValue(value + step)
                 }}
            >+</div>

        </div>)

}



const LayersListBase = () => {

    const defaultFrames: IKeyFrame[] = [];
    for (let i = 0; i < 5; i++) {
        const tmpFrame:  IKeyFrame =  {index:i, value:"", duration:1}
        defaultFrames.push(tmpFrame);
    }
    const getIconForLayer = (type:any) => {
        switch (type) {
            case 'GROUP':
                return <VectorIcon />;
            case 'TEXT':
                return <TextIcon />;
            case 'RECTANGLE':
                return <ImageIcon />;
            default:
                return null;
        }
    };
    const { id } = useParams();

    const [frames, setFrames] = useState<IKeyFrame[]>(defaultFrames)
    const [selectedLayer, setSelectedLayer ] = useState("")
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                :  null,
        );
    };
    const handleClose = () => {
        setContextMenu(null);
    };

    const handleCopyCode = () => {

        let code = templateStore?.currentNode?.name
        if(!code) return
        navigator.clipboard.writeText(code).then(function() {
            /* clipboard successfully set */
            handleClose()
        });
    }
    const uniqKeyGenerator = () => {
        return '_' + Math.random().toString(36).substr(2, 9);
    }


    return (
        <Grid container spacing={1} alignItems="center" sx={{  backgroundColor: "#f3f3f3"}}  >
            <Grid item xs={2} sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 10px 0px 10px",
            }}>
                <Button variant={"outlined"}
                        sx={{
                            color: "#27728a",
                            width: "20px",
                            height: "20px",
                            fontSize: "10px",
                            padding: "0px",
                            margin: "0px",
                            borderRadius: "0px",
                }}>
                    Layer +
                </Button>

                <NumberInput min={0} step={1} />

            </Grid>
            <Grid item xs={10}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "16px",
                    backgroundColor: "#f3f3f3",

                }}>
                {frames.map((frame: IKeyFrame, index: number) => {
                    return <div
                        key={uniqKeyGenerator()}
                        style={{
                            width: 100/frames.length+"%",
                            height: "100%",
                            backgroundColor: "#f3f3f3",
                            border: "1px solid #CCCCCC",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#0c2d36",
                            fontSize: "12px"
                        }}
                    >frame: {index}</div>
                })}
                </div>
            </Grid>
            <Grid item xs={2}>

                 <List disablePadding
                 sx={{
                        backgroundColor: "#f3f3f3",
                        border: "1px solid #CCCCCC",
                        borderRadius: "4px",
                        padding: "0px 0px 0px 0px",
                        width: "100%",
                        margin: "0px",
                 }}
                 >
                { templateStore?.selectedContainer?.children.map((node: TBaseItem, index:number) => {

                         return <ListItem
                             onContextMenu={handleContextMenu}
                             draggable={true}
                             key={uniqKeyGenerator()} sx={{
                             backgroundColor: selectedLayer === node.id ?  "#091d23": "#f3f3f3",
                                color: selectedLayer === node.id  ? "#FFFFFF" : "#0c2d36",
                                   '&:hover': {
                                          backgroundColor: "#27728a",
                                            color: "#FFFFFF",
                                        },
                                 padding: "0px 0px 0px 3px",

                                border: "1px solid #CCCCCC",
                                width: "100%",
                                margin: "0px",
                                height: "25px",

                            }}
                             onClick={() => {
                                 setSelectedLayer(node.id as string)
                             }}
                            >
                                <ListItemIcon>
                                    {getIconForLayer(node.type)}
                                </ListItemIcon>
                                <ListItemText primary={node.name}/>
                            </ListItem>
                        })}
                        </List>

            </Grid>
            <Grid item xs={10}>
                {templateStore?.selectedContainer?.children.map((layer: any, index:number) => {

                    return<TimeLineItem name={layer.name} frames={frames}/>
                })}
             </Grid>
        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
            }
        >

            <MenuItem onClick={handleClose}>Preview</MenuItem>
            <MenuItem onClick={handleCopyCode}>Copy code</MenuItem>
            <MenuItem onClick={handleClose}>Reimport</MenuItem>
            <MenuItem onClick={handleClose}>Delete</MenuItem>

        </Menu>
             </Grid>);
};

const LayersList = observer(LayersListBase);
export default LayersList;
