import IconButton from "@mui/material/IconButton";
import {Typography} from "@mui/material";
import {SiChainlink} from "react-icons/si";
import React from "react";
import Box from "@mui/material/Box";
import {observer} from "mobx-react";
import {TBaseItem} from "../../contracts/implementations/TBaseItem";
import {templateStore} from "../../stores/templates/TemplateStore";
import {TDynamicValue, TDynamicValueTypes} from "../../contracts/TemplateContracts";


interface DynamicParametersProps {
    node: TBaseItem;
    type: TDynamicValueTypes
}

const IconButtonStyle = {
    "& .MuiIconButton-root": {
        padding: "0px",
        margin: "0px",
        width: "20px",
        height: "20px",
        "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
        }
    }
}

function DynamicFieldsCBBase(props: DynamicParametersProps) {
        const {node} = props;
        const onChange = () => {

            if(node.is_dynamic) {
                templateStore.template?.addDynamicField(node, props.type);
            }else{
                templateStore.template?.removeDynamicField(node, props.type);
            }
            node.setDynamic(!node.is_dynamic);
        };
    return (<>
        {node.is_dynamic?
                <IconButton title={"Make dynamic"} sx={IconButtonStyle} size={"small"} onClick={()=>onChange()}>
                    <SiChainlink fontSize="small"/>
                </IconButton>
                :
                <IconButton title={"Remove dynamic"}  sx={IconButtonStyle} size={"small"} onClick={()=>onChange()}>
                    <SiChainlink color={"#1464f5"} fontSize="small"/>
                </IconButton> }
        </>);
}

const DynamicFieldsCB = observer(DynamicFieldsCBBase)
export default DynamicFieldsCB
